import React from "react";
import { Link } from "react-router-dom";
import imageUrl from "../../ImageUrl";
import Images from "../StaticImages/Images";

const MyCompaniesPage = (props) => {
	const companyData = props.company.startup;
	return (
		<>
			<ul className="my-pages flex items-center mt-2">
				<li>
					{companyData.images.length == 0 ? (
						<>
							<img src={Images.defaultCompanyLogo} alt="" className="mr-2" />
						</>
					) : (
						<>
							<img
								src={companyData.images}
								alt="logo"
								className="mr-2"
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = Images.defaultCompanyLogo;
								}}
							/>
						</>
					)}
				</li>
				<li className="company-name leading-4">
					<Link to={"/CompanyPage"}>
						<h4 className=" font-semibold">
							{companyData.company_name.length < 10 ? (
								<>{companyData.company_name}</>
							) : (
								<>{companyData.company_name.slice(0, 9)}..</>
							)}
						</h4>
						{companyData.company_title == null ? (
							""
						) : (
							<>
								<p className="text-xs">
									{companyData.company_title.length < 10 ? (
										<>{companyData.company_title}</>
									) : (
										<>{companyData.company_title.slice(0, 15)}..</>
									)}
								</p>
							</>
						)}
					</Link>
				</li>
			</ul>
		</>
	);
};

export default MyCompaniesPage;
