import React from "react";

const SubscriptionCard = ({
  index,
  color,
  cardHeader,
  cardSubHeader,
  cardBody,
  handleClick,
  clickedIndex,
}) => {
  return (
    <div
      className={`subscription-card cursor-pointer border-t-[0.8rem] ${
        clickedIndex === index || clickedIndex === null
          ? `border-${color}-600`
          : "border-slate-500"
      } `}
      onClick={() => handleClick(index)}
    >
      <div className="card-header p-3">
        <h1
          className={`text-2xl ${
            clickedIndex === index || clickedIndex === null
              ? `text-${color}-800`
              : "text-slate-500"
          } font-semibold`}
        >
          {cardHeader}
        </h1>
        <p className="text-base text-slate-500 mt-2">{cardSubHeader}</p>
      </div>
      <div className="card-body-section p-3 border-t-2 border-slate-200 mt-3 ">
        <ul className="text-slate-600 mt-4 pl-2">
          {cardBody.map((item) => {
            return <li className="mb-6 list-disc">{item}</li>;
          })}
        </ul>
        <div className="btn-section flex justify-center">
          <button
            className={`${
              clickedIndex === index || clickedIndex === null
                ? "learn-more-btn"
                : "learn-more-btn learn-more-btn-disable"
            } font-semibold`}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
