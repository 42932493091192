const CardData = [
  {
    index: 0,
    color:"emerald",
    cardHeader: "Free",
    cardSubHeader: "Get hired and get ahead",
    cardBody: [
      "Stand out and get in touch with hiring managers",
      "See how you compare to other applicants",
      "Learn new skills to advance your career",
    ],
    cardLeftContent: [
      "2.6x",
      "Candidates with Premium Career get hired an average of 2.6x as fast",
    ],
    cardListData: [
      "5 InMails per month",
      "See Who's Viewed Your Profile from the last 90 days",
      "Open Profile",
      "Job and applicant insights",
      "Top Applicant Job recommendations",
      "Company insights",
      "Resume insights",
      "Learning courses",
      "Full access to Interview Preparation tool",
    ],
  },
  {
    index: 1,
    color:"yellow",
    cardHeader: "Gold",
    cardSubHeader: "Grow nurture your network",
    cardBody: [
      "Find and contact the right people",
      "Promote and grow your business",
      "Learn new skills to enhance your professional brand",
    ],
    cardLeftContent: [
      "4x",
      "Premium members get an average of 4x more profile views",
    ],
    cardListData: [
      "15 InMails per month",
      "See Who's Viewed Your Profile from the last 90 days",
      "Open Profile",
      "Job and applicant insights",
      "Top Applicant Job recommendations",
      "Company insights",
      "Resume insights",
      "Learning courses",
      "Full access to Interview Preparation tool",
    ],
    price:999
  },
  {
    index: 2,
    color:"rose",
    cardHeader: "Platinum",
    cardSubHeader: "Get hired and get ahead",
    cardBody: [
      "Stand out and get in touch with hiring managers",
      "See how you compare to other applicants",
      "Learn new skills to advance your career",
    ],
    cardLeftContent: [
      "3.8x",
      "Members with Sales Navigator are connected to 3.8x more decision-makers",
    ],
    cardListData: [
      "5 InMails per month",
      "See Who's Viewed Your Profile from the last 90 days",
      "Open Profile",
      "Job and applicant insights",
      "Top Applicant Job recommendations",
      "Company insights",
      "Resume insights",
      "Learning courses",
      "Full access to Interview Preparation tool",
    ],
    price:1999
  }
];

export default CardData;
