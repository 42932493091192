import companyIcon from "./konnectorx-192.png";
import Images from "../components/StaticImages/Images";
const messageCard = (
  image,
  title,
  url,
  description,
  profileTitle = "",
  profileImage = ""
) => {
  return `
        <a href="${url}" target="_blank" rel="noopener noreferrer">
        <div class="card">
            <div class="card-content">
                <div class="card-info">
                    <div class='card-post-title'>
                        <img src="${profileImage}" alt="profile image">
                        <div class="card-user-info">
                        <h3>${title}</h3>
                            <p>${profileTitle}</p>
                            </div>
                    </div>
                    <div class="card-content">
                    ${description}
                    </div>
                    ${
                      image !== "" ? `<img src="${image}" alt="${title}"> ` : ""
                    }
                </div>
            </div>
        </div>
        </a>
        `;
};

const investorCard = (investorPost, url="") => {
  return `
    <a href="${url}" target="_blank" rel="noopener noreferrer">
    <div class="investor-card">
        <div class="investor-card__profileDetails">
            <img src="${
              investorPost.user?.UserImages?.[0]?.profile_image
                ? investorPost.user.UserImages[0].profile_image
                : "./img/default_img/default_profile.png"
            }" alt="profile image" />
            <div class="profileDetails__info">
                <h3>${investorPost.user?.username ? investorPost.user?.username : ""}</h3>
                <p>${investorPost.user?.UserAddress?.[0]?.city ? investorPost.user?.UserAddress?.[0]?.city : "" }, ${investorPost?.user?.UserAddress?.[0]?.country ? investorPost?.user?.UserAddress?.[0]?.country : ""}</p>
            </div>
        </div>

        <hr>

        <div class="investor-card__about">
            <h3>About Investor</h3>
            <p>${investorPost.user?.UserDescription?.[0]?.profileDescription ? investorPost.user?.UserDescription?.[0]?.profileDescription.substring(
              250
            ) : ""}...</p>
        </div>

        <hr>

        <div class="investor-card__investments">
            <h3>Investments</h3>
            <ul class="investments__info">
               <li>
                    <h4>Acquisitions</h4>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" class="icons" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M434.7 64h-85.9c-8 0-15.7 3-21.6 8.4l-98.3 90c-.1.1-.2.3-.3.4-16.6 15.6-16.3 40.5-2.1 56 12.7 13.9 39.4 17.6 56.1 2.7.1-.1.3-.1.4-.2l79.9-73.2c6.5-5.9 16.7-5.5 22.6 1 6 6.5 5.5 16.6-1 22.6l-26.1 23.9L504 313.8c2.9 2.4 5.5 5 7.9 7.7V128l-54.6-54.6c-5.9-6-14.1-9.4-22.6-9.4zM544 128.2v223.9c0 17.7 14.3 32 32 32h64V128.2h-96zm48 223.9c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zM0 384h64c17.7 0 32-14.3 32-32V128.2H0V384zm48-63.9c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16c0-8.9 7.2-16 16-16zm435.9 18.6L334.6 217.5l-30 27.5c-29.7 27.1-75.2 24.5-101.7-4.4-26.9-29.4-24.8-74.9 4.4-101.7L289.1 64h-83.8c-8.5 0-16.6 3.4-22.6 9.4L128 128v223.9h18.3l90.5 81.9c27.4 22.3 67.7 18.1 90-9.3l.2-.2 17.9 15.5c15.9 13 39.4 10.5 52.3-5.4l31.4-38.6 5.4 4.4c13.7 11.1 33.9 9.1 45-4.7l9.5-11.7c11.2-13.8 9.1-33.9-4.6-45.1z"></path></svg>
               </li> 
               <li>
                    <h4>Year of Expertise</h4>
                    ${
                      investorPost?.yearOfExpertise
                        ? `<span>${investorPost?.yearOfExpertise}</span>`
                        : `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="icons" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path><path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"></path></svg>`
                    }
                </li> 
                <li>
                    <h4>Total-Investment</h4>
                    ${
                        investorPost?.minAmount && investorPost?.maxAmount
                          ? `<span>${investorPost.minAmount} - ${investorPost.maxAmount}</span>`
                          : `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="icons" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm47.7-395.2l-25.4-5.9V348.6c38 5.2 61.5 29 65.5 58.2.5 4 3.9 6.9 7.9 6.9h44.9c4.7 0 8.4-4.1 8-8.8-6.1-62.3-57.4-102.3-125.9-109.2V263c0-4.4-3.6-8-8-8h-28.1c-4.4 0-8 3.6-8 8v33c-70.8 6.9-126.2 46-126.2 119 0 67.6 49.8 100.2 102.1 112.7l24.7 6.3v142.7c-44.2-5.9-69-29.5-74.1-61.3-.6-3.8-4-6.6-7.9-6.6H363c-4.7 0-8.4 4-8 8.7 4.5 55 46.2 105.6 135.2 112.1V761c0 4.4 3.6 8 8 8h28.4c4.4 0 8-3.6 8-8.1l-.2-31.7c78.3-6.9 134.3-48.8 134.3-124-.1-69.4-44.2-100.4-109-116.4zm-68.6-16.2c-5.6-1.6-10.3-3.1-15-5-33.8-12.2-49.5-31.9-49.5-57.3 0-36.3 27.5-57 64.5-61.7v124zM534.3 677V543.3c3.1.9 5.9 1.6 8.8 2.2 47.3 14.4 63.2 34.4 63.2 65.1 0 39.1-29.4 62.6-72 66.4z"></path></svg>`
                      }
                    
                </li> 
                <li>
                    <h4>Exits</h4>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="icons" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5a2 2 0 00-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 002 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg>
                </li>
            </ul>
        </div>
    </div>
    </a>
    `;
};

const startupCard = (startupPost, url="") => {
    return `
    <a href="${url}" target="_blank" rel="noopener noreferrer">
        <div class="startup-card">
            <div class="startup-card-head">
                <img src="${startupPost?.images == "" ? Images?.defaultCompanyLogo : startupPost.images}" alt="" />
                <div class="startup-company-info">
                    <h2>${startupPost?.company_name ? startupPost?.company_name : ""}</h2>
                    <p>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z"></path><circle cx="256" cy="192" r="48" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></circle></svg>
                        ${startupPost?.StartupAddress?.[0]?.completeAddress ? startupPost.StartupAddress[0].completeAddress : ""}
                    </p>
                </div>
            </div>
            <div class="startup-card-about">
                <h4>About ${startupPost?.company_name ? startupPost?.company_name : ""}</h2>
                <p>${startupPost?.company_description ? startupPost?.company_description?.substring(250) : ""}...</p>
            </div>
            <div class="startup-card-details">
                <ul class="startup-card-details-list">
                    <li class="startup-list-item">
                        <h3>Employees</h3>
                        <p>${startupPost?.company_size ? startupPost.company_size : ""}</p>
                    </li>
                    <li class="startup-list-item">
                        <h3>Revenue</h3>
                        <p>${startupPost.totalRevenue ? startupPost.totalRevenue : ""}</p>
                    </li>
                    <li class="startup-list-item">
                        <h3>Investment</h3>
                        <p>${startupPost.totalFunding ? startupPost.totalFunding : ""}</p>
                    </li>
                    <li class="startup-list-item">
                        <h3>Founded Year</h3>
                        <p>${startupPost.founded ? startupPost.founded : ""}</p>
                    </li>
                    <li class="startup-list-item">
                        <h3>Status</h3>
                        <p>${startupPost.class_of_company ? startupPost.class_of_company : ""}</p>
                    </li>
                    <li class="startup-list-item">
                        <h3>Website</h3>
                        <p>${startupPost.company_website ? startupPost.company_website : ""}</p>
                    </li>
                </ul>
            </div>
        </div>
    </a>
    `
}

const jobCard = (jobPostData, url="") => {
    return `
    <a href="${url}" target="_blank" rel="noopener noreferrer">
        <div class="job-card">
            <div class="job-card-head">
                <img src="${jobPostData?.company?.images == "" ? Images?.defaultCompanyLogo : jobPostData.company.images}" alt="" />
                <div class="job-card__company-info">
                    <h2>${jobPostData.job_title ? jobPostData.job_title : ""}</h2>
                    <h3>${jobPostData?.company?.company_name ? jobPostData?.company?.company_name : ""}</h3>
                </div>
            </div>

            <ul class="job-card__company-info-list">
                <li class="job-card__company-info-list-item">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="icons" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z"></path><circle cx="256" cy="192" r="48" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></circle></svg>
                    <h5>${jobPostData?.job_location?.name ? jobPostData?.job_location?.name : ""}</h5>
                </li>
                <li class="job-card__company-info-list-item">
                    <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" class="icons" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path></svg>
                    <h5>${jobPostData?.workPlaceType ? jobPostData.workPlaceType : ""}</h5>
                </li>
                <li class="job-card__company-info-list-item">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="icons" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect width="448" height="320" x="32" y="128" fill="none" stroke-linejoin="round" stroke-width="32" rx="48" ry="48"></rect><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M144 128V96a32 32 0 0132-32h160a32 32 0 0132 32v32m112 112H32m288 0v24a8 8 0 01-8 8H200a8 8 0 01-8-8v-24"></path></svg>
                    <h5>${jobPostData?.employementType ? jobPostData.employementType : ""}</h5>
                </li>
                <li class="job-card__company-info-list-item">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="icons" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17 6V4H6v2h3.5c1.302 0 2.401.838 2.815 2H6v2h6.315A2.994 2.994 0 0 1 9.5 12H6v2.414L11.586 20h2.828l-6-6H9.5a5.007 5.007 0 0 0 4.898-4H17V8h-2.602a4.933 4.933 0 0 0-.924-2H17z"></path></svg>
                    <h5>${jobPostData?.salary ? jobPostData.salary : ""}</h5>
                </li>
            </ul>
            
            <div class="job-card__company-about">
                <h3>Job Description</h3>
                <h3>About the Job</h3>
                <h3>Job Summary</h3>
                <p>${jobPostData?.description ? jobPostData?.description?.substring(200) : ""}</p>
            </div>
            
        </div>
    </a> 
    `
}

const ogCard = (ogTags) => {
    return `
    <a href=${ogTags?.url ? ogTags?.url : "#"} class="og-card" target="_blank">
        <h3 class="og_heading" style="font-size:1rem;">${ogTags.title ? ogTags.title : ""}</h3>
        <div class="og-card__meta">
            <div class="og-card__meta-img">
                ${ogTags.image ? `<img src="${ogTags.image}" alt="og image">` : ``}
            </div>
            <p>${ogTags.description ? ogTags.description : ""}</p>
        </div>
    </a>
    `
}

export { investorCard, startupCard, jobCard, ogCard };
export default messageCard;
