import React from "react";
import {  NavLink } from "react-router-dom";

const LoginBottomNavbar = () => {
	return (
		<>
			<ul className="flex justify-between fixed bottom-0 bg-white w-full px-4 login-navbar-mobile py-2">
				<NavLink to={"/news"}>
					<button className=" text-subHeadingTextColor text-base8 flex flex-col items-center">
						<img src="../img/icons/home.png" alt="" className="h-5 w-5" />
						News
					</button>
				</NavLink>
				<NavLink to="/publicstartup">
					<button className="flex flex-col items-center  text-subHeadingTextColor text-base8">
						<img src="../img/icons/startup.png" alt="" className="h-5 w-5" />{" "}
						Startup
					</button>
				</NavLink>
				<NavLink to="/publicinvestor">
					<button className="flex flex-col items-center  text-subHeadingTextColor text-base8">
						<img src="../img/icons/investor.png" alt="" className="h-5 w-5" />
						Investor
					</button>
				</NavLink>
				<NavLink to="/publicjobs">
					<button className="flex flex-col items-center  text-subHeadingTextColor text-base8">
						<img src="../img/icons/job.png" alt="" className="h-5 w-5" /> Jobs
					</button>
				</NavLink>
			</ul>
		</>
	);
};

export default LoginBottomNavbar;
