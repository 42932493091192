import React, { useState, useEffect, useContext } from "react";
import privateGetMethod from "../../apiUrls/privateUrl/privateGetMethod";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";
import MiniChatBox from "../MessangingPage/MiniChatBox";
// import ChatContext from "../Context/ChatContext";
import { removeChat } from "../../features/MiniChat/miniChatSlice";
import { useDispatch, useSelector } from "react-redux";

const ChatContainer = (props) => {
  const [chatRoomId, setChatRoomId] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const [allMessageStatus, setAllMessageStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [sendMessageData, setSendMessageData] = useState([]);
  const [user, setUser] = useState([]);
  const [userRoomId, setUserRoomId] = useState(null);
  const [messageLoading, setMessageLoading] = useState(true);
  const [deleteMessage, setDeleteMessage] = useState([]);
  const [messageValue, setMessageValue] = useState([]);
  const [miniChat, setMiniChat] = useState(false);
  const [clickedUser, setClickedUser] = useState("");

  // const { removeChat } = useContext(ChatContext);
  // const maxChats = useSelector(state => state.miniChat.maxChats);

  const dispatch = useDispatch();
  // const chatId = props.id;

  useEffect(() => {
    handleMessage(props.id);
  }, [props.id, props.maxChats]);

  const messageType = (e) => {
    e.preventDefault();
    setMessageValue(e.target.value);
  };

  const messageSend = (message) => {
    let data = {
      room: chatRoomId,
      message: message,
    };
    privatePostMethod(data, "chat/message_sending/", function (response) {
      if (response.status === 200) {
        setMessageValue([]);
      } else {
      }
    });
  };

  let userMessages = (roomId) => {
    privateGetMethod(
      "chat/chatroom_message/" + roomId + "/" + page + "/",
      function (response) {
        if (response.status === 200) {
          // setAllMessages((data) => data.concat(response.data.data.messages));
          setAllMessages(response.data.data.messages);
          if (page === 1) {
            setTimeout(() => {
              setAllMessageStatus(true);
            }, 200);
          }
        } else {
        }
      }
    );
  };

  const webSocketConnection = (roomId) => {
    var serverWs =
      // "ws://127.0.0.1:8000/chat/" +
      process.env.REACT_APP_CHAT_SOCKET_URL +
      roomId +
      "/?header=" +
      localStorage.getItem("accessToken");
    var chat = new WebSocket(serverWs);

    chat.onopen = () => {
      chat.send(
        JSON.stringify({
          command: "join",
          room: roomId,
        })
      );
    };

    chat.onmessage = (message) => {
      var data = JSON.parse(message.data);
      if (data.msg_type == 1 && data.del_status == 0) {
        setSendMessageData((sendMessageData) => [...sendMessageData, data]);
      } else if (data.msg_type === 0) {
        setUser(data);
        setUserRoomId(data.room_id);
        setMessageLoading(false);
      } else if (data.msg_type == 1 && data.del_status == 1) {
        setDeleteMessage((deleteMessage) => [...deleteMessage, data.msg_id]);
        // setDeleteMessage(data.msg_id);
      }
    };

    chat.onclose = () => {};
  };

  const handleMessage = (id) => {
    // =========comment this for testing===================
    let data = { chat_user_id: id };
    setSendMessageData([]);
    setClickedUser(id);
    setMiniChat(true);

    privatePostMethod(data, "chat/create_chat_request/", function (response) {
      // console.log(response);
      if (response.status === 200) {
        setChatRoomId(response.data.chatroom_id);
        userMessages(response.data.chatroom_id);
        webSocketConnection(response.data.chatroom_id);

        //:: set chatroom Id of clicked user
      }
    });
    // =========================================================
  };

  const handleMini = () => {
    setMiniChat(false);
    dispatch(removeChat(props.id));
  };

  return (
    <div
      style={{ right: props.right }}
      className={`${props.className} fixed h-[65vh]
         z-[1000] w-[300px] bg-white border-2 transition-all duration-150 ease-in-out border-[#dbdbdb] flex flex-col shadow-lg rounded-lg`}
    >
      <MiniChatBox
        user={user}
        allMessages={allMessages}
        chatRoomId={chatRoomId}
        messageValue={messageValue}
        setMessageValue={setMessageValue}
        handleMini={handleMini}
        messageSend={messageSend}
        sendMessageData={sendMessageData}
        deleteMessage={deleteMessage}
        messageLoading={messageLoading}
        clickedUser={clickedUser}
        allMessageStatus={allMessageStatus}
        className="!bottom-auto !block !h-full"
      />
    </div>
  );
};

export default ChatContainer;
