import axios from "axios";
import apiUrl from "../apiUrl";

const baseURL = process.env.REACT_APP_API_URL + "auth/"; //"http://127.0.0.1:8000/api/auth/";

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 50000,
  headers: {
    Authorization: localStorage.getItem("accessToken")
      ? "Token " + localStorage.getItem("accessToken")
      : null,
  },
});

// refresh accessToken after every 15 minutes
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    try {
      await axios
        .post(`${baseURL}token/refresh/`, { refresh: refreshToken })
        .then((response) => {
          // console.log("refreshed at ",new Date())
          localStorage.setItem("accessToken", response.data.access);
          localStorage.setItem("refreshToken", response.data.refresh);
        });
    } catch (error) {
      // console.log("error", error);
      alert("Something went wrong, Login again..!!");
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }
  } else {
    alert("Something went wrong, Login again..!!");
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  }
};
const refreshInterval = 15 * 60 * 1000;
setInterval(refreshAccessToken, refreshInterval);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (typeof error.response === "undefined") {
      alert(
        "A server/network error occurred. " +
          "Looks like CORS might be the problem. " +
          "Sorry about this - we will get it fixed shortly."
      );
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
      return Promise.reject(error);
    }
    if (
      error.response.status === 401 &&
      originalRequest.url === "token/refresh/"
    ) {
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
      return Promise.reject(error);
    }

    if (
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      const refreshToken = localStorage.getItem("refreshToken");

      if (refreshToken) {
        var atob = require("atob");
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post("token/refresh/", { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem("accessToken", response.data.access);
              localStorage.setItem("refreshToken", response.data.refresh);

              axiosInstance.defaults.headers["Authorization"] =
                "Token " + response.data.access;
              originalRequest.headers["Authorization"] =
                "Token " + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              // window.location.href = "/login";
            });
        } else {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      } else {
        localStorage.removeItem("accessToken");
        window.location.href = "/login";
      }
    }
    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
