import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import privateGetMethod from "../../apiUrls/privateUrl/privateGetMethod";
import imageUrl from "../../ImageUrl";
import MyCompaniesPage from "./MyCompaniesPage";
import Images from "../StaticImages/Images";

const Home_Profile = () => {
	useEffect(() => {
		userDetails();
	}, []);

	const tokenValue = localStorage.getItem("accessToken");
	useEffect(() => {
		if (tokenValue != null) {
			myCompanyDetails();
		}
	}, []);

	const [userData, setUserData] = useState("");
	const userDetails = () => {
		setUserData(JSON.parse(localStorage.getItem("userData")));
		// privateGetMethod("getProfile/userinfoData", function (response) {
		// 	if (response.status === 200) {
		// 		setUserData(response.data.data);
		// 	}
		// });
	};

	const [companyData, setCompanyData] = useState("");
	const myCompanyDetails = () => {
		privateGetMethod("getProfile/StartupHomeData", function (response) {
			if (response.status === 200) {
				setCompanyData(response.data.data);
			}
		});
	};

	const handleClick = (value) => {
		localStorage.setItem("searchType", value);
	};

	return (
		// <div className="home_container">
		<div className="left-container1">
			{userData == null || userData.length == 0 ? (
				<>
					<div className="profile w-full h-80 rounded-lg pb-4 bg-white shadow-lg relative">
						<img
							src="../img/icons/loader.gif"
							alt=""
							className="h-8 w-8 absolute top-4/12 left-1/2 transform -translate-x-1/2 
							mt-20"
						/>
					</div>
				</>
			) : (
				<>
					<div className="profile w-full rounded-lg pb-4 bg-white shadow-lg home-profile-section">
						{userData.UserImages.length === 0 ? (
							<>
								<img
									src={Images.defaultBg}
									alt=""
									className="bg-img w-full object-cover rounded-tr-lg rounded-tl-lg"
								/>
							</>
						) : (
							<>
								{userData.UserImages.map((item, index) => {
									return (
										<>
											{item.cover_image == null ? (
												<>
													<img
														src={Images.defaultBg}
														alt=""
														className="bg-img w-full object-cover rounded-tr-lg rounded-tl-lg"
													/>
												</>
											) : (
												<>
													<img
														src={item.cover_image}
														alt=""
														className="bg-img w-full object-cover rounded-tr-lg rounded-tl-lg"
														key={index}
														onError={(e) => {
															e.target.onerror = null;
															e.target.src = Images.defaultBg;
														}}
													/>
												</>
											)}
										</>
									);
								})}
							</>
						)}

						<Link to={"/profile"}>
							<div className="img">
								{userData.UserImages.length === 0 ? (
									<>
										<img
											src={Images.defaultProfile}
											alt=""
											className="profile-img rounded-full cursor-pointer border-2"
										/>
									</>
								) : (
									<>
										{userData.UserImages.map((item, index) => {
											return (
												<>
													{item.profile_image == null ? (
														<>
															<img
																src={Images.defaultProfile}
																alt=""
																className="profile-img rounded-full cursor-pointer border-2"
															/>
														</>
													) : (
														<>
															<img
																src={item.profile_image}
																alt=""
																className="profile-img rounded-full cursor-pointer border-2"
																key={index}
																onError={(e) => {
																	e.target.onerror = null;
																	e.target.src = Images.defaultProfile;
																}}
															/>
														</>
													)}
												</>
											);
										})}
									</>
								)}

								{userData.username && (
									<>
										<h2 className="name text-base text-center font-semibold">
											{userData.username}
										</h2>
									</>
								)}
								{userData.profileTitle == null ? (
									""
								) : (
									<>
										<p className="designation text-center cursor-default mb-2 text-xs">
											{userData.profileTitle.length < 30 ? (
												<>{userData.profileTitle}</>
											) : (
												<>{userData.profileTitle.slice(0, 30)}...</>
											)}
										</p>
									</>
								)}
							</div>
						</Link>
						<hr />

						<div className="views h-auto px-4">
							<ul className="text-primaryColor font-semibold">
								<Link to={"/savedjobs"} className="flex items-center">
									<img
										src="../img/icons/jobs.png"
										alt=""
										className="h-4 w-4 mr-2"
									/>
									<li
										className="hover:cursor-pointer text-xs py-2"
										onClick={() => handleClick("savedJob")}
									>
										Saved Jobs
									</li>
								</Link>
								<Link to={"/appliedjobs"} className="flex items-center">
									<img
										src="../img/icons/job.png"
										alt=""
										className="h-4 w-4 mr-2"
									/>
									<li
										className="hover:cursor-pointer text-xs py-2"
										onClick={() => handleClick("appliedJob")}
									>
										Applied Jobs
									</li>
								</Link>
								<Link to={"/savedstartup"} className="flex items-center">
									<img
										src="../img/icons/startup.png"
										alt=""
										className="h-4 w-4 mr-2"
									/>
									<li
										className="hover:cursor-pointer text-xs py-2"
										onClick={() => handleClick("savedStartup")}
									>
										Saved Startup
									</li>
								</Link>
								<Link to={"/savedinvestor"} className="flex items-center">
									<img
										src="../img/icons/investorNew.png"
										alt=""
										className="h-4 w-4 mr-2"
									/>
									<li
										className="hover:cursor-pointer text-xs py-2"
										onClick={() => handleClick("savedInvestor")}
									>
										Saved Investor
									</li>{" "}
								</Link>
								<Link to={"/myconnection"} className="flex items-center">
									<img
										src="../img/icons/investor.png"
										alt=""
										className="h-4 w-4 mr-2"
									/>
									<li
										className="hover:cursor-pointer text-xs py-2"
										onClick={() => handleClick("myConnection")}
									>
										My Connection
									</li>{" "}
								</Link>
								<Link to={"/sendconnection"} className="flex items-center">
									<img
										src="../img/icons/investor.png"
										alt=""
										className="h-4 w-4 mr-2"
									/>
									<li
										className="hover:cursor-pointer text-xs py-2"
										onClick={() => handleClick("sentConnection")}
									>
										Sent Requests
									</li>{" "}
								</Link>
								<Link to={"/receivedconnection"} className="flex items-center">
									<img
										src="../img/icons/investor.png"
										alt=""
										className="h-4 w-4 mr-2"
									/>
									<li
										className="hover:cursor-pointer text-xs py-2"
										onClick={() => handleClick("receivedConnection")}
									>
										Received Requests
									</li>{" "}
								</Link>
							</ul>
						</div>
					</div>

					{/* page section */}

					{companyData.length == 0 ? (
						""
					) : (
						<div className="page">
							<h4 className="main-heading font-semibold">My Company</h4>
							<>
								{companyData.map((item, index) => {
									return <MyCompaniesPage key={index} company={item} />;
								})}
							</>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default Home_Profile;
