import React from "react";
import { NavLink } from "react-router-dom";
import Images from "../StaticImages/Images";

const CompanyLogo = () => {
	const handleChange = (value) => {
		localStorage.setItem("searchType", value);
	};
	return (
		<>
			<NavLink to={"/home"}>
				<img
					src={Images.konnectorxLogo}
					alt="logo"
					className="header-company-logo"
					onClick={() => handleChange("home")}
				/>
			</NavLink>
			<NavLink to={"/home"}>
				<img
					src={Images.konnectorxLogoMobile}
					alt="logo"
					className="h-8 w-8 header-company-logo-mobile"
					onClick={() => handleChange("home")}
				/>
			</NavLink>
		</>
	);
};

export default CompanyLogo;
