import React from "react";
import { Link, NavLink } from "react-router-dom";
import Images from "../StaticImages/Images";
import LoginBottomNavbar from "./LoginBottomNavbar";

const LoginNavbar = ({ joinNow, signIn }) => {
	return (
		<>
			<nav id="navbar">
				<ul className="flex justify-between" id="login-navbar">
					<li className="w-2/4">
						<img
							src={Images.konnectorxLogo}
							alt=""
							className="h-10 login-company-logo"
						/>
						<img
							src={Images.konnectorxLogoMobile}
							alt="logo"
							className="h-10 login-company-logo-mobile"
						/>
					</li>
					<li className="flex w-2/4 items-center justify-between" >
						<NavLink to={"/news"}>
							<button className=" text-subHeadingTextColor login-navbar-desktop px-2 text-base8  flex flex-col items-center">
								<img src="../img/icons/home.png" alt="" className="h-5 w-5" />
								News
							</button>
						</NavLink>
						<NavLink to="/publicstartup">
							<button className="flex flex-col items-center  text-subHeadingTextColor login-navbar-desktop px-2 text-base8 ">
								<img
									src="../img/icons/startup.png"
									alt=""
									className="h-5 w-5"
								/>{" "}
								Startup
							</button>
						</NavLink>
						<NavLink to="/publicinvestor">
							<button className="flex flex-col items-center  text-subHeadingTextColor login-navbar-desktop px-2 text-base8 ">
								<img
									src="../img/icons/investor.png"
									alt=""
									className="h-5 w-5"
								/>
								Investor
							</button>
						</NavLink>
						<NavLink to="/publicjobs">
							<button className="flex flex-col items-center  text-subHeadingTextColor login-navbar-desktop px-2 text-base8 ">
								<img src="../img/icons/job.png" alt="" className="h-5 w-5" />{" "}
								Jobs
							</button>
						</NavLink>
						{joinNow == undefined ? (
							""
						) : (
							<>
								<Link to={"/registration"}>
									{/* <button className="font-semibold text-primaryColor flex flex-col items-center"> */}
									<button className="font-semibold text-primaryColor px-2 py-2">
										Join now
									</button>
								</Link>
							</>
						)}

						{signIn == undefined ? (
							""
						) : (
							<>
								<Link to={"/login"}>
									<button className="font-semibold text-primaryColor border px-4 py-2 rounded-full border-primaryColor hover:bg-primaryColor hover:text-white hover:transition-all">
										Sign in
									</button>
								</Link>
							</>
						)}
					</li>
				</ul>
			</nav>
			<LoginBottomNavbar />
		</>
	);
};

export default LoginNavbar;
