import React, { useState } from "react";
import { BsUpload } from "react-icons/bs";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";
import { AiOutlineFilePdf } from "react-icons/ai";
import Images from "../StaticImages/Images";
import ResponseAlert from "../Alerts/ResponseAlert";

const AttachmentsAddForm = (props) => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [title, setTitle] = useState([]);
	const [errorMessage, setErrorMessage] = useState([]);
	const [openResponse, setOpenResponse] = useState(false);
	const [errorKey, setErrorKey] = useState("");
	const [errorValue, setErrorValue] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleFileUpload = () => {
		setLoading(true);
		const formData = new FormData();
		formData.append("file", selectedFile, selectedFile.name);
		formData.set("title", title);
		formData.set("room", props.chatRoomId);
		privatePostMethod(formData, "chat/file_sending/", function (response) {
			if (response.data["isError"] == false) {
				setErrorValue(response.data.msg);
				setOpenResponse(true);
				setErrorStatus(false);
				props.handleFileClose();
			} else {
				setLoading(false);
				setOpenResponse(true);
				setErrorStatus(response.data["isError"]);
				setErrorValue(
					response.data["errors"].replace(
						/[`~!@#$%^&*()_|+\-=?;'",.<>\{\}\[\]\\\/]/gi,
						""
					)
				);
			}
		});
	};

	const handleFileSelect = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleChange = (e) => {
		setTitle(e.target.value);
	};

	const uploadDocument = () => {
		document.getElementById("documentSelect").click();
	};
	const removeSelectedFile = () => {
		setSelectedFile();
		setTitle("");
	};

	return (
		<>
			<ResponseAlert
				setOpenResponse={setOpenResponse}
				openResponse={openResponse}
				errorKey={errorKey}
				errorValue={errorValue}
				errorStatus={errorStatus}
			/>
			<div className="update-form chat-file-upload">
				{selectedFile == null ? (
					<>
						<input
							type="text"
							value={title}
							onChange={handleChange}
							placeholder="Title"
							className="border bg-slate-50 !w-full !pl-2 !py-2"
						/>

						<div className="attachment-upload-section upload-section !py-0">
							<span className="" onClick={uploadDocument}>
								{/* <BsUpload className="upload-logo " /> */}
								{/* <span className="upload-header ">Upload</span> */}
								<img src={Images.modalBg} alt="" />
							</span>
							<input
								type="file"
								onChange={handleFileSelect}
								style={{ display: "none" }}
								id="documentSelect"
								accept=".pdf"
							/>
						</div>
					</>
				) : (
					<>
						<input
							type="text"
							value={title}
							onChange={handleChange}
							placeholder="Title"
							className="border bg-slate-50 !w-full !pl-2 !py-2"
						/>
						<div className="attachment-upload-section upload-section w-full bg-gray-300 h-40">
							<span className="" onClick={uploadDocument}>
								<AiOutlineFilePdf className="upload-logo " />
							</span>
							<p style={{ fontWeight: "bold" }}>{selectedFile.name}</p>
						</div>
						{errorMessage && (
							<>
								<p className="investor-error">{errorMessage}</p>
							</>
						)}
					</>
				)}
				<div className=" flex justify-end">
					{selectedFile && (
						<button
							onClick={removeSelectedFile}
							className="image-remove-btn delete-btn !relative right-3"
						>
							Remove
						</button>
					)}
					{loading == true ? (
						<>
							<button className="save-btn  bg-gray-300 text-white !border-gray-300 hover:bg-gray-300 !relative">
								Sending
							</button>
						</>
					) : (
						<>
							<button className="save-btn !relative" onClick={handleFileUpload}>
								Send
							</button>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default AttachmentsAddForm;
