import React, { useState } from "react";
import ImageSendModal from "../Modal/ChatModal/ImageSendModal";
import FileSendModal from "../Modal/ChatModal/FileSendModal";
import { AiOutlineFilePdf } from "react-icons/ai";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import ReadMoreLess from "../ReadMoreLess";
import { Parser } from "html-to-react";
import moment from "moment";
import { RiAttachment2, RiH1 } from "react-icons/ri";
import { BsThreeDots } from "react-icons/bs";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";
import Images from "../StaticImages/Images";
import ReactQuill from "react-quill";
import ConfirmModal from "../Modal/ConfirmModal";
import { useRef } from "react";
import { useEffect } from "react";
import privateGetMethod from "../../apiUrls/privateUrl/privateGetMethod";
import UserMessage from "./UserMessage";
import { Modal } from "@mui/material";
import FileView from "./FileView";
import ImageSlider from "./ImageSlider";
import useMetaFetcher from "../MetaFetcher/useMetaFetcher";
import OgTagsRender from "./OgTagsRender";
import { ogCard } from "../../utils/MessageCard";

const MiniChatBox = (props) => {
  const user = props.user;
  const allMessages = props.allMessages;
  const chatRoomId = props.chatRoomId;
  const messageValue = props.messageValue;
  const messageType = props.messageType;
  const handleMini = props.handleMini;
  const messageSend = props.messageSend;
  const sendMessageData = props.sendMessageData;
  const noOfMiniChatBox = props.noOfMiniChatBox;
  const allMessageStatus = props.allMessageStatus;
  const regex = /\b(?:https?:\/\/|www\.)[^\s<]*/i;

  const extractedURL = (match) =>
    match
      ? match[0].startsWith("www")
        ? `https://${match[0]}`
        : match[0]
      : null;
  // ====================for checking date format=========================
  const isValid = (d) => moment(d, "MM/DD/YYYY", true).isValid();
  // =====================================================================
  const bucketUrl = process.env.REACT_APP_BUCKET_URL;

  // for og tags

  const { ogTags, loading: ogLoading } = useMetaFetcher(messageValue);

  const handleMessageSend = () => {
    if (ogTags) {
      messageSend(ogCard(ogTags) + messageValue);
    } else {
      messageSend(messageValue);
    }
  };

  // ==========for modal ========================================
  // ================Modal for Image upload==========================
  const [openImage, setOpenImage] = React.useState(false);
  const [scrollImage, setScrollImage] = React.useState("paper");
  const [sliderOpen, setSliderOpen] = React.useState(false);
  const [imageValue, setImageValue] = React.useState("");

  const handleImageOpen = (scrollType) => () => {
    setOpenImage(true);
    setScrollImage(scrollType);
  };

  const handleImageClose = () => {
    setOpenImage(false);
  };

  const handleImageSliderOpen = (item) => {
    setImageValue(item);
    setSliderOpen(true);
  };

  // ================================================================

  // ===============FOR SCROLL ALWAYS SHOW ON BOTTOM=======================
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (allMessageStatus == true) {
      setTimeout(() => {
        scrollToBottom();
      }, [500]);
    }
  }, [chatRoomId, sendMessageData, allMessageStatus]);

  // =================================================================

  // ===============Modal for File Upload============================

  const [openFile, setOpenFile] = React.useState(false);
  const [scrollFile, setScrollFile] = React.useState("paper");

  const handleFileOpen = (scrollType) => () => {
    setOpenFile(true);
    setScrollFile(scrollType);
  };

  const handleFileClose = () => {
    setOpenFile(false);
  };
  // ================================================================

  // ==============for textarea resize===============================
  const [inputResize, setInputResize] = useState(false);
  const resizingInput = () => {
    setInputResize(!inputResize);
  };

  // ================Delete Message===================================
  const [messageId, setMessageId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const handleDeleteMessage = (messageId) => {
    setModalOpen(true);
    // let data = {
    // 	room: chatRoomId,
    // 	message_id: messageId,
    // };
    // privatePostMethod(data, "chat/delete_message/", function (response) {
    // 	if (response.status === 200) {
    // 		setOpen(false);
    // 	} else {
    // 	}
    // });
  };
  // =================================================================

  // ===============for delete option================================
  const [open, setOpen] = useState(false);
  const handleOpen = (id) => {
    setMessageId(id);
    setOpen(!open);
  };

  // ================================================================

  const messagePersonType = localStorage.getItem("messageType");
  const [messageV, setMesagV] = useState([]);

  const handleScroll = (e) => {
    let element = e.target;
    if (element.scrollTop === 0 && allMessages.length != 0) {
      if (props.moreMessageStatus != "None") {
        // props.userMessages(chatRoomId);
      }
    } else {
    }
  };

  // =====================================================
  // const [morePage, setMorePage] = useState(1);
  // let userMoreMessages = (chatRoomId) => {
  // 	console.log(morePage);
  // 	privateGetMethod(
  // 		"chat/chatroom_message/" + chatRoomId + "/" + morePage + "/",
  // 		function (response) {
  // 			if (response.status === 200) {
  // 				props.setMoreMessageStatus(response.data.data.messages);
  // 				props.setAllMessages((data) =>
  // 					data.concat(response.data.data.messages)
  // 				);
  // 				// setAllMessages(response.data.data.messages);
  // 				if (morePage === 1) {
  // 					setTimeout(() => {
  // 						props.setAllMessageStatus(true);
  // 					}, 200);
  // 					setMorePage(morePage + 1);
  // 				}
  // 			} else {
  // 			}
  // 		}
  // 	);
  // };

  // =====================================================
  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const handleOpenFileModal = (fileUrl) => {
    setOpenFileModal(true);
    setFileUrl(fileUrl);
  };

  return (
    <>
      <div className={`${props.className} shadow-lg border`}>
        {props.messageLoading == true ? (
          <div className="mini-chat-loading loading">
            <img src="../img/icons/loader.gif" alt="" className="h-10 w-10" />
          </div>
        ) : (
          <>
            <div className="mini-chat-heading border-b-2">
              {props.messageLoading == true ? (
                ""
              ) : (
                <>
                  <ul>
                    <span>
                      <li>
                        {user.friend_image === "Default" ? (
                          <>
                            <img
                              src={Images.defaultProfile}
                              alt=""
                              className="mini-image"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={user.friend_image}
                              alt=""
                              className="mini-image"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = Images.defaultProfile;
                              }}
                            />
                          </>
                        )}
                      </li>

                      <li className="mini-user-details leading-4">
                        <h5 className="font-semibold mini-name-limit">
                          {user.friend}
                        </h5>
                        {user.friend_profile_title === "None" ? (
                          ""
                        ) : (
                          <>
                            <h6 className="text-xs ">
                              {user.friend_profile_title?.substring(0, 20) +
                                "..."}
                            </h6>
                          </>
                        )}
                      </li>
                    </span>
                    <span onClick={handleMini} className="mini-close">
                      <img
                        src="../img/icons/cross-btn.png"
                        alt=""
                        className="opacity-20 w-5 h-5"
                      />
                    </span>
                  </ul>
                </>
              )}
            </div>
            <>
              <div className="mini-chat-body px-1" onScroll={handleScroll}>
                <>
                  <ul className="mini-chat-user-messages">
                    {allMessages === undefined ? (
                      "say hello to user"
                    ) : (
                      <>
                        {[...allMessages].reverse().map((item, index) => {
                          return (
                            <>
                              {item.is_deleted === "True" ? (
                                ""
                              ) : (
                                <>
                                  <div>
                                    {/* <li>{item.message}</li> */}
                                    <li
                                      className={
                                        user.username === item.username
                                          ? "user-side"
                                          : "receiver-side"
                                      }
                                      key={index}
                                    >
                                      {item.profile_image === "Default" ? (
                                        <>
                                          <img
                                            src={Images.defaultProfile}
                                            alt=""
                                            className={
                                              user.username === item.username
                                                ? "none usermessage-image"
                                                : "receiver-left-image usermessage-image"
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={item.profile_image}
                                            alt=""
                                            className={
                                              user.username === item.username
                                                ? "none usermessage-image"
                                                : "receiver-left-image usermessage-image"
                                            }
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                Images.defaultProfile;
                                            }}
                                          />
                                        </>
                                      )}
                                      <>
                                        {item.msg_id == messageId ? (
                                          <>
                                            {open == true ? (
                                              <>
                                                <span className="relative flex items-center">
                                                  <p
                                                    onClick={() =>
                                                      handleDeleteMessage(
                                                        item.msg_id
                                                      )
                                                    }
                                                    className={
                                                      user.username ===
                                                      item.username
                                                        ? "userside-message-options message absolute -right-8 top-2/4 mt-2 px-2 py-1 rounded text-subHeadingTextColor bg-white shadow-md border"
                                                        : "receiverside-message-options message"
                                                    }
                                                  >
                                                    <span className="font-semibold text-base">
                                                      delete
                                                    </span>
                                                  </p>
                                                </span>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <span
                                          onClick={() =>
                                            handleOpen(item.msg_id)
                                          }
                                          className={
                                            user.username === item.username
                                              ? "userside-message-options message flex items-center pr-1 "
                                              : "receiverside-message-options message "
                                          }
                                        >
                                          <BsThreeDots />
                                        </span>
                                        {props.deleteMessage.includes(
                                          item.msg_id
                                        ) ? (
                                          <>
                                            <p className="bg-slate-200 flex items-center px-1 rounded-md font-semibold text-subHeadingTextColor">
                                              This message has been deleted
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <p
                                              className={
                                                user.username === item.username
                                                  ? "userside-message message userside-message-mini"
                                                  : "receiverside-message message receiverside-message-mini"
                                              }
                                            >
                                              {item.message === "" ? (
                                                <>
                                                  {item.file == "" ? (
                                                    <>
                                                      {item.image == "" ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          {item.title ===
                                                          "None" ? (
                                                            ""
                                                          ) : (
                                                            <>
                                                              <p>
                                                                {item.title}
                                                              </p>
                                                            </>
                                                          )}
                                                          <div
                                                            onClick={() =>
                                                              handleImageSliderOpen(
                                                                item
                                                              )
                                                            }
                                                            // href={
                                                            //   bucketUrl +
                                                            //   "/" +
                                                            //   item.image
                                                            // }
                                                            // target="_blank"
                                                          >
                                                            <img
                                                              src={
                                                                bucketUrl +
                                                                "/" +
                                                                item.image
                                                              }
                                                              alt=""
                                                              className="mini-message-image"
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item.title === "None" ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          <p>{item.title}</p>
                                                        </>
                                                      )}
                                                      <div
                                                        onClick={() =>
                                                          handleOpenFileModal(
                                                            bucketUrl +
                                                              "/" +
                                                              item.file
                                                          )
                                                        }
                                                        className={
                                                          user.username ===
                                                          item.username
                                                            ? "user-media-title file-flex pdf-flex"
                                                            : "receiver-media-title file-flex pdf-fle"
                                                        }
                                                        target="_blank"
                                                      >
                                                        {/* <img src="./img/icons/File.png" alt="" /> */}
                                                        <AiOutlineFilePdf className="message-pdf" />
                                                        <p>
                                                          {item.file
                                                            .split("/")[1]
                                                            .slice(0, 10)}
                                                          ...
                                                        </p>
                                                      </div>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {item.message.match(regex) !==
                                                  null ? (
                                                    <a
                                                      className="hover:underline hover:text-blue-700"
                                                      href={extractedURL(
                                                        item.message.match(
                                                          regex
                                                        )
                                                      )}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {Parser().parse(
                                                        item.message
                                                      )}
                                                    </a>
                                                  ) : item.message.length <
                                                    800 ? (
                                                    <>
                                                      {Parser().parse(
                                                        item.message
                                                      )}
                                                    </>
                                                  ) : (
                                                    <ReadMoreLess
                                                      limit={1000}
                                                      jsxFormat
                                                    >
                                                      {item.message}
                                                    </ReadMoreLess>
                                                  )}
                                                </>
                                              )}
                                            </p>
                                          </>
                                        )}
                                      </>
                                      {item.profile_image === "Default" ? (
                                        <>
                                          <img
                                            src={Images.defaultProfile}
                                            alt=""
                                            className={
                                              user.username === item.username
                                                ? "usermessage-image"
                                                : "none"
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={item.profile_image}
                                            alt=""
                                            className={
                                              user.username === item.username
                                                ? "usermessage-image"
                                                : "none"
                                            }
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                Images.defaultProfile;
                                            }}
                                          />
                                        </>
                                      )}
                                    </li>
                                    <li
                                      className={
                                        user.username === item.username
                                          ? "user-side-time"
                                          : "receiver-side-time"
                                      }
                                    >
                                      {isValid(item.natural_timestamp) ==
                                      true ? (
                                        <>
                                          {/* {moment(
																						item.natural_timestamp
																					).format("ll")} */}

                                          {moment(
                                            "" +
                                              new Date(item.natural_timestamp)
                                          ).format("lll")}
                                        </>
                                      ) : (
                                        <span>
                                          {/* {item.natural_timestamp} */}

                                          {moment(
                                            "" +
                                              new Date(item.natural_timestamp)
                                          ).format("lll")}
                                        </span>
                                      )}
                                    </li>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                      </>
                    )}
                    {/* {sendMessageData.map((item, index) => {
                        return (
                            <>
                                <li>{item.message}</li>
                            </>
                        );
                    })} */}
                    {sendMessageData.map((item, index) => {
                      return (
                        <>
                          {item === undefined ? (
                            ""
                          ) : (
                            <>
                              {props.deleteMessage.includes(
                                "" + item.msg_id
                              ) ? (
                                <>
                                  <>
                                    <li
                                      className={
                                        user.username === item.username
                                          ? "user-side"
                                          : "receiver-side"
                                      }
                                    >
                                      {item.profile_image === "Default" ? (
                                        <>
                                          <img
                                            src={Images.defaultProfile}
                                            alt=""
                                            className={
                                              user.username === item.username
                                                ? "none usermessage-image"
                                                : "receiver-left-image usermessage-image"
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={item.profile_image}
                                            alt=""
                                            className={
                                              user.username === item.username
                                                ? "none usermessage-image"
                                                : "receiver-left-image usermessage-image"
                                            }
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                Images.defaultProfile;
                                            }}
                                          />
                                        </>
                                      )}
                                      <p className="bg-slate-200 flex items-end px-1 py-1 rounded-md font-semibold text-subHeadingTextColor ">
                                        This message has been deleted
                                      </p>
                                      {item.profile_image === "Default" ? (
                                        <>
                                          <img
                                            src={Images.defaultProfile}
                                            alt=""
                                            className={
                                              user.username === item.username
                                                ? "  usermessage-image"
                                                : "none"
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={item.profile_image}
                                            alt=""
                                            className={
                                              user.username === item.username
                                                ? "usermessage-image"
                                                : "none"
                                            }
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                Images.defaultProfile;
                                            }}
                                          />
                                        </>
                                      )}
                                    </li>
                                  </>
                                </>
                              ) : (
                                <>
                                  {item.msg_status == 0 ? (
                                    <>
                                      <div>
                                        <li
                                          className={
                                            user.username === item.username
                                              ? "user-side"
                                              : "receiver-side"
                                          }
                                          key={index}
                                        >
                                          {item.msg_id == messageId ? (
                                            <>
                                              {open == true ? (
                                                <>
                                                  <span className="relative flex items-center">
                                                    <p
                                                      onClick={() =>
                                                        handleDeleteMessage(
                                                          item.msg_id
                                                        )
                                                      }
                                                      className={
                                                        user.username ===
                                                        item.username
                                                          ? "userside-message-options message  absolute -right-8 top-2/4 px-2 py-1 mt-2 rounded text-subHeadingTextColor bg-white shadow-md border"
                                                          : "receiverside-message-options message"
                                                      }
                                                    >
                                                      <span className="font-semibold text-base">
                                                        delete
                                                      </span>
                                                    </p>
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          <span
                                            onClick={() =>
                                              handleOpen(item.msg_id)
                                            }
                                            className={
                                              user.username === item.username
                                                ? "userside-message-options message flex items-center pr-1 "
                                                : "receiverside-message-options message "
                                            }
                                          >
                                            <BsThreeDots />
                                          </span>
                                          {item.profile_image === "Default" ? (
                                            <>
                                              <img
                                                src={Images.defaultProfile}
                                                alt=""
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "none usermessage-image"
                                                    : "receiver-left-image usermessage-image"
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={item.profile_image}
                                                alt=""
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "none usermessage-image"
                                                    : "receiver-left-image usermessage-image"
                                                }
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src =
                                                    Images.defaultProfile;
                                                }}
                                              />
                                            </>
                                          )}

                                          <>
                                            <p
                                              className={
                                                user.username === item.username
                                                  ? "userside-message message userside-message-mini"
                                                  : "receiverside-message message receiverside-message-mini"
                                              }
                                            >
                                              {item.message == undefined ? (
                                                <>
                                                  {item.image === null ? (
                                                    <>
                                                      {item.title ===
                                                      undefined ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          <p>{item.title}</p>
                                                        </>
                                                      )}
                                                      {item.file === null ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          <div
                                                            onClick={() =>
                                                              handleOpenFileModal(
                                                                bucketUrl +
                                                                  "/" +
                                                                  item.file
                                                              )
                                                            }
                                                            className={
                                                              user.username ===
                                                              item.username
                                                                ? "user-media-title file-flex"
                                                                : "receiver-media-title file-flex"
                                                            }
                                                          >
                                                            <AiOutlineFilePdf className="message-pdf" />
                                                            <p>
                                                              {item.file
                                                                .split("/")[1]
                                                                .slice(0, 10)}
                                                            </p>
                                                          </div>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item.title ===
                                                      undefined ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          <p>{item.title}</p>
                                                        </>
                                                      )}
                                                      <div
                                                        onClick={() =>
                                                          handleImageSliderOpen(
                                                            item
                                                          )
                                                        }
                                                        // href={
                                                        //   bucketUrl +
                                                        //   "/" +
                                                        //   item.image
                                                        // }
                                                        // target="_blank"
                                                      >
                                                        <img
                                                          src={
                                                            bucketUrl +
                                                            "/" +
                                                            item.image
                                                          }
                                                          alt=""
                                                          className="message-image"
                                                        />
                                                      </div>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {/* <p
													className={
														user.username === item.username
															? "userside-message message !max-w-full"
															: "receiverside-message message !max-w-full"
													}
												> */}

                                                  {item.message.match(regex) !==
                                                  null ? (
                                                    <a
                                                      className="hover:underline hover:text-blue-700"
                                                      href={extractedURL(
                                                        item.message.match(
                                                          regex
                                                        )
                                                      )}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {Parser().parse(
                                                        item.message
                                                      )}
                                                    </a>
                                                  ) : item.message.length <
                                                    800 ? (
                                                    <>
                                                      {Parser().parse(
                                                        item.message
                                                      )}
                                                    </>
                                                  ) : (
                                                    <ReadMoreLess
                                                      limit={1000}
                                                      jsxFormat
                                                    >
                                                      {item.message}
                                                    </ReadMoreLess>
                                                  )}
                                                  {/* </p> */}
                                                </>
                                              )}
                                            </p>
                                          </>
                                          {item.profile_image === "Default" ? (
                                            <>
                                              <img
                                                src={Images.defaultProfile}
                                                alt=""
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "usermessage-image"
                                                    : "none"
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={item.profile_image}
                                                alt=""
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "usermessage-image"
                                                    : "none"
                                                }
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src =
                                                    Images.defaultProfile;
                                                }}
                                              />
                                            </>
                                          )}
                                        </li>
                                        <li
                                          className={
                                            user.username === item.username
                                              ? "user-side-time flex justify-end text-xs mr-16 text-subHeadingTextColor"
                                              : "receiver-side-time  text-xs ml-16 py-px text-subHeadingTextColor"
                                          }
                                        >
                                          {isValid(item.natural_timestamp) ==
                                          true ? (
                                            <>
                                              {/* {moment(item.natural_timestamp).format("ll")} */}

                                              {moment(
                                                "" +
                                                  new Date(
                                                    item.natural_timestamp
                                                  )
                                              ).format("lll")}
                                            </>
                                          ) : (
                                            <>
                                              {/* {item.natural_timestamp} */}

                                              {moment(
                                                "" +
                                                  new Date(
                                                    item.natural_timestamp
                                                  )
                                              ).format("lll")}
                                            </>
                                          )}
                                        </li>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {props.clickedUser === item.user_id ? (
                                        <>
                                          <div>
                                            <li
                                              className={
                                                user.username === item.username
                                                  ? "user-side"
                                                  : "receiver-side"
                                              }
                                              key={index}
                                            >
                                              {item.profile_image ===
                                              "Default" ? (
                                                <>
                                                  <img
                                                    src={Images.defaultProfile}
                                                    alt=""
                                                    className={
                                                      user.username ===
                                                      item.username
                                                        ? "none usermessage-image"
                                                        : "receiver-left-image usermessage-image"
                                                    }
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <img
                                                    src={item.profile_image}
                                                    alt=""
                                                    className={
                                                      user.username ===
                                                      item.username
                                                        ? "none usermessage-image"
                                                        : "receiver-left-image usermessage-image"
                                                    }
                                                    onError={(e) => {
                                                      e.target.onerror = null;
                                                      e.target.src =
                                                        Images.defaultProfile;
                                                    }}
                                                  />
                                                </>
                                              )}

                                              <>
                                                <p
                                                  className={
                                                    user.username ===
                                                    item.username
                                                      ? "userside-message message userside-message-mini"
                                                      : "receiverside-message message receiverside-message-mini"
                                                  }
                                                >
                                                  {item.message == undefined ? (
                                                    <>
                                                      {item.image === null ? (
                                                        <>
                                                          {item.title ===
                                                          undefined ? (
                                                            ""
                                                          ) : (
                                                            <>
                                                              <p>
                                                                {item.title}
                                                              </p>
                                                            </>
                                                          )}
                                                          {item.file ===
                                                          null ? (
                                                            ""
                                                          ) : (
                                                            <>
                                                              <div
                                                                onClick={() =>
                                                                  handleOpenFileModal(
                                                                    bucketUrl +
                                                                      "/" +
                                                                      item.file
                                                                  )
                                                                }
                                                                className={
                                                                  user.username ===
                                                                  item.username
                                                                    ? "user-media-title file-flex"
                                                                    : "receiver-media-title file-flex"
                                                                }
                                                                target="_blank"
                                                              >
                                                                <AiOutlineFilePdf className="message-pdf" />
                                                                <p>
                                                                  {item.file
                                                                    .split(
                                                                      "/"
                                                                    )[1]
                                                                    .slice(
                                                                      0,
                                                                      10
                                                                    )}
                                                                </p>
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {item.title ===
                                                          undefined ? (
                                                            ""
                                                          ) : (
                                                            <>
                                                              <p>
                                                                {item.title}
                                                              </p>
                                                            </>
                                                          )}
                                                          <div
                                                            onClick={() =>
                                                              handleImageSliderOpen(
                                                                item
                                                              )
                                                            }
                                                            // href={
                                                            //   bucketUrl +
                                                            //   "/" +
                                                            //   item.image
                                                            // }
                                                            // target="_blank"
                                                          >
                                                            <img
                                                              src={
                                                                bucketUrl +
                                                                "/" +
                                                                item.image
                                                              }
                                                              alt=""
                                                              className="message-image"
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {/* <p
															className={
																user.username ===
																item.username
																	? "userside-message message !max-w-full"
																	: "receiverside-message message !max-w-full"
															}
														> */}

                                                      {item.message.match(
                                                        regex
                                                      ) !== null ? (
                                                        <a
                                                          className="hover:underline hover:text-blue-700"
                                                          href={extractedURL(
                                                            item.message.match(
                                                              regex
                                                            )
                                                          )}
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          {Parser().parse(
                                                            item.message
                                                          )}
                                                        </a>
                                                      ) : item.message.length <
                                                        800 ? (
                                                        <>
                                                          {Parser().parse(
                                                            item.message
                                                          )}
                                                        </>
                                                      ) : (
                                                        <ReadMoreLess
                                                          limit={1000}
                                                          jsxFormat
                                                        >
                                                          {item.message}
                                                        </ReadMoreLess>
                                                      )}
                                                      {/* </p> */}
                                                    </>
                                                  )}
                                                </p>
                                              </>
                                              {item.profile_image ===
                                              "Default" ? (
                                                <>
                                                  <img
                                                    src={Images.defaultProfile}
                                                    alt=""
                                                    className={
                                                      user.username ===
                                                      item.username
                                                        ? "usermessage-image"
                                                        : "none"
                                                    }
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <img
                                                    src={item.profile_image}
                                                    alt=""
                                                    className={
                                                      user.username ===
                                                      item.username
                                                        ? "usermessage-image"
                                                        : "none"
                                                    }
                                                    onError={(e) => {
                                                      e.target.onerror = null;
                                                      e.target.src =
                                                        Images.defaultProfile;
                                                    }}
                                                  />
                                                </>
                                              )}
                                            </li>
                                            <li
                                              className={
                                                user.username === item.username
                                                  ? "user-side-time flex justify-end text-xs mr-16 text-subHeadingTextColor"
                                                  : "receiver-side-time  text-xs ml-16 py-px text-subHeadingTextColor"
                                              }
                                            >
                                              {isValid(
                                                item.natural_timestamp
                                              ) == true ? (
                                                <>
                                                  {/* {moment(
													item.natural_timestamp
												).format("ll")} */}

                                                  {moment(
                                                    "" +
                                                      new Date(
                                                        item.natural_timestamp
                                                      )
                                                  ).format("lll")}
                                                </>
                                              ) : (
                                                <>
                                                  {/* {item.natural_timestamp} */}
                                                  {moment(
                                                    "" +
                                                      new Date(
                                                        item.natural_timestamp
                                                      )
                                                  ).format("lll")}
                                                </>
                                              )}
                                            </li>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </>
                <div ref={messagesEndRef} />
              </div>
              {messagePersonType == "Candidate" ? (
                <>
                  {user.friend_status == 0 && props.friendMsg == true ? (
                    <>
                      <p class="text-center absolute bottom-0 w-full flex justify-center text-sm font-semibold text-slate-500 py-5 bg-slate-200">
                        Wait for the user to accept the request
                      </p>
                    </>
                  ) : (
                    <>
                      <ul className="message-footer mini-chat-footer fixed w-[286px]">
                        <div className="flex flex-col w-full relative ">
                          {ogTags && (
                            <OgTagsRender
                              miniChat={true}
                              ogTags={ogTags}
                              loading={ogLoading}
                            />
                          )}
                          <ReactQuill
                            modules={{ toolbar: false }}
                            className="flex-col chat-quill border-none h-fit "
                            id="chat-quill-mini-chat"
                            onChange={props.setMessageValue}
                            value={messageValue}
                            contenteditable="true"
                          />
                        </div>
                        {/* <button onClick={resizingInput} className="absolute right-2">
						{inputResize == true ? (
							<IoIosArrowDown className="text-primaryColor" />
						) : (
							<IoIosArrowUp className="text-primaryColor" />
						)}
					</button> */}
                        <div className="flex justify-between items-center px-2 relative ">
                          <span className="flex">
                            <li className=" mini-chat-file-add h-6 w-6 flex items-center">
                              <img
                                src="../img/icons/image.png"
                                alt=""
                                className="add-image h-5 w-5"
                                onClick={handleImageOpen("paper")}
                              />
                              <ImageSendModal
                                openImage={openImage}
                                handleImageClose={handleImageClose}
                                scrollImage={scrollImage}
                                chatRoomId={chatRoomId}
                              />
                            </li>
                            <li className=" mini-chat-file-add h-6 w-6 flex items-center">
                              {/* <img
									src="../img/icons/attachment.png"
									alt=""
									className="add-file h-5 w-5 ml-2"
									onClick={handleFileOpen("paper")}
								/> */}

                              <RiAttachment2
                                className="add-file text-primaryColor h-8 w-8 ml-2"
                                onClick={handleFileOpen("paper")}
                              />
                              <FileSendModal
                                openFile={openFile}
                                handleFileClose={handleFileClose}
                                scrollFile={scrollFile}
                                chatRoomId={chatRoomId}
                              />
                            </li>
                          </span>

                          <li>
                            {messageValue.length === 0 ? (
                              <>
                                <button className="bg-primaryColor rounded-full mobile-send-message-btn">
                                  <img
                                    src="../img/icons/send.png"
                                    alt=""
                                    className="h-4 w-4 m-1 flex"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="bg-primaryColor rounded-full mobile-send-message-btn"
                                  onClick={handleMessageSend}
                                >
                                  <img
                                    src="../img/icons/send.png"
                                    alt=""
                                    className="h-4 w-4 m-1 flex"
                                  />
                                </button>
                              </>
                            )}
                          </li>
                        </div>
                      </ul>
                    </>
                  )}
                </>
              ) : (
                <>
                  {user.friend_status == 0 ? (
                    <>
                      <p class="text-center absolute bottom-0 w-full flex justify-center text-sm font-semibold text-slate-500 py-5 bg-slate-200">
                        You are no longer friend with {user.friend}
                      </p>
                    </>
                  ) : (
                    <>
                      <ul className="message-footer mini-chat-footer fixed w-[286px]">
                        <div className="flex flex-col w-full relative">
                          {ogTags && (
                            <OgTagsRender
                              miniChat={true}
                              ogTags={ogTags}
                              loading={ogLoading}
                            />
                          )}
                          <ReactQuill
                            modules={{ toolbar: false }}
                            className="flex-col chat-quill border-none h-fit"
                            id="chat-quill-mini-chat"
                            onChange={props.setMessageValue}
                            value={messageValue}
                            contenteditable="true"
                          />
                        </div>
                        {/* <ReactQuill
                          modules={{ toolbar: false }}
                          className="flex-col chat-quill border-none h-4"
                          id="chat-quill-mini-chat"
                          onChange={props.setMessageValue}
                          value={messageValue}
                          contenteditable="true"
                        /> */}
                        {/* <button onClick={resizingInput} className="absolute right-2">
						{inputResize == true ? (
							<IoIosArrowDown className="text-primaryColor" />
						) : (
							<IoIosArrowUp className="text-primaryColor" />
						)}
					</button> */}
                        <div className="flex justify-between items-center px-2 relative">
                          <span className="flex">
                            <li className=" mini-chat-file-add h-6 w-6 flex items-center">
                              <img
                                src="../img/icons/image.png"
                                alt=""
                                className="add-image h-5 w-5"
                                onClick={handleImageOpen("paper")}
                              />
                              <ImageSendModal
                                openImage={openImage}
                                handleImageClose={handleImageClose}
                                scrollImage={scrollImage}
                                chatRoomId={chatRoomId}
                              />
                            </li>
                            <li className=" mini-chat-file-add h-6 w-6 flex items-center">
                              {/* <img
                              src="../img/icons/attachment.png"
                              alt=""
                              className="add-file h-5 w-5 ml-2"
                              onClick={handleFileOpen("paper")}
                            /> */}

                              <RiAttachment2
                                className="add-file text-primaryColor h-8 w-8 ml-2"
                                onClick={handleFileOpen("paper")}
                              />
                              <FileSendModal
                                openFile={openFile}
                                handleFileClose={handleFileClose}
                                scrollFile={scrollFile}
                                chatRoomId={chatRoomId}
                              />
                            </li>
                          </span>

                          <li>
                            {messageValue.length === 0 ? (
                              <>
                                <button className="bg-primaryColor rounded-full mobile-send-message-btn 1">
                                  <img
                                    src="../img/icons/send.png"
                                    alt=""
                                    className="h-4 w-4 m-1 flex  222"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="bg-primaryColor rounded-full mobile-send-message-btn 2"
                                  onClick={handleMessageSend}
                                >
                                  <img
                                    src="../img/icons/send.png"
                                    alt=""
                                    className="h-4 w-4 m-1 flex 1111"
                                  />
                                </button>
                              </>
                            )}
                          </li>
                        </div>
                      </ul>
                    </>
                  )}
                </>
              )}{" "}
            </>
            {/* )} */}
          </>
        )}
      </div>
      {modalOpen && (
        <ConfirmModal
          setOpenModal={setModalOpen}
          // reload={props.personDetails}
          idData={{
            room: chatRoomId,
            message_id: messageId,
          }}
          url={"chat/delete_message/"}
          dialogMessage={"Delete Message ?"}
          name={"message"}
          post={true}
          setOpen={setOpen}
        />
      )}
      {sliderOpen && (
        <ImageSlider
          image={imageValue}
          chatRoomId={chatRoomId}
          allMessages={allMessages}
          sliderOpen={sliderOpen}
          setSliderOpen={setSliderOpen}
        />
      )}
      <Modal open={openFileModal} onClose={() => setOpenFileModal(false)}>
        <FileView fileUrl={fileUrl} ref={React.createRef()} />
      </Modal>
    </>
  );
};

export default MiniChatBox;
