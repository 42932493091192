import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chats: [],
  maxChats: 3,
};

export const miniChatSlice = createSlice({
  name: "miniChat",
  initialState,
  reducers: {
    addChat: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (!state.chats.includes(action.payload)) {
        if(state.chats.length >= state.maxChats) {
          state.chats = state.chats.slice(1);
          state.chats.push(action.payload);
        }else{
          state.chats.push(action.payload);
        }
      }
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setMaxChats(state, action){
      state.maxChats = action.payload;
      if(state.chats.length > action.payload){
        state.chats = state.chats.slice(state.chats.length - action.payload);
      }
    },
    removeChat: (state, action) => {
      const newState = {...state};
      newState.chats = newState.chats.filter((chat) => chat !== action.payload);
      state = newState;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addChat, removeChat, setChats, setMaxChats } = miniChatSlice.actions;

export default miniChatSlice.reducer;
