import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import privatePostMethod from "../../../apiUrls/privateUrl/privatePostMethod";
import privateGetMethod from "../../../apiUrls/privateUrl/privateGetMethod";
import useRazorpay from "react-razorpay";
import OfferApplied from "./OfferApplied";

const CheckoutModal = ({ planId, handleCheckoutModal, price }) => {
  const Razorpay = useRazorpay();
  const [paymentDetails, setPaymentDetails] = useState("");
  const [couponData, setCouponData] = useState("");
  const [couponId, setCouponId] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [offerModal, setOfferModal] = useState(false);

  useEffect(() => {
    applyCoupon();
  }, []);

  const applyCoupon = () => {
    privateGetMethod("payment/coupons/", (response) => {
      if (response.status === 200) {
        setCouponData(response.data);
      }
    });
  };

  const getPaidSubscription = (id, couponId, event) => {
    privatePostMethod(
      `${JSON.stringify({ plan_id: id, coupon_code: couponId })}`,
      `payment/CreateMembership/`,
      (response) => {
        if (response.data.isError !== true) {
          console.log(response.data);
          setPaymentDetails(response.data.msg);
          // console.log("payment", paymentAmount);

          setTimeout(() => {
            paymentHandler(response.data.msg, event);
          }, 10);

          // handleOpenSubscriptionModal();
        } else {
          console.log("not");
        }
      }
    );
  };

  const paymentHandler = (data, event) => {
    const options = {
      key: "rzp_test_8LcRNUm1mWg2hg",
      currency: "INR",
      name: "Konnectorx",
      description: "Thank you for your purchase",
      order_id: data.razorpay_order_id,
      amount: data.razorpay_amount,
      handler: (response)=>{
        // console.log(response)
        alert("payment successful"+ response.razorpay_order_id)
      },
      theme: {
        color: "#619BF2",
      },
      callback_url:"https://dev.konnectorx.com:8000/api/payment/PaymentSuccessView/",
    };

    var rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      // window.location.href = '/createjob';
      alert("response.error.code", response.error.code);
      alert("response.error.description", response.error.description);
      alert("response.error.source", response.error.source);
      alert("response.error.step", response.error.step);
      alert("response.error.reason", response.error.reason);
      alert(
        "esponse.error.metadata.order_id",
        response.error.metadata.order_id
      );
      alert(
        "response.error.metadata.payment_id",
        response.error.metadata.payment_id
      );
    });

    // rzp1.on("payment.success", function (response) {
    //   alert("payment done")
    //   window.location.href = '/payment-status';
    // });

    rzp1.open();
  };

  const getDate = () => {
    const date = new Date();
    return date.toISOString().slice(0, 10);
  };

  const verifyCoupon = () => {
    const currentDate = getDate();
    couponData?.map((item) => {
      if (couponCode === item.code) {
        setOfferModal(true);
        if (item.valid_from <= currentDate && currentDate <= item.valid_to) {
          setCouponId(item.id);
          setCouponApplied(item);
        } else {
          setCouponApplied("Coupon expired");
        }
        // console.log("match");
      } else {
        setOfferModal(true);
        setCouponApplied(false);
        // console.log("unMatch");
      }
    });
  };

  const calculateDiscount = (price, discount) => price * (discount / 100);

  const handleClear = () => {
    setCouponCode("");
    setCouponApplied(false);
    setCouponId("");
  };

  return (
    <>
      <Dialog open={handleCheckoutModal} onClose={handleCheckoutModal}>
        <DialogTitle
          style={{ cursor: "pointer" }}
          className="text-center !text-2xl !font-bold z-0"
        >
          Checkout
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Enter your coupon code</DialogContentText>
          <DialogContentText>
            <div className="container flex relative">
              <input
                type="text"
                className="border-black border w-full h-12  px-4 !pr-36"
                value={couponCode}
                onChange={(event) => {
                  setCouponCode(event.target.value);
                }}
              />
              <div className="btn-container absolute flex right-2 gap-4 align-center text-blue-700 px-4  pt-3">
                <button className="hover:text-blue-500" onClick={verifyCoupon}>
                  Apply
                </button>
                <button className="hover:text-blue-500" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>
            <div className="summary mt-8 border-t border-dashed border-slate-500 ">
              <p className="mt-4 text-xl text-slate-600 ">Summary</p>
              <p className="flex justify-between mt-4 mr-4">
                Plan price <span className="text-slate-700">₹ {price}</span>
              </p>
              {couponApplied !== false
                ? couponApplied !== "Coupon expired" && (
                    <div className="offer bg-green-200 rounded-lg shadow-md my-5 p-4">
                      <p className="flex justify-between text-xl text-green-600">
                        Code applied!!{" "}
                        <span>
                          - ₹{" "}
                          {calculateDiscount(
                            price,
                            couponApplied.discount_percentage
                          )}
                        </span>
                      </p>
                    </div>
                  )
                : ""}
              <p className="flex justify-between mt-3 mr-4">
                Amount to be paid{" "}
                <span className="text-slate-700">
                  ₹{" "}
                  {couponApplied !== false && couponApplied !== "Coupon expired"
                    ? price -
                      calculateDiscount(
                        price,
                        100 - couponApplied.discount_percentage
                      )
                    : price}
                </span>
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCheckoutModal}>Cancel</Button>
          <Button
            onClick={(event) => getPaidSubscription(planId, couponId, event)}
          >
            Pay
          </Button>
        </DialogActions>
        {offerModal && (
          <OfferApplied
            coupon={couponApplied}
            offerModal={offerModal}
            setOfferModal={setOfferModal}
          />
        )}
      </Dialog>
    </>
  );
};

export default CheckoutModal;
