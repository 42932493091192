import React, { forwardRef, useState } from "react";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";
import SubscriptionSuccessModal from "../Modal/SubscriptionModals/SubscriptionSuccessModal";
import CheckoutModal from "../Modal/SubscriptionModals/CheckoutModal";

const DetailedPage = forwardRef((props, ref) => {
  const color = props.color;
  const cardHeader = props.cardHeader;
  const cardLeftContent = props.cardLeftContent;
  const cardListData = props.cardListData;
  const active = props.active;

  const [plan, setPlan] = useState("");

  const [planId , setPlanId] = useState(null)
  const [successModal, setSuccessModal] = useState(false);
  const [checkoutModal, setCheckoutModal] = useState(false);

  const getFreeSubscription = () => {
    privatePostMethod("", "payment/create-free-membership/", (response) => {
      if (response.data.isError !== true) {
        console.log(response);
        handleSubscriptionModal();
      } else {
        console.log("not");
      }
    });
  };


  const handleApiCall = () => {
    if (cardHeader === "Free") {
      getFreeSubscription();
    } else if (cardHeader === "Gold") {
      setPlanId(2);
      handleCheckoutModal();
    } else if (cardHeader === "Platinum") {
      setPlanId(3);
      handleCheckoutModal();
    }
  };

  const handleSubscriptionModal = () => {
    setSuccessModal(!successModal);
  };

  const handleCheckoutModal = () =>{
    setCheckoutModal(!checkoutModal)
  }


  return (
    <>
      {active && (
        <div
          className={`flex  bg-white !m-0 gap-15 h-screen min-[320px]:max-xl:flex-col min-[320px]:max-xl:h-fit ${checkoutModal&& ("blur-sm")} `}
          ref={ref}
        >
          <div
            className={`detail-left-container flex flex-col justify-center bg-${color}-100 rounded-tr-full min-[320px]:max-xl:rounded-none min-[320px]:max-xl:mb-8 p-20 max-[516px]:p-8`}
          >
            <h3 className={`text-4xl text-${color}-600 font-semibold mt-24`}>
              {cardHeader}
            </h3>
            <h1 className={`text-9xl text-${color}-800 font-bold mt-6`}>
              {cardLeftContent[0]}
            </h1>
            <p className="text-xl text-slate-900 font-medium mt-4">
              {cardLeftContent[1]}
            </p>
            <div className="section mt-10">
              <button
                className="text-center w-1/2 bg-blue-600 p-3 rounded-3xl hover:bg-blue-800"
                onClick={handleApiCall}
              >
                <span className="text-base  text-white font-medium">
                  Start my free month
                </span>
              </button>
              <p className="text-black font-medium mt-2">
                After your free month, pay as little as ₹1,567.79* / month
              </p>
              <p className="text-sm text-slate-400 font-thin">
                Cancel anytime. We'll remind you 7 days before your trial ends.
              </p>
            </div>
          </div>
          <div className="detail-right-container  flex flex-col justify-center pl-6 pr-20 min-[320px]:max-xl:pl-14 min-[320px]:max-xl:pb-14 ">
            <div className="section text-sm  leading-6">
              <p className="text-black font-semibold">
                Find and contact anyone
              </p>
              <p className="text-slate-500">
                Find and contact the right people to grow your network and see
                who's viewed your profile
              </p>
              <ul className="leading-8 text-base">
                {cardListData.slice(0, 3).map((item) => {
                  return <li className="list-disc">{item}</li>;
                })}
              </ul>
            </div>

            <div className="section text-sm mt-8 w-3/4 leading-6">
              <p className="text-black font-semibold">
                Exclusive insights to get ahead
              </p>
              <p className="text-slate-500">
                Stay informed about companies of interest, see how you compare
                to other job applicants, and more
              </p>
              <ul className="leading-8 text-base">
                {cardListData.slice(3, 5).map((item) => {
                  return <li className="list-disc">{item}</li>;
                })}
              </ul>
            </div>

            <div className="section text-sm mt-8 w-3/4 leading-6">
              <p className="text-black font-semibold">
                Stay up-to-date on the latest skills
              </p>
              <p className="text-slate-500">
                Grow and learn new skills to advance your career and
                professional brand
              </p>
              <ul className="leading-8 text-base">
                {cardListData.slice(5, 6).map((item) => {
                  return <li className="list-disc">{item}</li>;
                })}
              </ul>
            </div>
          </div>
          {successModal && (
            <SubscriptionSuccessModal
              handleSubscriptionModal={handleSubscriptionModal}
            />
          )}
          {checkoutModal && (
            <CheckoutModal
            planId = {planId}
            handleCheckoutModal = {handleCheckoutModal}
            price = {props.price}
              // handleCloseSubscriptionModal={handleCloseSubscriptionModal}
              // handleOpenSubscriptionModal={handleOpenSubscriptionModal}
            />
          )}
        </div>
      )}
    </>
  );
});

export default DetailedPage;
