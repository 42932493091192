import privateGetMethod from "../../apiUrls/privateUrl/privateGetMethod";
import { load } from "cheerio";
import { useEffect, useState } from "react";;

// const parseHTML = (html) => {
//   const $ = load(html);
//   return $;
// };

// const extractOGTags = ($) => {
//   const ogTags = {};
//   $('meta[property^="og:"]').each((_, element) => {
//     const property = $(element).attr("property");
//     const content = $(element).attr("content");
//     ogTags[property] = content;
//   });
//   return ogTags;
// };

// const fetchWebsiteData = async (url) => {
//   axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

//   try {
//     const response = await axios.get(url, {
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         Accept: "text/html",
//       },
//     });
//     const html = response.data;
//     return html;
//   } catch (error) {
//     // console.error("Error fetching website data:", error);
//   }
// };

const extractHttpsUrlsFromHTML = (htmlString) => {
  const regex = /https:\/\/[^\s<"]+/g; // Regular expression to match HTTPS URLs
  const matches = htmlString.toString().match(regex) ||  []; // Extract all matches or an empty array
  return matches;
};

const useMetaFetcher = (htmlContent) => {
  const [loading, setLoading] = useState(true);
  const [ogTags, setOgTags] = useState(false);
  
  // const fetchMetaTags = async () => {
  //   const url = extractHttpsUrlsFromHTML(htmlContent)[0];
  //   const html = await fetchWebsiteData(url);
  //   const $ = parseHTML(html);
  //   const ogTags = extractOGTags($);
  //   setOgTags(ogTags);
  //   setLoading(false);
  // };

  const fetchMetaData = async () => {
    const url = extractHttpsUrlsFromHTML(htmlContent)[0];
    setLoading(true);

    if(url === undefined){
      setOgTags(false);
      setLoading(false);
      return;
    }
    
    privateGetMethod(`crawl/metadata/?url=${url}`, function (response) {
      // console.log(response)
      if (response?.status === 200) {
        const { data } = response;
        setOgTags(data);
        setLoading(false);
      }else{
        setOgTags(false);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchMetaData();
  }, [htmlContent]);

  return { loading, ogTags };
};

export default useMetaFetcher;
