import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
const SubscriptionSuccessModal = ({handleSubscriptionModal}) => {
  return (
    <div>
    <Dialog
      open={handleSubscriptionModal}
      onClose={handleSubscriptionModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'pointer' }} >
        Success..!!!
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have subscribed to our free plan.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={handleSubscriptionModal}>
          Cancel
        </Button>
        <Button autoFocus >
          <Link to="/createjob">
          Start Posting Jobs
          </Link>
        </Button>
      </DialogActions>
    </Dialog>
  </div>
  )
}

export default SubscriptionSuccessModal