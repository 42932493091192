import React, { useState } from "react";
// import { Parser } from "html-to-react";
import parse from "html-react-parser";

const ReadMoreLess = ({ children, limit, jsxFormat, childPresent }) => {
	// console.log(children)
	const [isReadMore, setReadMore] = useState(false);
	const toggleBtn = () => {
		setReadMore((prevState) => !prevState);
	};
	if (childPresent) {
		var sub = children.props.children?.substr(0, limit);
	} else {
		var sub = children?.substr(0, limit);
	}

	return (
		<>
			<>
				{jsxFormat != undefined ? (
					<>
						{isReadMore
							? childPresent
								? parse(children.props.children)
								: parse(children)
							: parse(sub)}
						<span className="read-more-btn" onClick={toggleBtn}>
							{isReadMore ? "...read less" : "...read more"}
						</span>
					</>
				) : (
					<>
						{isReadMore ? parse(children) : parse(children.substr(0, limit))}
						<span className="read-more-btn" onClick={toggleBtn}>
							{isReadMore ? "...read less" : "...read more"}
						</span>
					</>
				)}
			</>
		</>
	);
};

export default ReadMoreLess;
