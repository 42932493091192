import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AdsImage from "../AdsImage";
import Home_Profile from "../homePage/HomeProfile";
import PageLoading from "../LoadingComponent/PageLoading";
import Navbar from "../navbar/Navbar";

const Layout = () => {
	const [pageLoader, setPageLoader] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setPageLoader(false);
		}, 2000);
	}, []);
	return (
		<>
			{pageLoader == true ? (
				<>
					<PageLoading />
				</>
			) : (
				<>
					<div id="three-section-home-container">
						<div className="left-container">
							<Home_Profile />
						</div>
						<div className="mid-container">
							<Outlet />
						</div>
						<div className="right-container">
							<AdsImage />
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Layout;
