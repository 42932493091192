import React, { useContext, useEffect, useState } from "react";
import MessageBox from "./MessageBox";
import ChatContainer from "./ChatContainer";
import { useSelector, useDispatch } from "react-redux";
import { setMaxChats } from "../../features/MiniChat/miniChatSlice";
const MessageContainer = (props) => {
  // const { chats } = useContext(ChatContext);
  const {chats, maxChats} = useSelector((state) => state.miniChat);
  const dispatch = useDispatch();

  useEffect(() => {
    if(window.screen.width >= 1200){
      dispatch(setMaxChats(3));
    }
    else if(window.screen.width < 1200 && window.screen.width > 900){
      dispatch(setMaxChats(2))
    }
    else if(window.screen.width <= 900){
      dispatch(setMaxChats(1));
    }
  }, [chats, maxChats]);
  return (
    <>
      <MessageBox />
      {chats.map((chat, index) => {
        return (
          <ChatContainer
            id={chat}
            key={index}
            maxChats={maxChats}
            right={`${(index + 1) * 300}px`}
            className={`top-[35vh] right-[${(index + 1) * 20}vw]`}
          />
        );
      })}
    </>
  );
};

export default MessageContainer;
