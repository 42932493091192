import React from "react";
import LoginNavbar from "../LoginScreens/LoginNavbar";
import Footer from "../LoginScreens/Footer";

const accessToken = localStorage.getItem("accessToken");

const Privacy = () => {
  return (
    <>
      {!accessToken && <LoginNavbar joinNow={"joinNow"} />}
      <div className="mt-20 privacy-container bg-white">
        <div className="bg-primaryBgColor py-5 text-white font-semibold rounded-t-md">
          <h2 className="!text-3xl !pt-2">Privacy & Policy</h2>
          <p className="!text-sm !pt-1 !text-black">
            Last modified: oct 24 2022
          </p>
        </div>
        <p>
          We, at konnectorx , are committed to respecting your online privacy
          and recognize the need for appropriate protection and management of
          any personally identifiable information you share with us. This
          Privacy Policy ("Policy") describes how Konnectorx collects, uses,
          discloses and transfers personal information of users through its
          websites and applications. Konnectorx is vigilant about protecting
          your privacy and recognizes the importance of maintaining the privacy
          of every user who visits our Site. We value your privacy and
          appreciate your trust. We are committed to be transparent about the
          data we collect about you, how it is used and with whom it is shared.
          By visiting and/or using our Site, you agree to this Privacy Policy.
          We strongly recommend that you read this Privacy Policy so that you
          understand our approach towards the use of your personal data. By
          accepting the policy at the time of registration, you explicitly
          approve and provide consent to our collection, storage, use and
          disclosure of your personal information as described in this Policy
          and terms and conditions.{" "}
        </p>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          This Privacy Policy (“Policy”) can be updated at any time. We
          encourage users to frequently check this page for any changes. You
          acknowledge and agree that it is your responsibility to review this
          Policy periodically and become aware of those modifications.
        </p>
        <h2>What Information do we Collect</h2>
        <p>
          We collect information directly from you, from third-parties and
          automatically through our Sites/ mobile applications. When you
          visit/surf our Site, certain personal information about you such as
          your IP Address, etc. may be automatically stored with us. However, if
          you choose to avail of certain services on our Site, you shall be
          required to provide certain personal information for the registration
          and/or access to such services/web pages e.g. contact details, resume
          details, location data. Such information may include, without
          limitation, your first and last name, user name & password, email
          address, contact address, mobile/telephone number(s), sex, age,
          occupation, interests, credit card information, billing information,
          financial information, content, IP address, standard web log
          information, information about your computer hardware and software and
          such other information as may be required for your interaction with
          the services and from which your identity is discernible. We may also
          collect demographic information that is not unique to you such as
          code, preferences, favorites, etc. Further, sometimes you may be asked
          to provide descriptive, cultural, preferential and social & life style
          information. In addition to the above we may indirectly also collect
          information about you when you use certain third party services
          available on our Site. We may also collect certain information about
          the use of our Site by you, such as the services you access/use or
          areas you visit. We use this information to deliver our web pages to
          you upon request, to tailor our Site to the interests of our Site
          users, to measure traffic on the Site. This information may also be
          used by our advertisers / third party companies to personalize
          content, ads etc.
        </p>
        <h2>How we collect Information</h2>
        <p>
          We collect information directly from you when you register with us.{" "}
          <br />
          <span className="ml-4 block">
            1. We collect information (whether you are registered or not
            registered with us) when you browse our Sites/apps, open or respond
            to an email from us (promotional or informational). <br />
            2. When you post a comment on our Site or raise a query/question to
            us through phone or email. <br />
            3. We collect information from you when you register with us by
            linking your social media or third party accounts. By doing this,
            you are authorizing them to share with us certain information from
            such accounts, and authorizing us to collect, store, and use this in
            accordance with this Policy. <br />
            4. We collect information from you using third party tools, browser
            cookies and web beacons in order to improve user experience. <br />
            5. We may collect non-personal information about the computer,
            mobile device or other device you use to access the service, such as
            IP address, geo-location information, unique device identifiers,
            browser type, browser language and other information for the purpose
            of providing customized information on the browser. <br />
            6. Our mobile application and Sites may capture your current
            location if you choose to enable GPS feature in the app or browser.
          </span>
        </p>
        <h2> Use of Information</h2>
        <p>
          If you choose to provide us with the above mentioned information, you
          consent to the use, transfer, processing and storage of the
          information so provided by you on our servers. The information
          provided by you shall not be given to third parties (third parties for
          this purpose do not include our group / holding / subsidiary companies
          and or our service partners / associates) for marketing purposes and
          other related activities without your prior consent. Though if you
          choose to make your profile visible, all information pertaining to
          your profile will be visible to third parties.{" "}
        </p>
        <h2>The information provided by you shall be used by us to:</h2>
        <p>
          <span className="ml-4 block">
            1. Improve our Site and enable us to provide you the most
            user-friendly experience which is safe, smooth and customized.{" "}
            <br />
            2. Improve and customize our services, content and other commercial
            / non-commercial features on the Site. <br />
            3. Contact you to get your opinion on our current products and
            services or possible new products and/or services that may be
            offered by us. <br />
            4. Send you information on our products, services, special deals,
            promotions. <br />
            5. Ask you to send feedback on any or all of our services. <br />
            6. Send you marketing/promotional communications (If you do not wish
            to receive such marketing/promotional communications from us you may
            indicate your preferences at the time of registration or by
            following the instructions provided on the Site or by providing
            instructions to this effect). <br />
            7. Create various surveys and analysis in form of reports. <br />
            8. Send newsletter(s) to you (Provided you subscribe to the same).{" "}
            <br />
            9. Send you service-related announcements on rare occasions when it
            is necessary to do so. <br />
            10. Provide you the opportunity to participate in contests or
            surveys on our Site (If you participate, we may request certain
            additional personally identifiable information from you. Moreover,
            participation in these surveys or contests is shall be completely
            voluntary and you therefore shall have a choice whether or not to
            disclose such additional information). <br />
            11. Contact you or send you e-mail or other communications regarding
            updates on our Site, new job opportunities, services and
            additional/new job postings/openings which may be of your interest.{" "}
            <br />
            12. Provide customer support and the services you request. <br />
            13. Ascertain how many people visit our Site, of what kind and what
            areas they visit, on our Site. <br />
            14. Resolve disputes, if any and troubleshooting. <br />
            15. Avoid/check illegal and/or potentially prohibited activities and
            to enforce Agreements. <br />
            16. Provide service updates and promotional offers related to our
            services/products. <br />
            17. Comply with any court judgment / decree / order / directive /
            legal & government authority /applicable law. <br />
            18. Investigate potential violations or applicable national &
            international laws. <br />
            19. Investigate deliberate damage to the Site/services or its
            legitimate operation. <br />
            20. Detect, prevent, or otherwise address security and/or technical
            issues. <br />
            21. Protect the rights, property or safety of HTML and/or its
            Directors, employees and the general public at large. <br />
            22. Respond to Claims of third parties. <br />
            23. The members of our corporate family and group, affiliates,
            service providers and third parties under a contract to provide
            joint services, contents and marketing communications. <br />
            24. Other third parties to whom you explicitly require us to send
            the information.
          </span>
          If you consent to receive information about third party (such as
          employers, recruiters, data aggregators, marketers or others)
          opportunities, products or services/job opportunities or you make your
          profile visible we may disclose your information to such third parties
          for sending you communications via e-mail or otherwise to inform about
          new opportunities/services/products and new job postings/openings
          which may be of your interest. Also, we might use your personal
          information to communicate with you and ascertain if you are
          interested in third party services. Your information shall be
          disclosed to our employees/service providers who provide services to
          us vis-a-vis our Site and the services/products therein. These service
          providers/employees will have access to the information provided by
          you in order to perform their functions/services efficiently. If you
          choose to use our referral service to tell a friend about our Site or
          refer a job posting/service, we will ask you for your friend's name
          and email address. We will automatically send your friend an email
          inviting him or her to visit the Site. However, your friend may
          contact us to request that he does not require such
          services/content/email or we remove the information related to him
          from our database. Based upon the personally identifiable information
          you provide us, we will send you a welcoming email to verify your
          username and password. We will also communicate with you in response
          to your inquiries with respect to our services/content, to provide the
          services you request, and to manage your account. We will communicate
          with you by email or telephone, in accordance with your wishes. We
          will also send you SMS alerts from time to time to update you on new
          matched jobs and other updates. In order to provide certain services
          to you, we may on occasion supplement the personal information you
          submitted to us with information from third party sources (third
          parties for this purpose may include our group / holding / subsidiary
          companies and or our service partners /associates). We reserve the
          right to disclose your personally identifiable information as required
          by law and when we believe that disclosure is necessary to protect our
          rights and/or to comply with a judicial proceeding, court order, or
          legal process served on our Site.{" "}
        </p>
        <h2>Account Protection</h2>
        <p>
          Your password is the key to your account / profile. You shall be
          solely responsible for all the activities happening under your
          username and you shall be solely responsible for keeping your password
          secure. Do not disclose your password to anyone. If you share your
          password or your personal information with others, you shall be solely
          responsible for all actions taken under your username and you may lose
          substantial control over your personal information and may be subject
          to legally binding actions taken on your behalf. Therefore, if your
          password has been compromised for any reason, you should immediately
          change your password.{" "}
        </p>
        <h2>Security </h2>
        <p>
          The security of your personal information is important to us. When you
          enter your personal information, we treat the data as an asset that
          must be protected and use tools (encryption, passwords, physical
          security, etc.) to protect your personal information against
          unauthorized access and disclosure. However, no method of transmission
          over the Internet, or method of electronic storage, is 100 percent
          (100%) secure, therefore, while we strive to use commercially
          acceptable means to protect your personal information, we cannot
          guarantee its absolute security nor can we guarantee that third
          parties shall not unlawfully intercept or access transmissions or
          private communications, and that other users may abuse or misuse your
          personal information that you provide. Therefore, although we work
          hard to protect your information, we do not promise, and you should
          not expect, that your personal information or private communications
          will always remain private. Please note that information collected by
          third parties may not have the same security protections as
          information you submit to us, and we are not responsible for
          protecting the security of such information.{" "}
        </p>
        <h2>Links to Other Sites</h2>
        <p>
          The Site contains links to other Sites that are not owned or
          controlled by us. Please be aware that the Site, are not responsible
          for the privacy practices of such other websites. We encourage you to
          be aware when you leave our Site and to read the privacy statements of
          each and every Website that collects personally identifiable
          information. This privacy statement applies only to information
          collected by the Site or to our other related Sites provided it
          appears at the footer of the page therein. It does not apply to third
          party advertisements which appear on our Sites and we suggest you read
          the privacy statements of such advertisers.
        </p>
        <h2>Contact Us</h2>
        <p>
          All questions, comments and requests regarding this policy should be
          addressed to: Mailing Address: 5th Floor, Unit No.-534-535 ,Spaze
          ITech Park, Sohna Road ,Sector 49, Gurgaon 122018 Haryana
        </p>
        <p className="!text-black !font-semibold">
          <span className="font-semibold">Email</span> : support@konnectorx.com
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
