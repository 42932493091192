import axios from "axios";
import apiUrl from "../../apiUrl";
import axiosInstance from "../axio";

const privatePostMethod = (body, path, callback, add_headers = {}) => {
	let headers = {
		Authorization: `Token ${localStorage.getItem("accessToken")}`,
		"Content-Type": "application/json",
		accept: "application/json",
		...add_headers,
	};
	axiosInstance
		.post(apiUrl + path, body, {
			headers: headers,
		})
		.then((Response) => {
			callback(Response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export default privatePostMethod;
