import axios from "axios";
import apiUrl from "../../apiUrl";

const privateDeleteMethod = (body, path, callback) => {
	let headers = {
		Authorization: `Token ${localStorage.getItem("accessToken")}`,
	};
	axios
		.delete(apiUrl + path, {
			headers: headers,
			data: body,
		})
		.then((Response) => {
			callback(Response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export default privateDeleteMethod;
