import React, { useEffect, useState } from "react";
import CompanyLogo from "./CompanyLogo";
import NavbarIcons from "./NavbarIcons";
import SearchBar from "./SearchBar";

const Navbar = () => {
	return (
		<>
			<nav id="navbar">
				<ul className="content">
					<li className="header-logo">
						<CompanyLogo />
					</li>
					<li className="header-search">
						<SearchBar />
					</li>

					<>
						<li className="header-icons">
							<NavbarIcons />
						</li>
					</>
				</ul>
			</nav>
		</>
	);
};

export default Navbar;
