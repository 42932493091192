import React, { useState, useEffect } from "react";
import Loader from "../LoadingComponent/Loader"

const FileView = React.forwardRef((props, ref) => {

  return (
    <div className="pdf-container absolute left-1/2 h-full -translate-x-1/2 min-w-96 w-4/5 py-6">
      <iframe
        src={`${props.fileUrl}#view=fit&zoom=100`}
        title="testPdf"
        height="100%"
        width="100%"
      />
    </div>
  );
});

export default FileView;