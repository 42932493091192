import * as React from "react";
import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Container,
	Grid,
	makeStyles,
	Typography,
	Modal,
	CardHeader,
	DialogContent,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContentText,
	Button,
} from "@material-ui/core";
import { MdClear } from "react-icons/md";
import FileSendLayout from "../../MessangingPage/FileSendLayout";

export default function ScrollDialog(props) {
	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (props.openFile) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [props.openFile]);

	return (
		<div>
			<Dialog
				open={props.openFile}
				onClose={props.handleFileClose}
				scroll={props.scrollFile}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				className="dialog-box"
				id="image-modal"
			>
				<DialogTitle id="scroll-dialog-title">
					<Grid container spacing={3}>
						<Grid item xs={11}>
							Add File
						</Grid>
						<Grid item xs={1} align={"left"}>
							<div onClick={props.handleFileClose}>
								<MdClear className="cross-btn" />
							</div>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent dividers={props.scrollFile === "paper"}>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						<FileSendLayout
							openFile={props.openFile}
							handleFileClose={props.handleFileClose}
							scrollFile={props.scrollFile}
							chatRoomId={props.chatRoomId}
						/>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}
