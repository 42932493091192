import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { useUser } from "../Context/UserContext";
import Chat from "./Chat";
import privateGetMethod from "../../apiUrls/privateUrl/privateGetMethod";

const MessageBox = () => {
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleRecentSearch = () => {
    setLoading(true);
    privateGetMethod(
      "chat/get_recent_chatroom_messages/?search=" + search,
      function (response) {
        if (response.status === 200) {
          setChats(response.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          setChats([]);
        }
      }
    );
  };

  useEffect(() => {
    handleRecentSearch();
  }, [search]);

  const user = useUser();
  return (
    <>
      <div
        className={`message-box hidden lg:flex fixed ${
          showMessageBox ? "top-[35vh]" : "top-[calc(100vh-3rem)]"
        } right-0 z-[1000] w-[300px] h-[65vh] bg-white border-2 transition-all duration-150 ease-in-out border-[#dbdbdb] flex-col shadow-lg rounded-lg`}
      >
        <div className="messageBox-header border-b border-[#dbdbdb] flex px-2 items-center h-12">
          <div className="messageBox-header-left flex gap-2 items-center">
            <Avatar
              className="!w-8 !h-8"
              src={user?.UserImages?.[0]?.profile_image}
            />
            <h3 className="font-semibold">Messaging</h3>
          </div>
          <div className="messageBox-header-right ml-auto">
            {showMessageBox ? (
              <MdKeyboardArrowDown
                onClick={() => setShowMessageBox(false)}
                className="text-2xl !w-8 !h-8 cursor-pointer hover:bg-slate-200 rounded-full p-1"
              />
            ) : (
              <MdKeyboardArrowUp
                onClick={() => setShowMessageBox(true)}
                className="text-2xl !w-8 !h-8 cursor-pointer hover:bg-slate-200 rounded-full p-1"
              />
            )}
          </div>
        </div>
        <div className="messageBox-search overflow-y-auto py-3 px-4 justif-center shadow-md ">
          <div className="messageBox-search-input flex items-center gap-2 px-2 py-2 bg-sky-100 rounded-md hover:border border-black">
            <AiOutlineSearch className="text-md text-gray-500" />
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="flex-1 outline-none bg-transparent text-sm"
            />
          </div>
        </div>

        <ul className="chatContainer py-2 flex flex-col overflow-y-scroll flex-1">
          {chats.map((chat, index) => (
            <Chat chat={chat} key={index} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default MessageBox;
