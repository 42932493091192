import React from "react";
import { Link } from "react-router-dom";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";
import Images from "../StaticImages/Images";
import AboutUs from "../PrivacyPage/AboutUs";
import Privacy from "../PrivacyPage/Privacy";

const DropDownMenu = ({ userData }) => {
	const handleLogout = () => {
		const data = localStorage.getItem("refreshToken");
		privatePostMethod(
			{ refress_token: data },
			"auth/logout",
			function (response) {
				if (response.status == 200) {
					localStorage.clear();
					window.location.href = "/login";
				} else {
				}
			}
		);
	};

	return (
		<>
			<div className="drop-down-menu">
				<ul className="menu-list w-64 shadow-lg border">
					<li className="flex items-center">
						{userData == null ? (
							<>
								<img
									src={Images.defaultProfile}
									alt=""
									className="w-8 h-8 rounded-full m-2"
								/>
							</>
						) : (
							<>
								{userData?.UserImages?.length === 0 ? (
									<>
										<img
											src={Images.defaultProfile}
											alt=""
											className="w-8 h-8 rounded-full m-2"
										/>
									</>
								) : (
									<>
										{/* {userData?.UserImages[0].profile_image == undefined ? (
									<>
										<img
											src={Images.defaultProfile}
											alt=""
											className="w-8 h-8 rounded-full m-2"
										/>
									</>
								) : (
									<>
										<img
											src={userData?.UserImages[0].profile_image}
											alt=""
											className="w-8 h-8 rounded-full m-2"
										/>
									</>
								)} */}
										{userData?.UserImages?.map((item) => {
											return (
												<>
													{item?.profile_image == null ? (
														<>
															<img
																src={Images.defaultProfile}
																alt=""
																className="w-8 h-8 rounded-full m-2"
															/>
														</>
													) : (
														<>
															<img
																src={item?.profile_image}
																alt=""
																className="w-8 h-8 rounded-full m-2"
																onError={(e) => {
																	e.target.onerror = null;
																	e.target.src = Images.defaultProfile;
																}}
															/>
														</>
													)}
												</>
											);
										})}
									</>
								)}
							</>
						)}

						<span className="leading-3">
							<span className="font-semibold text-base9 block">
								{userData?.username?.length < 18 ? (
									<>{userData?.username}</>
								) : (
									<>{userData?.username?.slice(0, 17)}...</>
								)}
							</span>
							{userData?.profileTitle == undefined ? (
								""
							) : (
								<>
									<span className="text-xs">
										{userData?.profileTitle?.length < 20 ? (
											<>{userData?.profileTitle}</>
										) : (
											<>{userData?.profileTitle?.slice(0,20)}...</>
										)}
									</span>
								</>
							)}
						</span>
					</li>
					<Link
						to={"/profile"}
						className="cursor-pointer hover:font-semibold transition-all"
					>
						<li className="flex justify-center mb-2">
							<button className="w-11/12 mt-1 border-2 rounded-full py-0.5 px-1 text-sm border-primaryColor hover:bg-primaryLightColor cursor-default">
								View Profile
							</button>
						</li>
					</Link>

					<li className="menu-items">
						<Link to={"/createcompany"} className="flex items-center">
							<img
								src="../img/icons/company.png"
								alt=""
								className="mr-2 w-5 h-5"
							/>
							<span> Company</span>
						</Link>
					</li>

					<li className="menu-items">
						<Link to={"/createjob"} className="flex items-center">
							<img
								src="../img/icons/jobs.png"
								alt=""
								className="mr-2 w-5 h-5"
							/>
							<span>Post Jobs </span>
						</Link>
					</li>
					{userData?.UserJobPosted.length == 0 ? (
						""
					) : (
						<>
							<li className="menu-items">
								<Link to={"/PostedJobList"} className="flex items-center">
									<img
										src="../img/icons/jobs.png"
										alt=""
										className="mr-2 w-5 h-5"
									/>
									<span>Posted Jobs </span>
								</Link>
							</li>
							<li className="menu-items">
								<Link to={"/resume-search"} className="flex items-center">
									<img
										src="../img/icons/jobs.png"
										alt=""
										className="mr-2 w-5 h-5"
									/>
									<span>Resume Search </span>
								</Link>
							</li>
						</>
					)}
					<li className="menu-items flex items-center">
						<Link to={"/blogs"} className="flex items-center">
							<img
								src="../img/icons/blog.svg"
								alt=""
								className="mr-2 w-5 h-5"
							/>
							<span> Blogs</span>
						</Link>
					</li>
					<li className="menu-items flex items-center">
						<Link to={"/changepassword"} className="flex items-center">
							<img
								src="../img/icons/setting.png"
								alt=""
								className="mr-2 w-5 h-5"
							/>
							<span> Settings</span>
						</Link>
					</li>
					<li className="menu-items flex items-center">
						<Link to={"/aboutus" }className="flex items-center">
							<img
								src="../img/icons/info.png"
								alt=""
								className="mr-2 w-5 h-5"
							/>
							<span> About us</span>
						</Link>
					</li>
					<li className="menu-items flex items-center">
						<Link to={"/privacy"} className="flex items-center">
							<img
								src="../img/icons/privacy.png"
								alt=""
								className="mr-2 w-5 h-5"
							/>
							<span> Privacy Policy</span>
						</Link>
					</li>

					<li
						className="menu-items flex items-center cursor-pointer"
						onClick={handleLogout}
					>
						<img
							src="../img/icons/logout.png"
							alt=""
							className="mr-2 w-5 h-5"
						/>
						<span> Logout</span>
					</li>
				</ul>
			</div>
		</>
	);
};

export default DropDownMenu;
