import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
	const navigate = useNavigate();
	const [pageType, setPageType] = useState("");

	useEffect(() => {
		let getSearchType = localStorage.getItem("searchType");
		setPageType(getSearchType);
		setSearchValue("");
	}, [localStorage.getItem("searchType")]);
	const [searchValue, setSearchValue] = useState();
	const [enterStatus, setEnterStatus] = useState(0);

	const handleKey = (e) => {
		if (e.key == "Enter") {
			if (searchValue.length != 0) {
				// setEnterStatus("Enter");
				setEnterStatus(enterStatus+1)
				localStorage.setItem("Enter", enterStatus)
			}
		}
	};
	const handleChange = (event) => {
		setSearchValue(event.target.value);
		switch (pageType) {
			case "home":
				navigate("/Home?search=" + event.target.value);
				break;
			case "network":
				navigate("/network?search=" + event.target.value);
				break;
			case "investor":
				navigate("/Investor?search=" + event.target.value);
				break;
			case "startup":
				navigate("/StartUp?search=" + event.target.value);
				break;
			case "jobs":
				navigate("/Jobs?search=" + event.target.value);
				break;
			case "savedJob":
				navigate("/savedjobs?search=" + event.target.value);
				break;
			case "savedInvestor":
				navigate("/savedinvestor?search=" + event.target.value);
				break;
			case "savedStartup":
				navigate("/savedstartup?search=" + event.target.value + enterStatus);
				break;
			case "appliedJob":
				navigate("/appliedjobs?search=" + event.target.value);
				break;
			case "myConnection":
				navigate("/myconnection?search=" + event.target.value);
				break;
			case "sentConnection":
				navigate("/sendconnection?search=" + event.target.value);
				break;
			case "receivedConnection":
				navigate("/receivedconnection?search=" + event.target.value);
				break;
			case "profile":
				break;
			default:
				return 0;
		}
	};

	return (
		<>
			<input
				type="text"
				name="search"
				value={searchValue}
				placeholder="Search"
				onChange={handleChange}
				onKeyDown={handleKey}
			/>
		</>
	);
};

export default SearchBar;
{
	/* <div className="registration-container">
						<div className="reg-company-logo">
							<img
								src="./img/companyLogo/konnectorxLogo.png"
								alt="Company Logo"
							/>
						</div>
						<form className="registration-form" onSubmit={handleSubmit}>
							<h1 className="reg-header">Sign Up</h1>
							<label htmlFor="text">Full Name</label>
							<input
								className={errors.name && touched.name && "error"}
								type="text"
								name="name"
								placeholder="Enter name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.name}
								autoComplete="off"
							/>

							{errors.name && touched.name && (
								<div className="input-feedback">{errors.name}</div>
							)}

							<label htmlFor="email">Email</label>
							<input
								name="email"
								type="email"
								placeholder="Enter email"
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								className={errors.email && touched.email && "error"}
								autoComplete="off"
							/>
							{errors.email && touched.email && (
								<div className="input-feedback">{errors.email}</div>
							)}

							<label htmlFor="text">Phone</label>
							<input
								name="mobile_number"
								type="tel"
								className={
									errors.mobile_number && touched.mobile_number && "error"
								}
								placeholder="Enter phone number"
								value={values.mobile_number}
								onChange={handleChange}
								onBlur={handleBlur}
								autoComplete="off"
							/>

							{errors.mobile_number && touched.mobile_number && (
								<div className="input-feedback">{errors.mobile_number}</div>
							)}

							<label htmlFor="Password">Password</label>
							<input
								name="user_password"
								type="password"
								placeholder="Enter password"
								value={values.user_password}
								onChange={handleChange}
								onBlur={handleBlur}
								className={
									errors.user_password && touched.user_password && "error"
								}
							/>

							{errors.user_password && touched.user_password && (
								<div className="input-feedback">{errors.user_password}</div>
							)}

							<label htmlFor="confirm_password">Confirm Password</label>
							<input
								name="confirm_password"
								type="password"
								placeholder="Confirm password"
								value={values.confirm_password}
								onChange={handleChange}
								onBlur={handleBlur}
								className={
									errors.confirm_password && touched.confirm_password && "error"
								}
							/>

							{errors.confirm_password && touched.confirm_password && (
								<div className="input-feedback">{errors.confirm_password}</div>
							)}
							<button type="submit" className="sign_in-btn">
								Sign in
							</button>
							<div>
								<p className="login-page-redirect">
									Already have an account? &nbsp;
									<Link to={"/"}>
										<span>Sign in</span>
									</Link>
								</p>
							</div>
						</form>
					</div> */
}
