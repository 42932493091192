const compareDateTimeString = (prevTimeString, timeFormat="full") => {
    const presentTime = new Date();
    const prevTime = new Date(prevTimeString)

    const durationBtw = (presentTime.getTime() - prevTime.getTime())/1000;

    if(durationBtw >= 0 && durationBtw < 3600){
        return `${Math.floor(durationBtw / 60)}${timeFormat === "full" ? " min" : 'm' }`;
    }
    else if(durationBtw >= 3600 && durationBtw < 3600*24){
        return `${Math.floor(durationBtw / 3600)}${timeFormat === "full" ? " hours" : 'h' }`;
    }
    else if(durationBtw >= 3600 * 24 && durationBtw < 3600*24*7){
        return `${Math.floor(durationBtw / (3600 * 24))}${timeFormat === "full" ? " days" : 'd' }`;
    }
    else if(durationBtw >= 3600 * 24 * 7 && durationBtw < 3600*24*7*30){
        return `${Math.floor(durationBtw / (3600*24*7) )}${timeFormat === "full" ? " weeks" : 'w' }`
    }
    else{
        return new Date(prevTime).toDateString();
    }
}

export default compareDateTimeString;