import React from "react";
import Loader from "../LoadingComponent/Loader";

const checkOverflow = (element) => {
  const curOverflow = element.style.overflow;

  if (!curOverflow || curOverflow === "visible")
    element.style.overflow = "hidden";

  const isOverflowing =
    element.clientWidth < element.scrollWidth ||
    element.clientHeight < element.scrollHeight;

  element.style.overflow = curOverflow;

  return isOverflowing;
};

const OgTagsRender = (props) => {
  
  const elementRef = React.useRef(null);

  return (
    <div className={`${props.miniChat ? 'z-[9999] w-full -top-[2px] -left-[2px]' : 'left-[4.4rem] w-[calc(100%-7.8rem)]'} ogtags-renderer border  absolute -translate-y-[calc(100%+0.5rem)] min-h-42 bg-white rounded-md  flex px-4 py-6 shadow-md flex-col gap-4 ${props.className}`}>
      {props.loading ? (
        <Loader className="!my-[1.35rem]" />
      ) : (
        <>
          <div className={`flex flex-col md:flex-row ${props.miniChat && 'flex-col'} gap-2 `}>
            {props.ogTags?.image && (
              <img
                className={`${props.miniChat ? "w-12" : "w-32" } self-start`}
                src={props.ogTags?.image}
                alt="ogtags"
              />
            )}
            <div className="flex flex-col gap-1 overflow-hidden">
              <h1 className="text-lg font-semibold font-sans" ref={elementRef}>{props.ogTags?.title}</h1>
              <a href={props.ogTags?.url} className="text-sm overflow-hidden text-blue-500">
                {props.ogTags?.url}
              </a>
            </div>
          </div>
          <p className="text-sm">{props.ogTags?.description}</p>
        </>
      )}
    </div>
  );
};

export default OgTagsRender;
