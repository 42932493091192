import LoginNavbar from "../LoginScreens/LoginNavbar";
import Footer from "../LoginScreens/Footer";

const accessToken = localStorage.getItem("accessToken");

const AboutUs = () => {
  return (
    <>
      {!accessToken && <LoginNavbar joinNow={"joinNow"} />}
      <div className="mt-20 aboutus-container bg-white">
        <div className="bg-primaryBgColor py-5 text-white font-semibold rounded-t-md">
          <h2 className="!text-3xl !pt-2">About Us</h2>
        </div>

        <div class="markdown prose w-full break-words dark:prose-invert dark">
          <p>
            At konnectorx, we believe in the power of startups to drive
            innovation and create new opportunities. Our platform is designed to
            bring together investors, startups, and job seekers in a seamless
            and intuitive way, making it easy for everyone to find the right
            opportunities and connect with the right people.
          </p>

          <p>
            We offer a wide range of tools and services, from job boards and
            networking events to fundraising and consulting services, all geared
            towards supporting startups and entrepreneurs as they grow and
            thrive.
          </p>

          <p>
            <strong>Our mission </strong> is to provide a platform to connect
            startups and entrepreneurs with the funding, resources, and talent
            they need to succeed. We aim to foster an environment where
            entrepreneurs can connect with investors and other service
            providers, and where talented job seekers can find opportunities to
            work with exciting and growing companies.
          </p>

          <p>
            We believe that great ideas and talented people should have the
            opportunity to make a difference, and we're committed to helping
            them do just that.
          </p>

          <p>
            <strong>Our vision </strong> is to build a vibrant and thriving
            startup ecosystem, where innovative ideas can take root and grow
            into successful businesses. We believe that by connecting startups,
            investors, and job seekers, we can help build a brighter future for
            all.
          </p>

          <p>
            we at konnectorx want to create a world where anyone with a great
            idea, passion, and determination can build a successful business and
            make a positive impact. We believe that by providing a platform that
            facilitates connections and empowers entrepreneurs, we can help
            create a more diverse, innovative, and sustainable future for us
            all.
          </p>

          <p>
            Through our platform, we provide a range of tools and services to
            help startups and other businesses succeed. Whether you're a startup
            looking for funding, an investor looking for the next big thing, or
            a job seeker looking for a career in the startup world, we're here
            to help you connect and grow. At konnectorx, we're dedicated to
            making the startup ecosystem more accessible, supportive, and
            dynamic, and we're excited to work with you on this journey.
          </p>

          <p>
            Our platform features a comprehensive database of startups and
            investors, as well as a job board where job seekers can find
            opportunities at these companies. We use advanced algorithms and
            data analytics to match startups with the right investors and job
            seekers with the right opportunities.
          </p>

          <p>
            Overall, konnectorx platform aim to simplify the process of
            connecting startups, founders, investors, and job seekers, and
            provide a valuable resource for anyone looking to find job
            opportunities, raise funds, or connect with the startup community.
          </p>

          <p>
            Thank you for choosing konnectorx. We look forward to helping you
            achieve your goals and build a better future for us all.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
