import React from "react";
import Images from "../StaticImages/Images";

const PageLoading = () => {
	return (
		<div className="loading-main-container">
			<div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
				<img src={Images.konnectorxLogo} alt="" className="h-20" />
				<div className="demo-container">
					<div className="progress-bar">
						<div className="progress-bar-value"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageLoading;
