import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import privateGetMethod from "../../apiUrls/privateUrl/privateGetMethod";

const userContext = createContext();
const userUpdateContext = createContext();

export const useUser = () => {
	return useContext(userContext);
};
export const useUserUpdate = () => {
	return useContext(userUpdateContext);
};

export const UserProvider = ({ children }) => {
	const tokenValue = localStorage.getItem("accessToken");
	const [userData, setUserData] = useState("");
	useEffect(() => {
		tokenValue && userDetails();
	}, []);

	const userDetails = () => {
		privateGetMethod("getProfile/userinfoData", function (response) {
			if (response.status === 200) {
				localStorage.setItem("userData", JSON.stringify(response.data.data));
				setUserData(response.data.data);
			}
		});
	};

	return (
		<userContext.Provider value={userData}>
			<userUpdateContext.Provider value={userDetails}>
				{children}
			</userUpdateContext.Provider>
		</userContext.Provider>
	);
};
