import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DropDownMenu from "./DropDownMenu";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";
import TabletDropDown from "./TabletDropDown";
import Images from "../StaticImages/Images";
import { useUser } from "../Context/UserContext";

const NavbarIcons = () => {
	const userData = useUser();

	const [open, setOpen] = useState(false);
	const btnRef = useRef();
	const tabletBtnRef = useRef();
	const [tabletOpen, setTabletOpen] = useState(false);
	const [mobileOpen, setMobileOpen] = useState(false);
	const handleOpen = () => {
		setOpen(!open);
	};
	const handleTabletOpen = () => {
		setTabletOpen(!tabletOpen);
	};

	const handleMobileOpen = () => {
		handleChange("profile");
		setMobileOpen(!mobileOpen);
	};

	useEffect(() => {
		const closeDropdown = (e) => {
			if (btnRef.current && !btnRef.current.contains(e.target)) {
				setOpen(false);
				setMobileOpen(false);
			}
			if (!tabletBtnRef.current.contains(e.target)) {
				setTabletOpen(false);
			}
		};
		document.body.addEventListener("mousedown", closeDropdown);
		return () => {
			document.body.removeEventListener("mousedown", closeDropdown);
		};
	}, []);
	const navigate = useNavigate();

	const handleChange = (value) => {
		localStorage.setItem("searchType", value);
		localStorage.setItem("messageType", "person");
		if (value == "Notifications") {
			notification.send(
				JSON.stringify({
					command: "mark_notifications_read",
				})
			);
			localStorage.setItem("notification", "");
			setNotificationDeskCount(0);
		}
	};

	// =========== for logout====================
	const handleLogout = () => {
		const data = localStorage.getItem("refreshToken");
		privatePostMethod(
			{ refress_token: data },
			"auth/logout",
			function (response) {
				if (response.data["isError"] == false) {
					localStorage.clear();
					navigate("/login");
				} else {
				}
			}
		);
	};
	// ==========================================

	// ============ for notification count===============
	const [notificationDeskCount, setNotificationDeskCount] = useState([]);
	useEffect(() => {
		webSocketConnection();
	}, []);

	var serverWs =
		process.env.REACT_APP_CHAT_NOTIFICATION_URL +
		"?header=" +
		localStorage.getItem("accessToken");
	var notification = new WebSocket(serverWs);

	const webSocketConnection = () => {
		notification.onopen = () => {
			setInterval(() => {
				if (notification.readyState == 1) {
					notification.send(
						JSON.stringify({
							command: "get_unread_general_notifications_count",
						})
					);
				}
			}, 60000);
			// notification.send(
			// 	JSON.stringify({
			// 		command: "get_unread_chat_notifications_count",
			// 	})
			// );
		};
		notification.onmessage = (message) => {
			var data = JSON.parse(message.data);
			// setNotificationDeskCount(data.count);
			localStorage.setItem("notification", data.count);
		};
		notification.onclose = () => {};
		notification.onerror = (error) => {};
	};

	// setNotificationDeskCount(localStorage.getItem("notification"));
	useEffect(() => {
		notificationCount();
	}, []);

	const notificationCount = () => {
		setInterval(() => {
			setNotificationDeskCount(localStorage.getItem("notification"));
		}, 10000);
	};

	useEffect(() => {
		const handleScroll = () => {
		  if (mobileOpen) {
			document.body.classList = "no-scroll";
		  } else {
			document.body.classList = "";
		  }
		};
	
		handleScroll();
	
		return () => {
		  document.body.classList = "";
		};
	  }, [mobileOpen]);
	// // ==================================================

	return (
		<>
			<ul className="navbarIcons">
				<li>
					<NavLink
						to={"/home"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("home")}
					>
						<i>
							<img src="../img/icons/home.png" alt="home" />
						</i>
						<p>Home</p>
					</NavLink>
				</li>

				<li>
					<NavLink
						to={"/feed"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("feed")}
					>
						<i className="filter-blue">
							<img className="text-[#458af0] filter-blue" src="../img/icons/feed.svg" alt="feed" />
						</i>
						<p>Feed</p>
					</NavLink>
				</li>

				<li>
					<NavLink
						to={"/investor"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("investor")}
					>
						<i>
							<img
								src="../img/icons/investorNew.png"
								alt="investor
"
							/>
						</i>

						<p>Investor</p>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={"/startup"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("startup")}
					>
						<i>
							<img src="../img/icons/startup.png" alt="startup" />
						</i>
						<p>Startup</p>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={"/jobs"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("jobs")}
					>
						<i>
							<img src="../img/icons/job.png" alt="jobs" />{" "}
						</i>
						<p>Jobs</p>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={"/network"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("network")}
					>
						<i>
							<img src="../img/icons/investor.png" alt="home" />
						</i>
						<p>Network</p>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={"/message"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => localStorage.setItem("messageType", "message")}
					>
						<i>
							<img src="../img/icons/message.png" alt="home" />
						</i>
						<p>Message</p>
					</NavLink>
				</li>
				<li className="relative">
					<NavLink
						to={"/notifications"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("Notifications")}
					>
						<i>
							<img src="../img/icons/notification.png" alt="startup" />
							{notificationDeskCount == 0 ? (
								""
							) : (
								<>
									<span className="absolute right-4 top-0 text-xs font-semibold text-white flex items-center justify-center px-1 rounded-full bg-red-500">
										{notificationDeskCount}
									</span>
								</>
							)}
						</i>
						<p>Notification</p>
					</NavLink>
				</li>

				<>
					<li className="profile-icon" onClick={() => handleChange("profile")}>
						{userData.length == 0 ? (
							<>
								<img
									src={Images.defaultProfile}
									alt=""
									className="header-user-img"
								/>
							</>
						) : (
							<>
								<NavLink
									to={"/profile"}
									className={(state) =>
										state.isActive == true ? "active" : ""
									}
								>
									{userData.UserImages.length === 0 ? (
										<>
											<img
												// src="../img/default_img/default_profile.png"
												src={Images.defaultProfile}
												alt=""
												className="header-user-img"
											/>
										</>
									) : (
										<>
											{userData.UserImages.map((item, index) => {
												return (
													<>
														{item.profile_image == null ? (
															<>
																<img
																	src={Images.defaultProfile}
																	alt=""
																	key={'e' + index}
																	className="header-user-img"
																/>
															</>
														) : (
															<>
																<img
																	src={item.profile_image}
																	alt=""
																	className="header-user-img"
																	key={'e' + index}
																	onError={(e) => {
																		e.target.onerror = null;
																		e.target.src = Images.defaultProfile;
																		// "../img/default_img/default_profile.png";
																	}}
																/>
															</>
														)}
													</>
												);
											})}
										</>
									)}
								</NavLink>
							</>
						)}
						<span ref={btnRef} onClick={handleOpen} className="dropdown-icon">
							<p className="flex items-center">
								Me
								<img
									src="../img/icons/downarrow.png"
									alt=""
									className="h-4 w-4 "
								/>
							</p>
							{open == true ? <DropDownMenu userData={userData} /> : ""}
						</span>
					</li>
				</>
			</ul>

			{/* ==============for Tablet and Mobile============= */}

			<ul className="navbarIcons" id="tablet-navbarIcons">
				<li className="tablet-view">
					<NavLink
						to={"/home"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("home")}
					>
						<i>
							<img src="../img/icons/home.png" alt="home" />
						</i>
						<p>Home</p>
					</NavLink>
				</li>

				<li className="tablet-view">
					<NavLink
						to={"/feed"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("feed")}
					>
						<i className="filter-blue">
							<img className="text-[#458af0] filter-blue" src="../img/icons/feed.svg" alt="feed" />
						</i>
						<p>Feed</p>
					</NavLink>
				</li>

				<li className="tablet-view">
					<NavLink
						to={"/investor"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("investor")}
					>
						<i>
							<img
								src="../img/icons/investorNew.png"
								alt="investor
"
							/>
						</i>

						<p>Investor</p>
					</NavLink>
				</li>
				<li className="tablet-view">
					<NavLink
						to={"/startup"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("startup")}
					>
						<i>
							<img src="../img/icons/startup.png" alt="startup" />
						</i>
						<p>Startup</p>
					</NavLink>
				</li>
				{/* <li className="tablet-view">
					<NavLink
						to={"/network"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("network")}
					>
						<i>
							<img src="../img/icons/investor.png" alt="home" />
						</i>
						<p>Network</p>
					</NavLink>
				</li> */}
				<li className="tablet-view">
					<NavLink
						to={"/jobs"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("jobs")}
					>
						<i>
							<img src="../img/icons/job.png" alt="jobs" />
						</i>
						<p>Jobs</p>
					</NavLink>
				</li>
				<li
					onClick={handleTabletOpen}
					// onClick={handleOpen}
					className="tablet-view 1"
				>
					{userData.length == 0 ? (
						<>
							<img
								src={Images.defaultProfile}
								// src="../img/default_img/default_profile.png"
								alt=""
								className="header-user-img"
								onClick={() => handleChange("profile")}
							/>
						</>
					) : (
						<>
							{userData.UserImages.length === 0 ? (
								<>
									<img
										src={Images.defaultProfile}
										alt=""
										className="header-user-img"
										onClick={() => handleChange("profile")}
									/>
								</>
							) : (
								<>
									{userData.UserImages.map((item, index) => {
										return (
											<>
												{item.profile_image == null ? (
													<>
														<img
															src={Images.defaultProfile}
															alt=""
															className="header-user-img"
															key={'e' + index}
															onClick={() => handleChange("profile")}
														/>
													</>
												) : (
													<>
														<img
															src={item.profile_image}
															alt=""
															key={'e' + index}
															className="header-user-img"
															onClick={() => handleChange("profile")}
															onError={(e) => {
																e.target.onerror = null;
																e.target.src = Images.defaultProfile;
															}}
														/>
													</>
												)}
											</>
										);
									})}
								</>
							)}
						</>
					)}
					<img src="../img/icons/downarrow.png" alt="" className="h-6 w-6" />
					<span ref={tabletBtnRef}>
						{tabletOpen == true ? <TabletDropDown userData={userData} /> : ""}
					</span>
				</li>
				<li className="mt-1 mobile-view">
					<NavLink
						to={"/message"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => {
							localStorage.setItem("messageType", "person");
						}}
					>
						<span className="flex">
							<i>
								<img src="../img/icons/message.png" alt="home" />
							</i>
							&nbsp;
						</span>
					</NavLink>
				</li>
			</ul>

			{/* ==============bottom mobile navbar============= */}
			<div
				className={
					mobileOpen == true
						? "overlay fixed top-0 right-0 bg-black duration-500 w-full h-[calc(100%+2rem)] opacity-30"
						: ""
				}
			></div>

			<ul className="navbarIcons !pb-7" id="mobile-navbarIcons">
				<li>
					<NavLink
						to={"/home"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("home")}
					>
						<i>
							<img src="../img/icons/home.png" alt="home" />
						</i>
						<p>Home</p>
					</NavLink>
				</li>

				<li>
					<NavLink
						to={"/feed"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("feed")}
					>
						<i className="filter-blue">
							<img className="text-[#458af0] filter-blue" src="../img/icons/feed.svg" alt="feed" />
						</i>
						<p>Feed</p>
					</NavLink>
				</li>

				<li>
					<NavLink
						to={"/investor"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("investor")}
					>
						<i>
							<img
								src="../img/icons/investorNew.png"
								alt="investor
"
							/>
						</i>

						<p>Investor</p>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={"/startup"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("startup")}
					>
						<i>
							<img src="../img/icons/startup.png" alt="startup" />
						</i>
						<p>Startup</p>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={"/jobs"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("jobs")}
					>
						<i>
							<img src="../img/icons/job.png" alt="jobs" />
						</i>
						<p>Jobs</p>
					</NavLink>
				</li>
				<li>
					<NavLink
						to={"/network"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("network")}
					>
						<i>
							<img src="../img/icons/investor.png" alt="home" />
						</i>
						<p>Network</p>
					</NavLink>
				</li>
				<li onClick={handleMobileOpen} className="">
					{userData.length == 0 ? (
						<>
							<img
								src="../img/icons/uparrow.png"
								alt=""
								className="h-3 w-4 ml-0.5"
							/>
							<img
								src={Images.defaultProfile}
								alt=""
								className="header-user-img"
								onClick={() => handleChange("profile")}
							/>
						</>
					) : (
						<>
							<img
								src="../img/icons/uparrow.png"
								alt=""
								className="h-3 w-4 ml-0.5"
							/>
							<i>
								{userData.UserImages.length === 0 ? (
									<>
										<img
											src={Images.defaultProfile}
											alt=""
											className="header-user-img"
											onClick={() => handleChange("profile")}
										/>
									</>
								) : (
									<>
										{userData.UserImages.map((item) => {
											return (
												<>
													{item.profile_image == null ? (
														<>
															<img
																src={Images.defaultProfile}
																alt=""
																className="header-user-img"
																onClick={() => handleChange("profile")}
															/>
														</>
													) : (
														<>
															<img
																src={item.profile_image}
																alt=""
																className="header-user-img"
																onError={(e) => {
																	e.target.onerror = null;
																	e.target.src = Images.defaultProfile;
																}}
															/>
														</>
													)}
												</>
											);
										})}
									</>
								)}
							</i>
						</>
					)}
				</li>

				<div
					className={
						mobileOpen == true
							? "mobile-open-dropdown fixed bottom-16 mt-1 mb-3.5 right-0 justify-between bg-white shadow-lg px-2 pt-1 pb-2 mobile-dropDown border rounded "
							: "mobile-close-dropdown fixed bottom-16 mt-1 right-0 justify-between bg-white shadow-lg px-2 pt-1 pb-2 mobile-dropDown border rounded "
					}
				>
					<div className="profile-icon flex flex-col mt-5">
						<span className="flex flex-row items-center ml-2">
							{userData.length == 0 ? (
								<>
									<img
										src={Images.defaultProfile}
										alt=""
										className="header-user-img"
									/>
								</>
							) : (
								<>
									<NavLink
										to={"/profile"}
										className={(state) =>
											state.isActive == true ? "active 1/6" : " 1/6"
										}
									>
										{userData.UserImages.length === 0 ? (
											<>
												<img
													src={Images.defaultProfile}
													alt=""
													className="header-user-img"
												/>
											</>
										) : (
											<>
												{userData.UserImages.map((item) => {
													return (
														<>
															{item.profile_image == null ? (
																<>
																	<img
																		src={Images.defaultProfile}
																		alt=""
																		className="header-user-img"
																	/>
																</>
															) : (
																<>
																	<img
																		src={item.profile_image}
																		alt=""
																		className="header-user-img"
																		onError={(e) => {
																			e.target.onerror = null;
																			e.target.src = Images.defaultProfile;
																		}}
																	/>
																</>
															)}
														</>
													);
												})}
											</>
										)}
									</NavLink>

									<span onClick={handleOpen} className="dropdown-icon w-5/6 ">
										&nbsp;
										<p className="font-semibold">
											{userData.username.length < 15 ? (
												<>{userData.username}</>
											) : (
												<>{userData.username.slice(0, 14)}...</>
											)}
										</p>
									</span>
								</>
							)}
						</span>

						<button className=" top-8 w-11/12 mt-1 border-2 rounded-full py-0.5 px-1 text-sm border-primaryColor">
							<NavLink to={"/profile"}>View Profile</NavLink>
						</button>
					</div>
					<li className="mt-3">
						<NavLink
							to={"/message"}
							className={(state) => (state.isActive == true ? "active" : "")}
						>
							<span className="flex items-center">
								<i>
									<img src="../img/icons/message.png" alt="home" />
								</i>
								&nbsp;
								<p className="ml-2">Message</p>
							</span>
						</NavLink>
					</li>
					<li className="mt-3">
						<NavLink
							to={"/notifications"}
							className={(state) => (state.isActive == true ? "active" : "")}
							onClick={() => handleChange("Notifications")}
						>
							<span className="flex items-center">
								<i>
									<img src="../img/icons/notification.png" alt="startup" />
								</i>
								&nbsp;
								<p className="ml-2 flex items-center">
									Notification
									{notificationDeskCount == 0 ? (
										""
									) : (
										<>
											<span className=" text-xs font-semibold bg-red-500 text-white rounded-full ml-4 px-1">
												{notificationDeskCount}
											</span>
										</>
									)}
								</p>
							</span>
						</NavLink>
					</li>
					<li className="mt-3">
						<Link to={"/createcompany"}>
							<span className="flex items-center">
								<i>
									<img src="../img/icons/company.png" alt="" />
								</i>
								&nbsp;
								<p className="ml-2">Company</p>
							</span>
						</Link>
					</li>
					<li className="mt-3">
						<Link to={"/createjob"}>
							<span className="flex items-center">
								<i>
									<img src="../img/icons/jobs.png" alt="" />
								</i>
								&nbsp;
								<p className="ml-2">Post Jobs</p>
							</span>
						</Link>
					</li>
					{userData.length == 0 ? (
						""
					) : (
						<>
							{userData.UserJobPosted.length == 0 ? (
								""
							) : (
								<>
									<li className="mt-3">
										<NavLink
											to={"/PostedJobList"}
											className={(state) =>
												state.isActive == true ? "active" : ""
											}
											onClick={() => handleChange("jobs")}
										>
											<span className="flex items-center">
												<i>
													<img src="../img/icons/job.png" alt="jobs" />
												</i>
												&nbsp;
												<p className="ml-2">Posted Jobs</p>
											</span>
										</NavLink>
									</li>
									<li className="mt-3">
										<NavLink
											to={"/resume-search"}
											className={(state) =>
												state.isActive == true ? "active" : ""
											}
											onClick={() => handleChange("jobs")}
										>
											<span className="flex items-center">
												<i>
													<img src="../img/icons/job.png" alt="jobs" />
												</i>
												&nbsp;
												<p className="ml-2">Resume Search</p>
											</span>
										</NavLink>
									</li>
								</>
							)}
						</>
					)}
					<li className="mt-3">
						<Link to={"/blogs"}>
							<span className="flex items-center">
								<i>
									<img src="../img/icons/blog.svg" alt="blogs" />
								</i>
								&nbsp;
								<p className="ml-2">Blogs</p>
							</span>
						</Link>
					</li>
					<li className="mt-3">
						<Link to={"/changepassword"}>
							<span className="flex items-center">
								<i>
									<img src="../img/icons/setting.png" alt="" />
								</i>
								&nbsp;
								<p className="ml-2">Settings</p>
							</span>
						</Link>
					</li>
					<li className="mt-3">
						<Link to={"/aboutus"}>
							<span className="flex items-center">
								<i>
									<img src="../img/icons/info.png" alt="" />
								</i>
								&nbsp;
								<p className="ml-2">About us</p>
							</span>
						</Link>
					</li>
					<li className="mt-3">
						<Link to={"/privacy"}>
							<span className="flex items-center">
								<i>
									<img src="../img/icons/privacy.png" alt="" />
								</i>
								&nbsp;
								<p className="ml-2">Privacy</p>
							</span>
						</Link>
					</li>
					
					<li className="mt-3" onClick={handleLogout}>
						<span className="flex items-center">
							<i>
								<img src="../img/icons/logout.png" alt="" />
							</i>
							<p className="ml-2">Logout</p>
						</span>
					</li>
				</div>
			</ul>
		</>
	);
};

export default NavbarIcons;
