import React, { useEffect } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import MessageContainer from "../MessageBox/MessageContainer";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const tokenValue = localStorage.getItem("accessToken");

 useEffect(()=>{
  if(tokenValue == undefined ){
    navigate("/login",
    {
      state: {
        from: location.pathname
      }
    }) 
  }
 })
  return tokenValue !== undefined && ( <>
      {location.pathname !== "/message" && <MessageContainer />}
      {children}
    </>
    )}
   

export default PrivateRoute;
