import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";

const TabletDropDown = ({ userData }) => {
  // =========== for logout====================
  const handleLogout = () => {
    const data = localStorage.getItem("refreshToken");
    privatePostMethod(
      { refress_token: data },
      "auth/logout",
      function (response) {
        if (response.status == 200) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
        }
      }
    );
  };
  // ==========================================
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleChange = (value) => {
    localStorage.setItem("searchType", value);
  };
  return (
    <>
      <div
        className="absolute top-full right-1 justify-between bg-white shadow-lg border px-2 pt-1 mobile-chat-dropDown tablet-view w-64 pb-2"
        // onClick={() => handleChange("profile")}
      >
        <li
          className="profile-icon flex mt-1.5 mb-8"
          onClick={() => handleChange("profile")}
        >
          <NavLink
            to={"/profile"}
            className={(state) => (state?.isActive == true ? "active" : "")}
          >
            {userData?.UserImages?.length === 0 ? (
              <>
                <img
                  src="../img/default_img/default_profile.png"
                  alt=""
                  className="header-user-img"
                />
              </>
            ) : (
              <>
                {userData?.UserImages[0].profile_image == null ? (
                  <>
                    <img
                      src="../img/default_img/default_profile.png"
                      alt=""
                      className="header-user-img"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={userData?.UserImages[0].profile_image}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../img/default_img/default_profile.png";
                      }}
                      alt=""
                      className="header-user-img"
                    />
                  </>
                )}
              </>
            )}
          </NavLink>

          <span className="dropdown-icon">
            &nbsp;{" "}
            <p className="font-semibold">
              {userData?.username?.length < 17 ? (
                <>{userData?.username}</>
              ) : (
                <>{userData?.username?.slice(0, 17)}...</>
              )}
            </p>
          </span>
          <button className="absolute top-8 w-11/12 mt-1 border-2 rounded-full py-0.5 px-1 text-sm border-primaryColor">
            <NavLink to={"/profile"}>View Profile</NavLink>
          </button>
        </li>
        <li className="mt-1.5">
          <NavLink
            to={"/network"}
            className={(state) => (state.isActive == true ? "active" : "")}
            onClick={() => handleChange("network")}
          >
            <span className="flex">
              <i>
                <img src="../img/icons/investor.png" alt="home" />
              </i>
              &nbsp;
              <p>Network</p>
            </span>
          </NavLink>
        </li>
        <li className="mt-1.5">
          <NavLink
            to={"/message"}
            className={(state) => (state.isActive == true ? "active" : "")}
          >
            <span className="flex">
              <i>
                <img src="../img/icons/message.png" alt="home" />
              </i>
              &nbsp;
              <p>Message</p>
            </span>
          </NavLink>
        </li>
        <li className="mt-1.5">
          <NavLink
            to={"/notifications"}
            className={(state) => (state.isActive == true ? "active" : "")}
            onClick={() => handleChange("Notifications")}
          >
            <span className="flex">
              <i>
                <img src="../img/icons/notification.png" alt="startup" />
              </i>
              &nbsp;
              <p>Notification</p>
            </span>
          </NavLink>
        </li>
        {/* <li className="mt-1.5">
					<NavLink
						to={"/jobs"}
						className={(state) => (state.isActive == true ? "active" : "")}
						onClick={() => handleChange("jobs")}
					>
						<span className="flex">
							<i>
								<img src="../img/icons/job.png" alt="jobs" />
							</i>
							&nbsp;
							<p>Jobs</p>
						</span>
					</NavLink>
				</li> */}

        <li className="mt-1.5">
          <Link to={"/createcompany"}>
            <span className="flex">
              <i>
                <img src="../img/icons/company.png" alt="" />
              </i>
              &nbsp;
              <p>Company</p>
            </span>
          </Link>
        </li>
        <li className="mt-1.5">
          <Link to={"/createjob"}>
            <span className="flex">
              <i>
                <img src="../img/icons/jobs.png" alt="" />
              </i>
              &nbsp;
              <p>Post Jobs</p>
            </span>
          </Link>
        </li>
        {userData?.UserJobPosted.length == 0 ? (
          ""
        ) : (
          <>
          <li className="mt-1.5">
            <Link to={"/PostedJobList"}>
              <span className="flex">
                <i>
                  <img src="../img/icons/jobs.png" alt="" />
                </i>
                &nbsp;
                <p>Posted Jobs</p>
              </span>
            </Link>
          </li>
          <li className="mt-1.5">
            <Link to={"/resume-search"}>
              <span className="flex">
                <i>
                  <img src="../img/icons/jobs.png" alt="" />
                </i>
                &nbsp;
                <p>Resume Search</p>
              </span>
            </Link>
          </li>
          </>
        )}

        <li className="mt-1.5">
          <Link to={"/blogs"}>
            <span className="flex">
              <i>
                <img src="../img/icons/blog.svg" alt="" />
              </i>
              &nbsp;
              <p>Blogs</p>
            </span>
          </Link>
        </li>
        <li className="mt-1.5">
          <Link to={"/changepassword"}>
            <span className="flex">
              <i>
                <img src="../img/icons/setting.png" alt="" />
              </i>
              &nbsp;
              <p>Settings</p>
            </span>
          </Link>
        </li>
        <li className="mt-1.5" onClick={handleLogout}>
          <span className="flex">
            <i>
              <img src="../img/icons/logout.png" alt="" />
            </i>
            <p>Logout</p>
          </span>
        </li>
      </div>
    </>
  );
};

export default TabletDropDown;
