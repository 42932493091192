import React from "react";

const OfferApplied = ({ coupon, offerModal, setOfferModal }) => {
  return (
    <>
      <div
        className={`offer-modal-container flex flex-col w-full h-full shadow-md p-8  mt absolute justify-center ${
          coupon !== false
            ? coupon === "Coupon expired"
              ? "bg-yellow-300"
              : "bg-green-300"
            : "bg-red-200"
        } `}
      >
        {coupon !== false ? (
          coupon === "Coupon expired" ? (
            <div className="warning flex flex-col items-center gap-3 ">
              <p className="text-3xl text-yellow-700 font-bold  mt-12">
                Oops...!!!!!
              </p>
              <p className="text-2xl font-medium">{coupon}</p>
            </div>
          ) : (
            <div className="success flex flex-col items-center gap-3 ">
              <p className="text-3xl text-green-700 font-bold  mt-12">
                Hurray...!!!!!
              </p>
              <p className="text-2xl font-medium">
                Coupon Applied {coupon.discount_percentage}% off
              </p>
            </div>
          )
        ) : (
          <div className="flex flex-col items-center gap-3">
            <p className="text-3xl text-red-600 font-bold  mt-12">Sorry..!!</p>
            <p className="text-2xl font-medium">Enter a valid Code</p>
          </div>
        )}

        <button
          className={`mx-28 mt-6 shadow-md rounded-lg border px-12 py-4 ${
            coupon !== false
              ? coupon=== "Coupon expired"? ( "border-yellow-200 bg-yellow-200 text-yellow-400") : ( "border-green-200 bg-green-200 text-green-400")
              : "border-red-200 bg-red-100 text-red-400"
          } hover:scale-150`}
          onClick={() => setOfferModal(!offerModal)}
        >
          {" "}
          Ok
        </button>
      </div>
    </>
  );
};

export default OfferApplied;
