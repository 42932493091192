import React, { useState } from "react";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";
import ResponseAlert from "../Alerts/ResponseAlert";
import Images from "../StaticImages/Images";

const ImageSendLayout = (props) => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [title, setTitle] = useState([]);
	const [errorMessage, setErrorMessage] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openResponse, setOpenResponse] = useState(false);
	const [errorValue, setErrorValue] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);
	const handleFileSend = () => {
		setLoading(true);
		const formData = new FormData();
		formData.append("image", selectedFile, selectedFile.name);
		formData.set("title", title);
		formData.set("room", props.chatRoomId);
		privatePostMethod(formData, "chat/image_sending/", function (response) {
			if (response.data["isError"] == false) {
				setErrorValue(response.data.msg);
				setOpenResponse(true);
				setErrorStatus(false);
				props.handleImageClose();
			} else {
				setLoading(false);
				setOpenResponse(true);
				setErrorStatus(true);
				setErrorValue(
					response.data["errors"].replace(
						/[`~!@#$%^&*()_|+\-=?;'",.<>\{\}\[\]\\\/]/gi,
						""
					)
				);
			}
		});
	};

	const handleFileSelect = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleChange = (e) => {
		setTitle(e.target.value);
	};
	// ------------------ for remove btn----------------
	const removeSelectedImage = () => {
		setSelectedFile();
		setTitle("");
	};

	const handleSendImage = () => {
		document.getElementById("sendImageSelect").click();
	};

	return (
		<>
			<ResponseAlert
				setOpenResponse={setOpenResponse}
				openResponse={openResponse}
				errorValue={errorValue}
				errorStatus={errorStatus}
			/>
			<div className="img-upload-section chat-image-upload">
				{selectedFile == null ? (
					<>
						<input
							type="text"
							onChange={handleChange}
							value={title}
							className="border bg-slate-50 !w-full !pl-2"
							placeholder="Title"
						/>
						<div className="img-upload" onClick={handleSendImage}>
							<img src={Images.modalBg} alt="" />
							<input
								type="file"
								onChange={handleFileSelect}
								className="chooseImage-btn"
								style={{ display: "none" }}
								id="sendImageSelect"
								accept=".png, .jpg, .jpeg"
							/>
							{/* <label htmlFor="text">Title</label> */}
						</div>
					</>
				) : (
					<>
						{selectedFile && (
							<div className="image-preview-section chat-image-preview">
								<span className="mt-4">
									<input
										type="text"
										value={title}
										onChange={handleChange}
										className="borderbg-slate-50 !w-full rounded-sm !pl-2"
										placeholder="Title"
									/>
								</span>
								<img
									src={URL.createObjectURL(selectedFile)}
									alt="Thumb"
									className=" image-preview"
								/>
								{errorMessage && (
									<>
										<p className="investor-error">{errorMessage}</p>
									</>
								)}
							</div>
						)}
					</>
				)}
				<div className=" flex justify-end">
					{selectedFile && (
						<button
							onClick={removeSelectedImage}
							className="image-remove-btn delete-btn !relative right-3"
						>
							Remove
						</button>
					)}
					{loading == true ? (
						<>
							<button 
							className="save-btn bg-gray-300 text-white !border-gray-300 hover:bg-gray-300 !relative"
							>
								Sending
							</button>
						</>
					) : (
						<>
							<button onClick={handleFileSend} className="save-btn !relative">
								Send
							</button>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default ImageSendLayout;
