import React from 'react'

const Loader = (props) => {
  return (
    <div className={"loading my-14 " + props.className}>
        <img className={props.imgClassName} src="../../../img/icons/loader.gif" alt="" />
    </div>
  )
}

export default Loader