import React from "react";
import privateDeleteMethod from "../../apiUrls/privateUrl/privateDeleteMethod";
import privateGetMethod from "../../apiUrls/privateUrl/privateGetMethod";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";

function Modal(props) {
	const deleteMethod = () => {
		// console.log(props)
		let data = props.idData;
		if (props.post != true) {
			privateDeleteMethod(data, props.url, function (response) {
				if (response.status == 200) {
					if (props.updateLocalStorage == true) {
						userDetails();
					}

					props.reload();
					props.setOpenModal(false);
					setTimeout(() => {
						props.closeModal();
					}, 500);
				}
			});
		} else {
			privatePostMethod(data, props.url, function (response) {
				if (response.status == 200) {
					props.setOpenModal(false);
					props.setOpen(false);
					props.reload();
					setTimeout(() => {
						props.closeModal();
					}, 500);
				}
			});
		}
	};

	const userDetails = () => {
		privateGetMethod("getProfile/userinfoData", function (response) {
			if (response.status === 200) {
				localStorage.setItem("userData", JSON.stringify(response.data.data));
			}
		});
	};

	return (
		<div className="modalBackground">
			<div className="modalContainer">
				<button className="modal-fullContainer">
					<div className="title">
						<span className="text-base font-semibold text-black">
							{props.dialogMessage}
						</span>
					</div>
					<div className="confirm-modal-body">
						<p className="text-subHeadingTextColor font-semibold">
							Deleting {props.name} is permanent and there is no way to get it
							back.
						</p>
					</div>
				</button>
				<div className="footer ">
					<button
						onClick={() => {
							props.setOpenModal(false);
						}}
						id="cancelBtn"
						className="text-black font-semibold bg-slate-300 px-4 py-2 mr-2 rounded text-base9 cursor-pointer border-0"
					>
						Cancel
					</button>
					<button
						type="button"
						onClick={deleteMethod}
						className="text-white bg-red-400 font-semibold px-4 py-2 ml-2 rounded text-base9 cursor-pointer border-0"
					>
						Confirm
					</button>
				</div>
			</div>
		</div>
	);
}

export default Modal;
