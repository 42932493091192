import React, { useEffect, useRef, useState } from "react";
import privateGetMethod from "../../apiUrls/privateUrl/privateGetMethod";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { Modal } from "@mui/material";

const ImageSlider = (props) => {
  const bucketUrl = process.env.REACT_APP_BUCKET_URL;
  const [imageData, setImageData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    imageDetails(props.image.msg_id);
  }, []);
  const imageDetails = (imageId) => {
    privateGetMethod(
      `chat/chatroom_message_image/${props.chatRoomId}/1/?id=${imageId}`,
      function (response) {
        if (response.status === 200) {
          setImageData(response.data);
          setCurrentIndex(response.data.previous.messages.length);
          setLoading(false);
        }
      }
    );
  };

  const modalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props.setSliderOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        props.setSliderOpen(false);
      }
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", (e) => {});
    };
  }, []);

  let image =
    loading == false
      ? [
          ...imageData.previous.messages.reverse(),
          ...imageData.current.messages,
          ...imageData.next.messages,
        ]
      : "";

  const [currentIndex, setCurrentIndex] = useState("");

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = () => {
    if (currentIndex < image.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <>
      {/* <div className="modal-background fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center z-10 backdrop-blur-md"> */}

      {/* <div
					className="modal-content flex relative justify-between w-full items-center"
					ref={modalRef}
				> */}
      <Modal
        open={props.sliderOpen}
        onClose={() => props.setSliderOpen(false)}
        className="modal-background fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center z-10 backdrop-blur-md"
      >
        {loading == false ? (
          <>
            <button
              onClick={() => handlePrev()}
              className={
                currentIndex == 0
                  ? "invisible"
                  : "hover:bg-gray-500 h-20 transition-all duration-75 ease-in-out absolute top-1/2 -transform-y-1/2 left-0"
              }
            >
              <img src="../img/icons/left.png" alt="" />
            </button>
            <InnerImageZoom
              src={bucketUrl + "/" + image[currentIndex].image}
              zoomSrc={bucketUrl + "/" + image[currentIndex].image}
              width={650}
              height={400}
              style={{ transform: "translate(-50%, -50%)" }}
              className="-transform-y-1/2 absolute  -transform-x-1/2 md:max-w-[650px]"
            />
            {/* <img
								src={bucketUrl + "/" + image[currentIndex].image}
								alt="Slider Image"
								className="slider-img"
							/> */}
            <button
              onClick={() => handleNext()}
              className={
                currentIndex < image.length - 1
                  ? "hover:bg-gray-500 h-20 transition-all duration-75 ease-in-out top-1/2 -transform-y-1/2 absolute right-0"
                  : "invisible"
              }
            >
              <img src="../img/icons/right.png" alt="" />
            </button>
          </>
        ) : (
          <p> </p>
        )}
      </Modal>
      {/* </div>
			</div> */}
    </>
  );
};

export default ImageSlider;
