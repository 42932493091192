import React from "react";
import { Outlet } from "react-router-dom";
import AdsImage from "../AdsImage";
import LoginNavbar from "../LoginScreens/LoginNavbar";

const PublicLayout = () => {
	return (
		<>
			{/* {pageLoader == true ? (
				<>
					<PageLoading />
				</>
			) : (
				<> */}
			<LoginNavbar joinNow signIn />
			{/* <div id="three-section-home-container"> */}
			<div id="user-profile">
				{/* <div className="left-container"></div> */}
				<div className="user-leftContainer !bg-transparent">
					{/* <div className="mid-container"> */}
					<Outlet />
				</div>
				<div className="user-rightContainer">
					{/* <div className="right-container"> */}
					<AdsImage />
				</div>
			</div>
			{/* </>
			)} */}
		</>
	);
};

export default PublicLayout;
