import React from "react";

const SubscriptionBanner = () => {
  return (
    <>
      <div className="header mt-12 text-center  bg-white p-12 pb-4" >
        <h1 className="text-xl leading-10 font-bold text-[#619bf2] ">
          Join the millions of Konnectorx members using Premium to get ahead
        </h1> 
     
      <div className="header-content text-base m-3 ">
        <p className=" text-slate-900">
          Suma and millions of other members use Premium
        </p>
        <p className=" mt-1 text-slate-500">
          Start your free 1-month trial today. Cancel anytime. We'll send you a
          reminder 7 days before your trial ends.
        </p>
      </div>
      </div>
    </>
  );
};

export default SubscriptionBanner;
