import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  BrowserRouter,
  Navigate,
  useSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Navbar from "./components/navbar/Navbar";
import Layout from "./components/Layout/Layout";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PageLoading from "./components/LoadingComponent/PageLoading";
import { UserProvider } from "./components/Context/UserContext";
import PublicLayout from "./components/Layout/PublicLayout";
import SubscriptionPage from "./components/SubscriptionPage/SubscriptionPage";
const LazyAbout = React.lazy(() => import("./components/PrivacyPage/AboutUs"));
const LandingPage = React.lazy(() =>
  import("./components/LoginScreens/LandingPage")
);
const RegistrationForm = React.lazy(() =>
  import("./components/LoginScreens/RegistrationForm")
);
const LazyPrivacy = React.lazy(() =>
  import("./components/PrivacyPage/Privacy")
);
const Submission = React.lazy(() =>
  import("./components/LoginScreens/Submission")
);
const Home = React.lazy(() => import("./components/homePage/Home"));
const Post = React.lazy(() => import("./components/homePage/Post"));
const ForgotPassword = React.lazy(() =>
  import("./components/ForgotPassword/ForgotPassword")
);
const NewPassword = React.lazy(() =>
  import("./components/ForgotPassword/NewPassword")
);
const StartUp = React.lazy(() => import("./components/startupPage/Startup"));
const Network = React.lazy(() =>
  import("./components/UserPublicPage/PublicUsers")
);
const Investor = React.lazy(() => import("./components/investorPage/Investor"));
const Jobs = React.lazy(() => import("./components/jobsListPage/Jobs"));
const Profile = React.lazy(() => import("./components/profilePage/Profile"));
const CreateCompanyForm = React.lazy(() =>
  import("./components/CompanyPage/CreateCompanyForm")
);
const CreateCompanyPage = React.lazy(() =>
  import("./components/CompanyPage/CreateCompanyPage")
);
const CompanyPage = React.lazy(() =>
  import("./components/CompanyPage/CompanyPage")
);
const TeamMemberPage = React.lazy(() =>
  import("./components/CompanyPage/TeamMemberPage")
);
const CreateJobsForm = React.lazy(() =>
  import("./components/JobsPostPage/CreateJobsForm")
);
const PostedJobList = React.lazy(() =>
  import("./components/JobsPostPage/PostedJobList")
);
const JobPostDetailsPage = React.lazy(() =>
  import("./components/JobsPostPage/JobPostDetailsPage")
);
const CreatedInvestorDetails = React.lazy(() =>
  import("./components/CreateInvestorPage/CreatedInvestorDetails")
);
const StartupDetailedPage = React.lazy(() =>
  import("./components/jobsListPage/JobDetailedPage/JobDetailed")
);
const JobDetailedPage = React.lazy(() =>
  import("./components/jobsListPage/JobDetailedPage/JobDetailed")
);
const JobDetailedPublicPage = React.lazy(() =>
  import("./components/jobsListPage/JobDetailedPage/JobDetailedPub")
);
const InvestorDetailedPage = React.lazy(() =>
  import("./components/investorPage/investorDetailedPage/InvestorDetailedPage")
);
const InvestorDetailedReview = React.lazy(() =>
  import("./components/investorPage/reviewInvestor/InvestorDetailedReview")
);
const SavedJobs = React.lazy(() =>
  import("./components/jobsListPage/SavedJobs/SavedJobs")
);
const SavedJobDetailedPage = React.lazy(() =>
  import("./components/jobsListPage/SavedJobs/SavedJobDetailedPage")
);
const AppliedJobs = React.lazy(() =>
  import("./components/jobsListPage/AppliedJobs/AppliedJobs")
);
const AppliedDetailed = React.lazy(() =>
  import(
    "./components/jobsListPage/AppliedJobs/DetailedPage/AppliedJobDetailed"
  )
);
const PublicUserDetailed = React.lazy(() =>
  import(
    "./components/UserPublicPage/UserPublicDetailedPage/UserProfileDetailedPage"
  )
);
const ConnectedPeople = React.lazy(() =>
  import("./components/ConnectionListPage/ConnectedPeoplePage/ConnectedPeople")
);
const ReceivedConnection = React.lazy(() =>
  import(
    "./components/ConnectionListPage/ReceivedConnectionPage/ReceivedConnectionRequest"
  )
);
const SendConnection = React.lazy(() =>
  import("./components/ConnectionListPage/SendConnectionPage/SendConnection")
);
const ConnectedDetailed = React.lazy(() =>
  import(
    "./components/ConnectionListPage/ConnectedPeoplePage/ConnectedDetailedPage/ConnectedDetailed"
  )
);
const SendDetailed = React.lazy(() =>
  import(
    "./components/ConnectionListPage/SendConnectionPage/SendDetailedPage/SendDetailed"
  )
);
const ReceivedDetailed = React.lazy(() =>
  import(
    "./components/ConnectionListPage/ReceivedConnectionPage/ReceivedDetailedPage/ReceivedDetailed"
  )
);
const Notifications = React.lazy(() =>
  import("./components/NotificationPage/Notification")
);
const Message = React.lazy(() =>
  import("./components/MessangingPage/MessagePage")
);
const MobileMessageScreen = React.lazy(() =>
  import("./components/MessangingPage/MobileMessageScreen")
);
const JobDetailsMobile = React.lazy(() =>
  import("./components/JobsPostPage/JobDetailsMobile")
);
const SavedStartup = React.lazy(() =>
  import("./components/startupPage/SavedStartupPage/SavedStartup")
);
const SavedStartupDetailed = React.lazy(() =>
  import(
    "./components/startupPage/SavedStartupPage/SavedStartupDetailesPage/SavedStartupDetailed"
  )
);
const SavedInvestor = React.lazy(() =>
  import("./components/investorPage/SavedInvestorPage/SavedInvestor")
);
const LoginNews = React.lazy(() =>
  import("./components/LoginScreens/LoginNews")
);
const CheckUser = React.lazy(() =>
  import("./components/CompanyPage/CheckUser")
);
const CompanyVerify = React.lazy(() =>
  import("./components/CompanyPage/CompanyVerify")
);
const CreatedCompanyHome = React.lazy(() =>
  import("./components/CompanyPage/CompanyTabs/CompanyHome")
);
const CompanyCreateSuccessPage = React.lazy(() =>
  import("./components/CompanyPage/CompanyCreateSuccessPage")
);
const MessageProfileDetailsPage = React.lazy(() =>
  import(
    "./components/MessangingPage/MeesagePersonDetails/MessageProfileDetailedPage"
  )
);
const CreatedInvestorDetailedPage = React.lazy(() =>
  import(
    "./components/CreateInvestorPage/CreatedInvestorDetailedPage/CreatedInvestorDetails"
  )
);
const CommonPublicUserDetails = React.lazy(() =>
  import("./components/CommonPublicUserDetailedPage/CommonPublicDetailedPage")
);
const CommonInvestorDetails = React.lazy(() =>
  import("./components/CommonInvestorDetailedPage/CommonInvestorDetailedPage")
);
const CommonStartupDetailedPage = React.lazy(() =>
  import("./components/CommonStartupPage/CommonStartupDetailedPage")
);
const ChangePassword = React.lazy(() =>
  import("./components/SettingPage/ChangePassword")
);
const EmailVerify = React.lazy(() =>
  import("./components/LoginScreens/EmailVerify")
);
const NotFound = React.lazy(() =>
  import("./components/NotFoundPages/NotFound")
);

const StartupPublicPage = React.lazy(() =>
  import("./components/startupPage/StartupPublicPage/StartupPublicPage")
);
const InvestorPublicPage = React.lazy(() =>
  import("./components/investorPage/InvestorPublicPage/InvestorPublicPage")
);
const JobPublicPage = React.lazy(() =>
  import("./components/jobsListPage/PublicJobListPage/PublicJobListPage")
);
const ContactUs = React.lazy(() =>
  import("./components/PrivacyPage/ContactUs")
);
const CompanyReviewDetailed = React.lazy(() =>
  import("./components/ReviewsPage/CompanyReviewDetailed")
);
const CompanyReview = React.lazy(() =>
  import("./components/ReviewsPage/CompanyReview")
);
const Blog = React.lazy(() => import("./components/BlogPage/Blog"));
const BlogPage = React.lazy(() => import("./components/BlogPage/BlogPage"));

const Feed = React.lazy(() => import("./components/feedPage/Feed"));

const SingleFeedPage = React.lazy(() =>
  import("./components/feedPage/Post/SinglePostPage")
);

const ResumeSearch = React.lazy(() =>
  import("./components/ResumeSearch/ResumeSearch")
);

function RouteComponent() {
  let tokenValue = localStorage.getItem("accessToken");
  const paramValue = useLocation().pathname.replace(/[0-9{/}]/gi, "");

  window.onbeforeunload = function () {
    localStorage.removeItem("blogData");
    localStorage.removeItem("blogPostOffset");
  };

  return (
    <>
      <UserProvider>
        {tokenValue == null ? (
          ""
        ) : paramValue != "MobileMessageScreen" ? (
          <Navbar />
        ) : (
          ""
        )}

        <Routes>
          {/* ===========public Routes=================== */}
          <Route
            path="/login"
            element={
              !tokenValue ? (
                <React.Suspense fallback={<PageLoading />}>
                  <LandingPage />
                </React.Suspense>
              ) : (
                <Navigate replace to="/home" />
              )
            }
          />
          <Route
            path="/registration"
            element={
              !tokenValue ? (
                <React.Suspense fallback={<PageLoading />}>
                  <RegistrationForm />
                </React.Suspense>
              ) : (
                <Navigate replace to="/home" />
              )
            }
          />
          <Route
            path="/news"
            element={
              !tokenValue ? (
                <React.Suspense fallback={<PageLoading />}>
                  <LoginNews />
                </React.Suspense>
              ) : (
                <Navigate replace to="/home" />
              )
            }
          />
          <Route
            path="/submission"
            element={
              !tokenValue ? (
                <React.Suspense fallback={<PageLoading />}>
                  <Submission />
                </React.Suspense>
              ) : (
                <Navigate replace to="/home" />
              )
            }
          />
          <Route
            path="/forgotPassword"
            element={
              !tokenValue ? (
                <React.Suspense fallback={<PageLoading />}>
                  <ForgotPassword />
                </React.Suspense>
              ) : (
                <Navigate replace to="/home" />
              )
            }
          />
          <Route
            path="/setpassword/:token"
            element={
              !tokenValue ? (
                <React.Suspense fallback={<PageLoading />}>
                  <NewPassword />
                </React.Suspense>
              ) : (
                <Navigate replace to="/home" />
              )
            }
          />
          <Route
            path="/emailVerify/:token"
            element={
              !tokenValue ? (
                <React.Suspense fallback={<PageLoading />}>
                  <EmailVerify />
                </React.Suspense>
              ) : (
                <Navigate replace to="/home" />
              )
            }
          />

          <Route
            path="/privacy"
            element={
              <React.Suspense fallback={<PageLoading />}>
                <LazyPrivacy />
              </React.Suspense>
            }
          />
          <Route
            path="/aboutus"
            element={
              <React.Suspense fallback={<PageLoading />}>
                <LazyAbout />
              </React.Suspense>
            }
          />

          <Route
            path="/post"
            element={
              <PrivateRoute>
                <React.Suspense fallback={<PageLoading />}>
                  <Post />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="/contactus"
            element={
              <React.Suspense fallback={<PageLoading />}>
                <ContactUs />
              </React.Suspense>
            }
          />

          <Route element={<PublicLayout />}>
            <Route
              path="/publicstartup"
              element={
                !tokenValue ? (
                  <React.Suspense fallback="">
                    <StartupPublicPage />
                  </React.Suspense>
                ) : (
                  <Navigate replace to="/startup" />
                )
              }
            />

            <Route
              path="/publicinvestor"
              element={
                !tokenValue ? (
                  <React.Suspense fallback="">
                    <InvestorPublicPage />
                  </React.Suspense>
                ) : (
                  <Navigate replace to="/investor" />
                )
              }
            />
            <Route
              path="/publicjobs"
              element={
                !tokenValue ? (
                  <React.Suspense fallback="">
                    <JobPublicPage />
                  </React.Suspense>
                ) : (
                  <Navigate replace to="/jobs" />
                )
              }
            />
            {/* <Route
							path="/jobdetails/:uuid"
							// path="/publicjobdetails/:uuid"
							element={
								<React.Suspense fallback="">
									{tokenValue ? <JobDetailedPage /> : <JobDetailedPublicPage />}
								</React.Suspense>
							}
						/>
						<Route
							path="/InvestorDetailedPage/:uuid"
							// path="/publicinvestorDetails/:uuid"
							element={
								<React.Suspense fallback="">
									<InvestorDetailedPage />
								</React.Suspense>
							}
						/>
						<Route
							path="/startupDetails/:uuid"
							// path="/publicstartupDetails/:uuid"
							element={
								<React.Suspense fallback="">
									<CommonStartupDetailedPage />
								</React.Suspense>
							}
						/> */}
          </Route>
          <Route element={tokenValue ? <Layout /> : <PublicLayout />}>
            <Route
              path="/blogs"
              element={
                <React.Suspense fallback="">
                  <Blog />
                </React.Suspense>
              }
            />
            <Route
              path="/blogs/:id"
              element={
                <React.Suspense fallback="">
                  <BlogPage />
                </React.Suspense>
              }
            />

            <Route
              path="/jobdetails/:uuid"
              element={
                <React.Suspense fallback="">
                  <JobDetailedPublicPage />
                </React.Suspense>
              }
            />
            <Route
              path="/investorDetailedPage/:uuid"
              element={
                <React.Suspense fallback="">
                  <InvestorDetailedPage />
                </React.Suspense>
              }
            />
            <Route
              path="/startupDetails/:uuid"
              element={
                <React.Suspense fallback="">
                  <CommonStartupDetailedPage />
                </React.Suspense>
              }
            />
          </Route>
          {/* =========================================== */}

          {/* =============Layout====================== */}
          <Route element={<Layout />}>
            <Route
              exact
              path="/"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <Home />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              index
              path="/home"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <Home />
                  </React.Suspense>
                </PrivateRoute>
              }
            />

            <Route
              path="/network"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <Network />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/investor"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <Investor />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/investorReview/:uuid"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <InvestorDetailedReview />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/startUp"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <StartUp />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <Notifications />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/jobs"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <Jobs />
                  </React.Suspense>
                </PrivateRoute>
              }
            />

            {/* ============= ============================== */}
            {/* <PrivateRoute> */}
            {/* <Route
							path="/investorDetailedPage/:uuid"
							element={
								<React.Suspense fallback="">
									<InvestorDetailedPage />
								</React.Suspense>
							}
						/> */}
            {/* </PrivateRoute> */}
            {/* <PrivateRoute> */}
            {/* <Route
							path="/startupDetails/:uuid"
							element={
								<React.Suspense fallback="">
									<CommonStartupDetailedPage />
								</React.Suspense>
							}
						/> */}
            {/* </PrivateRoute> */}
            {/* <PrivateRoute> */}
            {/* <Route
							path="/jobdetails/:uuid"
							element={
								<React.Suspense fallback="">
									<JobDetailedPage />
								</React.Suspense>
							}
						/> */}
            {/* </PrivateRoute> */}
            {/* =============================================== */}

            <Route
              path="/startupreview/:uuid"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <CompanyReviewDetailed />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/savedJobs"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <SavedJobs />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/appliedjobs"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <AppliedJobs />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/savedjobdetails"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <SavedJobDetailedPage />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/appliedjobdetails"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <AppliedDetailed />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/savedstartup"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <SavedStartup />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/savedstartupdetailed"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <SavedStartupDetailed />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/savedinvestor"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <SavedInvestor />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/myconnection"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <ConnectedPeople />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/sendconnection"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <SendConnection />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/receivedconnection"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <ReceivedConnection />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/connecteddetailed"
              element={
                <PrivateRoute>
                  <React.Suspense fallback="">
                    <ConnectedDetailed />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="feed"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<PageLoading />}>
                    <Feed />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/feed/:uuid"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<PageLoading />}>
                    <SingleFeedPage />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/companyverify/:token"
            element={
              <React.Suspense fallback="">
                <CompanyVerify />
              </React.Suspense>
            }
          />
          {/* ============================================ */}
          <Route
            path="/message"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <Message />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="/changepassword"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <ChangePassword />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <Profile />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="/createCompanyform"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CreateCompanyForm />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/createcompany"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CreateCompanyPage />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/companyPage"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CompanyPage />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="/companycreated"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CompanyCreateSuccessPage />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/teamMemberPage"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <TeamMemberPage />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/createjob"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CreateJobsForm />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/postedJobList"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <PostedJobList />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/jobPostDetailsPage"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <JobPostDetailsPage />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/createdInvestorDetails"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CreatedInvestorDetails />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/startupDetailedPage"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <StartupDetailedPage />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="/createdInvestorDetailedPage"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CreatedInvestorDetailedPage />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="/publicUserDetailed"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <PublicUserDetailed />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="/receiveddetailed"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <ReceivedDetailed />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/senddetailed"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <SendDetailed />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="/jobdetailsmobile/:uuid"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <JobDetailsMobile />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="/checkuser"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CheckUser />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/company"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CreatedCompanyHome />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/userDetails"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <MessageProfileDetailsPage />
                </React.Suspense>
              </PrivateRoute>
            }
          />

          <Route exact path="/linkedin" element={<LinkedInCallback />} />

          <Route
            path="/mobileMessageScreen/:userId"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <MobileMessageScreen />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/userDetails/:uuid"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CommonPublicUserDetails />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/investorDetails/:uuid"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <CommonInvestorDetails />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription"
            element={
              <React.Suspense fallback="">
                <SubscriptionPage />
              </React.Suspense>
            }
          />
          <Route
            path="/resume-search"
            element={
              <PrivateRoute>
                <React.Suspense fallback="">
                  <ResumeSearch />
                </React.Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <React.Suspense fallback="">
                <NotFound />
              </React.Suspense>
            }
          />
        </Routes>
      </UserProvider>
    </>
  );
}

export default RouteComponent;
