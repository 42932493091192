import React from "react";
import Images from "./StaticImages/Images";

const AdsImage = () => {
	return (
		<>
			<a href="https://koderbox.com/contactus/" target="_blank">
				<img
					src={Images.adThree}
					alt=""
					className=" shadow-md shadow-slate-400 "
				/>
			</a>
			<img
				src={Images.adTwo}
				alt=""
				className="mt-4 shadow-md shadow-slate-400"
			/>
			<img
				src={Images.adOne}
				alt=""
				className="mt-4 shadow-md shadow-slate-400"
			/>
		</>
	);
};

export default AdsImage;
