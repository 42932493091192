const Images = {
	landingGroupImage: process.env.REACT_APP_BUCKET_URL + "/staticImages/gd.webp",
	findJob: process.env.REACT_APP_BUCKET_URL + "/staticImages/job.webp",
	postJob: process.env.REACT_APP_BUCKET_URL + "/staticImages/Postjob.webp",
	adOne: process.env.REACT_APP_BUCKET_URL + "/staticImages/JLPT_N4_and_N5.webp",
	adTwo:
		process.env.REACT_APP_BUCKET_URL +
		"/staticImages/Japanese_Crash_Course.webp",
	adThree:
		process.env.REACT_APP_BUCKET_URL + "/staticImages/Koderbox_services.webp",
	defaultBg: process.env.REACT_APP_BUCKET_URL + "/staticImages/default_bg.jpg",
	defaultProfile:
		process.env.REACT_APP_BUCKET_URL + "/staticImages/default_profile.png",
	companyCreate:
		process.env.REACT_APP_BUCKET_URL + "/staticImages/companyCreate.png",
	defaultCompanyLogo:
		process.env.REACT_APP_BUCKET_URL + "/staticImages/default_logo.png",
	konnectorxLogo:
		process.env.REACT_APP_BUCKET_URL + "/staticImages/konnectorxLogo.webp",
	konnectorxLogoMobile:
		process.env.REACT_APP_BUCKET_URL + "/staticImages/konnectorxMob.webp",
	modalBg: process.env.REACT_APP_BUCKET_URL + "/staticImages/bg.webp",
	changePassword:
		process.env.REACT_APP_BUCKET_URL + "/staticImages/changepassword.webp",
	postJob: process.env.REACT_APP_BUCKET_URL + "/staticImages/Postjob.webp",
};

export default Images;
