import React, { useEffect, useRef, useState } from "react";
import privateGetMethod from "../../apiUrls/privateUrl/privateGetMethod";
import ImageUrl from "../../ImageUrl";
import { AiOutlineFilePdf } from "react-icons/ai";
import privatePostMethod from "../../apiUrls/privateUrl/privatePostMethod";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment";
import ReadMoreLess from "../ReadMoreLess";
import { Parser } from "html-to-react";
import Images from "../StaticImages/Images";
import ConfirmModal from "../Modal/ConfirmModal";
import NoDataPage from "../NotFoundPages/NoDataPage";
import ImageSliderModal from "../Modal/ChatModal/ImageSliderModal";
import ImageSlider from "./ImageSlider";
import { Modal } from "@mui/material";
import FileView from "./FileView";

const UserMessage = (props) => {
  const messageValue = props.sendMessageData;
  const chatRoomId = props.chatRoomId;
  const user = props.user;
  const allMessages = props.allMessages;
  const bucketUrl = process.env.REACT_APP_BUCKET_URL;
  const messagesEndRef = props.messagesEndRef;
  const regex = /\b(?:https?:\/\/|www\.)[^\s<]*/i;

  const extractedURL = (match) =>
    match
      ? match[0].startsWith("www")
        ? `https://${match[0]}`
        : match[0]
      : null;

  // ====================for checking date format=========================
  const isValid = (d) => moment(d, "MM/DD/YYYY", true).isValid();
  // =====================================================================

  // ================for old message =====================================
  const [page, setPage] = useState(1);
  const handleScroll = (e) => {
    let element = e.target;
    if (element.scrollTop == 0) {
      // userMessages();
    }
  };

  // ======================================================================

  // ========================For Messages==================================
  // useEffect(() => {
  // 	if (indexValue === 0) {
  // 		firstReload();
  // 	}
  // }, []);

  // let firstReload = () => {
  // 	privateGetMethod(
  // 		"chat/chatroom_message/" + chatRoomId + "/" + page + "/",
  // 		function (response) {
  // 			if (response.status === 200) {
  // 				setAllMessages((data) => data.concat(response.data.data.messages));
  // 				if (page === 1) {
  // 					scrollToBottom();
  // 				}
  // 				setPage(page + 1);
  // 			} else {
  // 			}
  // 		}
  // 	);
  // };

  // useEffect(() => {
  // 	if (indexValue === 0) {
  // 		userMessages();
  // 	}
  // }, [chatRoomId]);

  // const [allMessages, setAllMessages] = useState([]);
  // const [messageCount, setMessageCount] = useState(0);

  // let userMessages = () => {
  // 	privateGetMethod(
  // 		"chat/chatroom_message/" + chatRoomId + "/" + page + "/",
  // 		function (response) {
  // 			if (response.status === 200) {
  // 				setAllMessages((data) => data.concat(response.data.data.messages));
  // 				// setAllMessages(response.data.data.messages);
  // 				if (page === 1) {
  // 					scrollToBottom();
  // 				}
  // 				// setPage(page + 1);
  // 			} else {
  // 			}
  // 		}
  // 	);
  // };

  // ======================================================================

  // ===============FOR SCROLL ALWAYS SHOW ON BOTTOM=======================
  // const messagesEndRef = useRef(null);

  // const scrollToBottom = () => {
  // 	messagesEndRef.current?.scrollIntoView();
  // 	// messagesEndRef.current?.scrollToEnd();
  // };

  // // // useEffect(() => {}, [messageCount]);

  // useEffect(() => {
  // 	scrollToBottom();
  // }, []);
  // useEffect(() => {
  // 	messagesEndRef.current?.scrollIntoView();
  // }, [props.clickedUser]);

  // =================================================================

  // ==============for all messages after delete======================

  // let allUserMessages = (chatRoomId) => {
  // 	privateGetMethod(
  // 		"chat/chatroom_message/" + chatRoomId + "/" + 1 + "/",
  // 		function (response) {
  // 			if (response.status === 200) {
  // 				props.setAllMessages((data) =>
  // 					data.concat(response.data.data.messages)
  // 				);
  // 				// if (page === 1) {
  // 				// 	scrollToBottom();
  // 				// }
  // 				// setPage(page + 1);
  // 			} else {
  // 			}
  // 		}
  // 	);
  // };
  // =================================================================

  // ================Delete Message===================================
  const [messageId, setMessageId] = useState("");

  const handleDeleteMessage = (messageId) => {
    setModalOpen(true);
    // let data = {
    // 	room: chatRoomId,
    // 	message_id: messageId,
    // };

    // privatePostMethod(data, "chat/delete_message/", function (response) {
    // 	if (response.status === 200) {
    // 		setOpen(false);
    // 	} else {
    // 	}
    // });
  };
  // =================================================================

  // ===============for delete option================================
  const [open, setOpen] = useState(false);
  const handleOpen = (id) => {
    setMessageId(id);
    setOpen(!open);
  };

  // ================================================================

  const [modalOpen, setModalOpen] = useState(false);

  // messageValue.map((item) => {
  // });

  // ================== for image slider===============================

  const [openImage, setOpenImage] = React.useState(false);
  const [scrollImage, setScrollImage] = React.useState("paper");
  const [imageValue, setImageValue] = useState("");
  const handleClickOpenImage = (scrollImageType, image) => () => {
    setImageValue(image);
    setOpenImage(true);
    setScrollImage(scrollImageType);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const [sliderOpen, setSliderOpen] = useState(false);

  const handleSliderOpen = (image) => {
    setImageValue(image);
    setSliderOpen(true);
  };

  // ================================================================

  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const handleClickOpenFile = (url) => {
    setOpenFileModal(true);
    setFileUrl(url);
  };

  let _dateObj = new Date("2023-01-30 11:37:58.831415+00:00");
  // let _sendingTime = moment(_dateObj).format("H:mm");
  // let _chatDate = moment(_dateObj).format("Y-MM-D");

  return (
    <>
      <div>
        {allMessages == undefined ? (
          <NoDataPage
            img="../img/icons/message.png"
            description="No Messages"
          />
        ) : (
          <>
            <ul className="user-messages">
              {[...allMessages].reverse().map((item, index) => {
                if (item != "None") {
                  return (
                    <>
                      {item.is_deleted === "True" ? (
                        ""
                      ) : (
                        <>
                          <div className="my-2">
                            <li
                              className={
                                user.username === item.username
                                  ? "user-side flex mt-3 mb-1"
                                  : "receiver-side flex mt-3 mb-1"
                              }
                              key={index}
                            >
                              {item.profile_image === "Default" ? (
                                <>
                                  <img
                                    src={Images.defaultProfile}
                                    alt=""
                                    className={
                                      user.username === item.username
                                        ? "none usermessage-image"
                                        : "receiver-left-image usermessage-image"
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={item.profile_image}
                                    alt=""
                                    className={
                                      user.username === item.username
                                        ? "none usermessage-image"
                                        : "receiver-left-image usermessage-image"
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = Images.defaultProfile;
                                    }}
                                  />
                                </>
                              )}

                              <>
                                {item.msg_id == messageId ? (
                                  <>
                                    {open == true ? (
                                      <>
                                        <span className="relative flex items-center">
                                          <p
                                            onClick={() =>
                                              handleDeleteMessage(item.msg_id)
                                            }
                                            className={
                                              user.username === item.username
                                                ? "userside-message-options message absolute right-0 top-2/4 px-6 py-2 rounded text-subHeadingTextColor bg-white shadow-md border"
                                                : "receiverside-message-options message"
                                            }
                                          >
                                            <span className="font-semibold text-base">
                                              delete
                                            </span>
                                          </p>
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                <span
                                  onClick={() => handleOpen(item.msg_id)}
                                  className={
                                    user.username === item.username
                                      ? "userside-message-options message flex items-center pr-1 "
                                      : "receiverside-message-options message "
                                  }
                                >
                                  <BsThreeDots />
                                </span>

                                {props.deleteMessage.includes(item.msg_id) ? (
                                  <>
                                    <p className="bg-slate-200 flex items-center px-1 rounded-md font-semibold text-subHeadingTextColor">
                                      This message has been deleted
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p
                                      className={
                                        user.username === item.username
                                          ? "userside-message message items-center !text-white relative"
                                          : "receiverside-message message items-center relative"
                                      }
                                      id="user_side-message"
                                      key={item.msg_id}
                                    >
                                      {item.message === "" ? (
                                        <>
                                          {item.file == "" ? (
                                            <>
                                              {item.image == "" ? (
                                                ""
                                              ) : (
                                                <>
                                                  {item.title === "None" ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      <p>{item.title}</p>
                                                    </>
                                                  )}
                                                  {/* <a
																										href={
																											bucketUrl +
																											"/" +
																											item.image
																										}
																										target="_blank"
																									> */}
                                                  <img
                                                    src={
                                                      bucketUrl +
                                                      "/" +
                                                      item.image
                                                    }
                                                    alt=""
                                                    className="message-image"
                                                    onClick={() =>
                                                      handleSliderOpen(item)
                                                    }
                                                    // onClick={handleClickOpenImage(
                                                    // 	"paper",
                                                    // 	item
                                                    // )}
                                                  />
                                                  {/* </a> */}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {item.title === "None" ? (
                                                ""
                                              ) : (
                                                <>
                                                  <p className="font-semibold">
                                                    {item.title}
                                                  </p>
                                                </>
                                              )}
                                              <div
                                                onClick={() =>
                                                  handleClickOpenFile(
                                                    bucketUrl + "/" + item.file
                                                  )
                                                }
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "user-media-title file-flex pdf-flex"
                                                    : "receiver-media-title file-flex pdf-fle"
                                                }
                                              >
                                                {/* <img src="./img/icons/File.png" alt="" /> */}
                                                <AiOutlineFilePdf className="message-pdf" />
                                                {/* <p className="font-semibold"> */}
                                                {item.file
                                                  .split("/")[1]
                                                  .slice(0, 10)}
                                                ...
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.message.match(regex) !==
                                          null ? (
                                            <a
                                              className={` ${
                                                user.username === item.username
                                                  ? "userside-message message"
                                                  : "receiverside-message message"
                                              } hover:underline hover:text-blue-700`}
                                              href={extractedURL(
                                                item.message.match(regex)
                                              )}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {Parser().parse(item.message)}
                                            </a>
                                          ) : item.message.length < 700 ? (
                                            <p
                                              className={`${
                                                user.username === item.username
                                                  ? "userside-message message"
                                                  : "receiverside-message message"
                                              }`}
                                            >
                                              {Parser().parse(item.message)}
                                            </p>
                                          ) : (
                                            <ReadMoreLess limit={700} jsxFormat>
                                              {item.message}
                                            </ReadMoreLess>
                                          )}
                                        </>
                                      )}
                                    </p>
                                  </>
                                )}
                              </>

                              {item.profile_image === "Default" ? (
                                <>
                                  <img
                                    src={Images.defaultProfile}
                                    alt=""
                                    className={
                                      user.username === item.username
                                        ? "usermessage-image"
                                        : "none"
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={item.profile_image}
                                    alt=""
                                    className={
                                      user.username === item.username
                                        ? "usermessage-image"
                                        : "none"
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = Images.defaultProfile;
                                    }}
                                  />
                                </>
                              )}
                            </li>
                            <li
                              className={
                                user.username === item.username
                                  ? "user-side-time flex justify-end text-xs mr-16 text-subHeadingTextColor"
                                  : "receiver-side-time  text-xs ml-16 py-px text-subHeadingTextColor"
                              }
                            >
                              {isValid(item.natural_timestamp) == true ? (
                                <>
                                  {moment(
                                    "" + new Date(item.natural_timestamp)
                                  ).format("lll")}
                                </>
                              ) : (
                                <>
                                  {moment(
                                    "" + new Date(item.natural_timestamp)
                                  ).format("lll")}
                                </>
                              )}
                            </li>
                          </div>
                          {/* <div ref={messagesEndRef}></div> */}
                        </>
                      )}
                    </>
                  );
                }
              })}

              <>
                {messageValue.map((item, index) => {
                  return (
                    <>
                      {props.deleteMessage.includes("" + item.msg_id) ? (
                        <>
                          <li
                            className={
                              user.username === item.username
                                ? "user-side"
                                : "receiver-side"
                            }
                          >
                            {item.profile_image === "Default" ? (
                              <>
                                <img
                                  src={Images.defaultProfile}
                                  alt=""
                                  className={
                                    user.username === item.username
                                      ? "none usermessage-image"
                                      : "receiver-left-image usermessage-image"
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.profile_image}
                                  alt=""
                                  className={
                                    user.username === item.username
                                      ? "none usermessage-image"
                                      : "receiver-left-image usermessage-image"
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = Images.defaultProfile;
                                  }}
                                />
                              </>
                            )}
                            <p className="bg-slate-200 flex items-end px-1 py-1 rounded-md font-semibold text-subHeadingTextColor ">
                              This message has been deleted
                            </p>
                            {item.profile_image === "Default" ? (
                              <>
                                <img
                                  src={Images.defaultProfile}
                                  alt=""
                                  className={
                                    user.username === item.username
                                      ? "  usermessage-image"
                                      : "none"
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.profile_image}
                                  alt=""
                                  className={
                                    user.username === item.username
                                      ? "usermessage-image"
                                      : "none"
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = Images.defaultProfile;
                                  }}
                                />
                              </>
                            )}
                          </li>
                        </>
                      ) : (
                        <>
                          {item.msg_status == 0 ? (
                            <>
                              <>
                                {item === undefined ? (
                                  ""
                                ) : (
                                  <>
                                    <>
                                      <div className="my-2">
                                        <li
                                          className={
                                            user.username === item.username
                                              ? "user-side"
                                              : "receiver-side"
                                          }
                                          key={index}
                                        >
                                          {item.msg_id == messageId ? (
                                            <>
                                              {open == true ? (
                                                <>
                                                  <span className="relative flex items-center">
                                                    <p
                                                      onClick={() =>
                                                        handleDeleteMessage(
                                                          item.msg_id
                                                        )
                                                      }
                                                      className={
                                                        user.username ===
                                                        item.username
                                                          ? "userside-message-options message absolute right-0 top-2/4 px-6 py-2 rounded text-subHeadingTextColor bg-white shadow-md border"
                                                          : "receiverside-message-options message"
                                                      }
                                                    >
                                                      <span className="font-semibold text-base">
                                                        delete
                                                      </span>
                                                    </p>
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          <span
                                            onClick={() =>
                                              handleOpen(item.msg_id)
                                            }
                                            className={
                                              user.username === item.username
                                                ? "userside-message-options message flex items-center pr-1 "
                                                : "receiverside-message-options message "
                                            }
                                          >
                                            <BsThreeDots />
                                          </span>
                                          {item.profile_image === "Default" ? (
                                            <>
                                              <img
                                                src={Images.defaultProfile}
                                                alt=""
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "none usermessage-image"
                                                    : "receiver-left-image usermessage-image"
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={item.profile_image}
                                                alt=""
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "none usermessage-image"
                                                    : "receiver-left-image usermessage-image"
                                                }
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src =
                                                    Images.defaultProfile;
                                                }}
                                              />
                                            </>
                                          )}
                                          <>
                                            <>
                                              {/* <p
																								className={
																									user.username ===
																									item.username
																										? "userside-message message "
																										: "receiverside-message message"
																								}
																							> */}
                                              {/* {props.messageLoader == true  ? (
																									"dfdfdfdfd"
																								) : ( */}
                                              <>
                                                {item.message == undefined ? (
                                                  <>
                                                    {item.image === null ? (
                                                      <>
                                                        <p
                                                          className={
                                                            user.username ===
                                                            item.username
                                                              ? "userside-message message 1111"
                                                              : "receiverside-message message"
                                                          }
                                                        >
                                                          {item.title ===
                                                          undefined ? (
                                                            ""
                                                          ) : (
                                                            <>
                                                              <p>
                                                                {item.title}
                                                              </p>
                                                            </>
                                                          )}
                                                          {item.file ===
                                                          null ? (
                                                            ""
                                                          ) : (
                                                            <>
                                                              <div
                                                                onClick={() =>
                                                                  handleClickOpenFile(
                                                                    bucketUrl +
                                                                      "/" +
                                                                      item.file
                                                                  )
                                                                }
                                                                className={
                                                                  user.username ===
                                                                  item.username
                                                                    ? "user-media-title file-flex"
                                                                    : "receiver-media-title file-flex"
                                                                }
                                                                target="_blank"
                                                              >
                                                                <AiOutlineFilePdf className="message-pdf" />
                                                                <p>
                                                                  {item.file
                                                                    .split(
                                                                      "/"
                                                                    )[1]
                                                                    .slice(
                                                                      0,
                                                                      10
                                                                    )}
                                                                </p>
                                                              </div>
                                                            </>
                                                          )}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <p
                                                          className={
                                                            user.username ===
                                                            item.username
                                                              ? "userside-message message 2222222"
                                                              : "receiverside-message message"
                                                          }
                                                        >
                                                          {/* {item.title ===
																														undefined ? (
																															""
																														) : (
																															<>
																																<p>
																																	{item.title}
																																</p>
																															</>
																														)} */}
                                                          {/* <a
																														href={
																															bucketUrl +
																															"/" +
																															item.image
																														}
																														target="_blank"
																													> */}
                                                          <img
                                                            src={
                                                              bucketUrl +
                                                              "/" +
                                                              item.image
                                                            }
                                                            alt=""
                                                            className="message-image"
                                                            // onClick={() =>
                                                            // 	handleClickOpenImage(
                                                            // 		"paper"
                                                            // 	)
                                                            // }
                                                            onClick={() =>
                                                              handleSliderOpen(
                                                                item
                                                              )
                                                            }
                                                          />
                                                          {/* </a> */}
                                                        </p>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {item.message.match(
                                                      regex
                                                    ) !== null ? (
                                                      <a
                                                        className={`${
                                                          user.username ===
                                                          item.username
                                                            ? "userside-message message"
                                                            : "receiverside-message message"
                                                        }
                                                    hover:underline hover:text-blue-700`}
                                                        href={extractedURL(
                                                          item.message.match(
                                                            regex
                                                          )
                                                        )}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {Parser().parse(
                                                          item.message
                                                        )}
                                                      </a>
                                                    ) : item.message.length <
                                                      800 ? (
                                                      <p
                                                        className={`${
                                                          user.username ===
                                                          item.username
                                                            ? "userside-message message"
                                                            : "receiverside-message message"
                                                        }`}
                                                      >
                                                        {Parser().parse(
                                                          item.message
                                                        )}
                                                      </p>
                                                    ) : (
                                                      <ReadMoreLess
                                                        limit={1000}
                                                        jsxFormat
                                                      >
                                                        {item.message}
                                                      </ReadMoreLess>
                                                    )}
                                                    {/* {item.message.length <
                                                    700 ? (
                                                      <>
                                                        <p
                                                          className={
                                                            user.username ===
                                                            item.username
                                                              ? "userside-message message"
                                                              : "receiverside-message message"
                                                          }
                                                        >
                                                          {Parser().parse(
                                                            item.message
                                                          )}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p
                                                        className={
                                                          user.username ===
                                                          item.username
                                                            ? "userside-message message"
                                                            : "receiverside-message message"
                                                        }
                                                      >
                                                        <ReadMoreLess
                                                          limit={700}
                                                          jsxFormat
                                                          childPresent
                                                        >
                                                          <span>
                                                            {item.message}
                                                          </span>
                                                        </ReadMoreLess>
                                                      </p>
                                                    )} */}
                                                  </>
                                                )}
                                              </>
                                              {/* )} */}
                                              {/* </p> */}
                                            </>
                                          </>
                                          {item.profile_image === "Default" ? (
                                            <>
                                              <img
                                                src={Images.defaultProfile}
                                                alt=""
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "usermessage-image"
                                                    : "none"
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={item.profile_image}
                                                alt=""
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "usermessage-image"
                                                    : "none"
                                                }
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src =
                                                    Images.defaultProfile;
                                                }}
                                              />
                                            </>
                                          )}
                                        </li>
                                        <li
                                          className={
                                            user.username === item.username
                                              ? "user-side-time flex justify-end text-xs mr-16 text-subHeadingTextColor"
                                              : "receiver-side-time  text-xs ml-16 py-px text-subHeadingTextColor"
                                          }
                                        >
                                          {isValid(item.natural_timestamp) ==
                                          true ? (
                                            <>
                                              {moment(
                                                "" +
                                                  new Date(
                                                    item.natural_timestamp
                                                  )
                                              ).format("lll")}
                                            </>
                                          ) : (
                                            <>
                                              {moment(
                                                "" +
                                                  new Date(
                                                    item.natural_timestamp
                                                  )
                                              ).format("lll")}
                                            </>
                                          )}
                                        </li>
                                        {/* <div ref={messagesEndRef} /> */}
                                      </div>
                                    </>
                                  </>
                                )}
                              </>
                            </>
                          ) : (
                            <>
                              {props.clickedUser == item.user_id ? (
                                <>
                                  {item === undefined ? (
                                    ""
                                  ) : (
                                    <>
                                      <>
                                        <div className="my-2">
                                          <li
                                            className={
                                              user.username === item.username
                                                ? "user-side"
                                                : "receiver-side"
                                            }
                                            key={index}
                                          >
                                            {item.profile_image ===
                                            "Default" ? (
                                              <>
                                                <img
                                                  src={Images.defaultProfile}
                                                  alt=""
                                                  className={
                                                    user.username ===
                                                    item.username
                                                      ? "none usermessage-image"
                                                      : "receiver-left-image usermessage-image"
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={item.profile_image}
                                                  alt=""
                                                  className={
                                                    user.username ===
                                                    item.username
                                                      ? "none usermessage-image"
                                                      : "receiver-left-image usermessage-image"
                                                  }
                                                  onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src =
                                                      Images.defaultProfile;
                                                  }}
                                                />
                                              </>
                                            )}
                                            <>
                                              <p
                                                className={
                                                  user.username ===
                                                  item.username
                                                    ? "userside-message message"
                                                    : "receiverside-message message"
                                                }
                                              >
                                                {item.message == undefined ? (
                                                  <>
                                                    {item.image === null ? (
                                                      <>
                                                        {item.title ===
                                                        undefined ? (
                                                          ""
                                                        ) : (
                                                          <>
                                                            <p>{item.title}</p>
                                                          </>
                                                        )}
                                                        {item.file === null ? (
                                                          ""
                                                        ) : (
                                                          <>
                                                            <div
                                                              onClick={() =>
                                                                handleClickOpenFile(
                                                                  bucketUrl +
                                                                    "/" +
                                                                    item.file
                                                                )
                                                              }
                                                              className={
                                                                user.username ===
                                                                item.username
                                                                  ? "user-media-title file-flex"
                                                                  : "receiver-media-title file-flex"
                                                              }
                                                              target="_blank"
                                                            >
                                                              <AiOutlineFilePdf className="message-pdf" />
                                                              <p>
                                                                {item.file
                                                                  .split("/")[1]
                                                                  .slice(0, 10)}
                                                              </p>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {item.title ===
                                                        undefined ? (
                                                          ""
                                                        ) : (
                                                          <>
                                                            <p>{item.title}</p>
                                                          </>
                                                        )}
                                                        {/* <a
																													href={
																														bucketUrl +
																														"/" +
																														item.image
																													}
																													target="_blank"
																												> */}
                                                        <img
                                                          src={
                                                            bucketUrl +
                                                            "/" +
                                                            item.image
                                                          }
                                                          alt=""
                                                          className="message-image"
                                                          // onClick={handleClickOpenImage(
                                                          // 	"Paper"
                                                          // )}
                                                          onClick={() =>
                                                            handleSliderOpen(
                                                              item
                                                            )
                                                          }
                                                        />
                                                        {/* </a> */}
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {item.message.match(
                                                      regex
                                                    ) !== null ? (
                                                      <a
                                                        className={`${
                                                          user.username ===
                                                          item.username
                                                            ? "userside-message message"
                                                            : "receiverside-message message"
                                                        }
                                                    hover:underline hover:text-blue-700`}
                                                        href={extractedURL(
                                                          item.message.match(
                                                            regex
                                                          )
                                                        )}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {Parser().parse(
                                                          item.message
                                                        )}
                                                      </a>
                                                    ) : item.message.length <
                                                      500 ? (
                                                      <p
                                                        className={`${
                                                          user.username ===
                                                          item.username
                                                            ? "userside-message message"
                                                            : "receiverside-message message"
                                                        }`}
                                                      >
                                                        {Parser().parse(
                                                          item.message
                                                        )}
                                                      </p>
                                                    ) : (
                                                      <ReadMoreLess
                                                        limit={500}
                                                        jsxFormat
                                                      >
                                                        {item.message}
                                                      </ReadMoreLess>
                                                    )}
                                                  </>
                                                )}
                                              </p>
                                            </>
                                            {item.profile_image ===
                                            "Default" ? (
                                              <>
                                                <img
                                                  src={Images.defaultProfile}
                                                  alt=""
                                                  className={
                                                    user.username ===
                                                    item.username
                                                      ? "usermessage-image"
                                                      : "none"
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={item.profile_image}
                                                  alt=""
                                                  className={
                                                    user.username ===
                                                    item.username
                                                      ? "usermessage-image"
                                                      : "none"
                                                  }
                                                  onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src =
                                                      Images.defaultProfile;
                                                  }}
                                                />
                                              </>
                                            )}
                                          </li>
                                          <li
                                            className={
                                              user.username === item.username
                                                ? "user-side-time flex justify-end text-xs mr-16 text-subHeadingTextColor"
                                                : "receiver-side-time  text-xs ml-16 py-px text-subHeadingTextColor"
                                            }
                                          >
                                            {isValid(item.natural_timestamp) ==
                                            true ? (
                                              <>
                                                {moment(
                                                  "" +
                                                    new Date(
                                                      item.natural_timestamp
                                                    )
                                                ).format("lll")}
                                              </>
                                            ) : (
                                              <>
                                                {moment(
                                                  "" +
                                                    new Date(
                                                      item.natural_timestamp
                                                    )
                                                ).format("lll")}
                                              </>
                                            )}
                                          </li>
                                        </div>
                                      </>
                                    </>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </>
              <div ref={messagesEndRef} />
            </ul>
            {modalOpen && (
              <ConfirmModal
                setOpenModal={setModalOpen}
                // reload={props.personDetails}
                idData={{
                  room: chatRoomId,
                  message_id: messageId,
                }}
                url={"chat/delete_message/"}
                dialogMessage={"Delete Message ?"}
                name={"message"}
                post={true}
                setOpen={setOpen}
              />
            )}
            {/* <ImageSliderModal
							image={imageValue}
							handleClickOpenImage={handleClickOpenImage}
							handleCloseImage={handleCloseImage}
							scrollImage={scrollImage}
							openImage={openImage}
							chatRoomId={chatRoomId}
							allMessages={allMessages}
						/> */}
            {sliderOpen && (
              <ImageSlider
                image={imageValue}
                chatRoomId={chatRoomId}
                allMessages={allMessages}
                sliderOpen={sliderOpen}
                setSliderOpen={setSliderOpen}
              />
            )}

            <Modal open={openFileModal} onClose={() => setOpenFileModal(false)}>
              <FileView
                fileUrl={fileUrl}
                onClose={() => setOpenFileModal(false)}
                ref={React.createRef()}
              />
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default UserMessage;
