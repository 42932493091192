import { configureStore } from '@reduxjs/toolkit';
import miniChatReducer from '../features/MiniChat/miniChatSlice';

const store = configureStore({
  reducer: {
    miniChat: miniChatReducer,
  },
})


export default store;