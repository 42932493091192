import React, { useState, useRef, useEffect } from "react";
import LoginNavbar from "../LoginScreens/LoginNavbar";
import SubscriptionBanner from "./SubscriptionBanner";
import SubscriptionCard from "./SubscriptionCard";
import CardData from "./CardData";
import DetailedPage from "./DetailedPage";


const accessToken = localStorage.getItem("accessToken");

const SubscriptionPage = () => {
  const [clickedIndex, setClickedIndex] = useState(null);
  const [active,setActive] = useState(false)
  const scrollRef = useRef()

  const handleClick = (index) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    // console.log("base",baseUrl)
    const targetIndex = baseUrl === "https://dev.konnectorx.com" ? index : 0;
    
    setClickedIndex(targetIndex);
    setActive(true);
    
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 50);
    
  };

  const disableCards = () =>{
   if (process.env.REACT_APP_BASE_URL !== "https://dev.konnectorx.com")
   {
     setActive(false)
   }
  }

 useEffect(()=>{
  disableCards()
 },[clickedIndex])

  // console.log("index", clickedIndex);
  return (
    <div>
      {!accessToken && <LoginNavbar joinNow={"joinNow"} />}
      <div>
        <SubscriptionBanner />
        <div class="card-container">
          {CardData.map((item, index) => {
            return (
              <SubscriptionCard
                key={item.index}
                index={index}
                clickedIndex = {clickedIndex}
                color={item.color}
                cardHeader={item.cardHeader}
                cardSubHeader={item.cardSubHeader}
                cardBody={item.cardBody}
                handleClick={handleClick}
              />
            );
          })}
        </div>

        {CardData.map((item, index) => {
          if (clickedIndex === index) {
            return (
              <DetailedPage
                color={item.color}
                cardHeader={item.cardHeader}
                cardLeftContent={item.cardLeftContent}
                cardListData={item.cardListData}
                price={item.price}
                active={active}
                ref={scrollRef}
              />
            );
          }
        })}

        {/* <BusinessDetailed />
          <SalesDetailed />
          <RecruiterDetailed /> */}
      </div>
    </div>
  );
};

export default SubscriptionPage;
