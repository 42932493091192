import React, { useState, useContext } from "react";
import { Avatar } from "@material-ui/core";
import compareDateTimeString from "../../utils/Date";
import parse from "html-react-parser";
// import ChatContext from "../Context/ChatContext";
import { addChat } from "../../features/MiniChat/miniChatSlice";
import { useDispatch } from "react-redux";

const Chat = (props) => {
  const chat = props.chat;
  const dispatch = useDispatch();
  return (
    <>
      <div
        onClick={() => dispatch(addChat(chat.friend_id))}
        className="messageBox__chat flex items-center px-2 hover:bg-slate-100 cursor-pointer"
      >
        <Avatar src={chat?.friend_image} className="!w-12 !h-12" />
        <div className="messageBox__chat-info flex-1 flex gap-2 flex-col ml-3 border-b border-[#dbdbdb] py-4 self-start">
          <h3 className="text-sm font-semibold">{chat?.friend}</h3>
          <p className="text-xs text-slate-500">
            {parse(chat?.message?.substring(0, 20))}...
          </p>
        </div>
        <div className="messageBox__chat-time py-4 h-full">
          <p className="text-xs text-slate-500">
            {compareDateTimeString(chat.timestamp, "half")}
          </p>
        </div>
      </div>
    </>
  );
  
};

export default Chat;
