import React from "react";
import Images from "../StaticImages/Images";

const NoDataPage = (props) => {
	return (
		<>
			<div className="relative">
				<div className="flex flex-col justify-center items-center mt-20">
					<img
						src={Images.konnectorxLogo}
						alt=""
						className={`h-20 ${props.className}`} 
					/>
					<span className="flex items-center">
						<img src={props.img} alt="" className="h-6 w-6 mr-2" />
						<p
							className={
								props.handleClick
									? "font-semibold text-subHeadingTextColor cursor-pointer"
									: "font-semibold text-subHeadingTextColor"
							}
							onClick={props.handleClick}
						>
							{props.description}
						</p>
					</span>
				</div>
			</div>
		</>
	);
};

export default NoDataPage;
